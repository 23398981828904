import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  periodPickerSelectedNotice: {
    show: true,
    showDoubleText: true
  },
  datePickerComponent: LeaveDatePeriodPicker,
  datePickerDescription:
    input.leave.type === 'Miscarriage' ? null : baseConfig.datePickerDescription
})

import React, { useContext } from 'react'
import { Button, H1, Icon } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import redirectByUserRole from 'src/utils/redirectByUserRole'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router'

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
      margin: 0 auto;
    `}

  ${mobileMaxWidthMixin};
`

const Text = styled.p`
  font-style: normal;
  line-height: 150%;
  text-align: center;
  margin: 24px 0 48px;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin: 0 0 16px;
    `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}

  ${mobileButtonMaxWidthMixin};
`

const NotFoundPage = React.memo(() => {
  const { t } = useTranslation()
  const userResult: IUseUser = useUser()
  const sharedContext: ISharedContext = useContext(SharedContext)
  const navigate = useNavigate()

  usePageTitle('pageNotFound')

  const onGoBackHomeClick = () => {
    redirectByUserRole(userResult.user, navigate, sharedContext.apolloClient)
  }

  return (
    <Container>
      <H1>{t('pageNotFound.title')}</H1>
      <Icon
        name={'pagick_unfortunately'}
        ariaLabel={t('common.accessibilityText.notFound')}
      />
      <Text>{t('pageNotFound.text')}</Text>
      <ButtonContainer>
        <ButtonWrapper onClick={onGoBackHomeClick}>
          {t('pageNotFound.buttonTitle')}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})

NotFoundPage.displayName = 'NotFoundPage'

export default withRouter(NotFoundPage)

import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import withRouter from 'src/components/hooks/useRouter'
import { timelineRoute } from 'src/routes/constants'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'
import { useNavigate } from 'react-router'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  className?: string
  translationKey: string
}

export const Container = styled.button`
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  line-height: 130%;
  color: ${props => props.theme.colors.main100};
  padding-top: 14px;
  padding-bottom: 16px;

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
    outline-offset: 0;
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          gap: 8px;
        `
      : css`
          font-size: 14px;
          gap: 4px;
        `}
`

const IconWrapper = styled(Icon)`
  display: flex;
  align-items: center;
`

export const AdjustInTheTimeline = React.memo((props: IProps) => {
  const { className, translationKey } = props
  const { isDesktop } = useContext(ScreenContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Container
      className={className}
      onClick={() => {
        navigate(timelineRoute)
      }}
    >
      <IconWrapper name={isDesktop ? 'pencil' : 'pencil_small'} />
      {t(translationKey)}
    </Container>
  )
})

AdjustInTheTimeline.displayName = 'AdjustInTheTimeline'

export default withRouter(AdjustInTheTimeline)

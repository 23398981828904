import React, { useCallback } from 'react'
import { Icon, Link } from 'src/UIKit'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { timelineRoute } from 'src/routes/constants'
import { useNavigate } from 'react-router'
import Tooltip from 'src/components/Tooltip'
import Title from 'src/components/ItemListTitled/components/Title'
import Value from 'src/components/ItemListTitled/components/Value'

interface IProps {
  period: any
  extraPeriods: any[]
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.colors.dark10};
  border-radius: 8px;
  font-weight: 400;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ItemContainer = styled.div`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 16px;
  line-height: 16px;
`

const EditLink = styled(Link)`
  font-family: Roboto, serif;
  font-weight: normal;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;
  color: ${props => props.theme.colors.main100};
`

export const dataAttrs = {
  edit: () => 'edit-upcoming-period-item'
}

const UpcomingPeriodItems = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { period, extraPeriods } = props
  const navigate = useNavigate()

  const lockedIcon = (
    <Tooltip
      placement={'bottom-end'}
      dashedUnderline={{ skipUnderline: true }}
      title={t('claimUpdate.reviewDates.upcoming.locked.title')}
      body={t('claimUpdate.reviewDates.upcoming.locked.body')}
    >
      <Icon name="lock_big" settings={{ width: '16px', height: '21px' }} />
    </Tooltip>
  )

  const onPeriodEditClicked = useCallback(
    item => navigate(timelineRoute, { state: { periodItemId: item.id } }),
    [navigate]
  )

  const periodActionView = item =>
    item.isSynced ? (
      lockedIcon
    ) : (
      <EditLink
        data-testid={dataAttrs.edit()}
        onClick={() => onPeriodEditClicked(item)}
      >
        {t('common.edit')}
      </EditLink>
    )

  return (
    <Container>
      <RowContainer>
        <ItemContainer>
          <Title>{period.name}</Title>
          <Value>{period.value}</Value>
        </ItemContainer>

        <ActionContainer>{periodActionView(period)}</ActionContainer>
      </RowContainer>

      {extraPeriods.map((item, index) => (
        <RowContainer key={index}>
          <ItemContainer>
            <Title>{item.name}</Title>
            <Value>{item.value}</Value>
          </ItemContainer>
        </RowContainer>
      ))}
    </Container>
  )
})

UpcomingPeriodItems.displayName = 'UpcomingPeriodItems'

export default UpcomingPeriodItems

import {
  helpCenterRoute,
  journeyMapRoute,
  managerArchiveRoute,
  managerDashboardRoute,
  managerPreviousVersionsRoute,
  timelineRoute,
  userProfileRoute,
  managerNotificationsRoute,
  hrAdminDashboardRoute,
  managerHelpCenterRoute,
  managerJourneyMapRoute,
  managerDashboardJourneyMapRoute,
  managerArchiveJourneyMapRoute
} from 'src/routes/constants'
import { matchPath } from 'react-router'

export const isPathnameLike = (
  pathname: string,
  route: string,
  contains = false
): boolean => {
  if (pathname == null || route == null) {
    return false
  }
  if (contains) {
    return pathname?.indexOf(route) !== -1
  }
  return matchPath(route, pathname) !== null
}

export const isPathnameLikeOneOf = (
  pathname: string,
  routes: string[],
  contains = false
): boolean => {
  for (const route of routes) {
    if (isPathnameLike(pathname, route, contains)) {
      return true
    }
  }
  return false
}

export const isPathnameLikeNoneOf = (
  pathname: string,
  routes: string[]
): boolean => {
  for (const route of routes) {
    if (isPathnameLike(pathname, route)) {
      return false
    }
  }
  return true
}

export const isManagerRoute = (pathname: string): boolean =>
  isPathnameLikeOneOf(pathname, [
    managerJourneyMapRoute,
    managerDashboardRoute,
    managerDashboardJourneyMapRoute,
    managerArchiveRoute,
    managerArchiveJourneyMapRoute,
    managerNotificationsRoute,
    managerHelpCenterRoute,
    managerPreviousVersionsRoute
  ])

export const isHrAdminRoute = (pathname: string): boolean =>
  isPathnameLike(pathname, hrAdminDashboardRoute)

export const isNavigationJourneyMapSelected = (pathname: string): boolean =>
  isPathnameLike(pathname, journeyMapRoute)

export const isNavigationManagerDashboardSelected = (
  pathname: string
): boolean =>
  isPathnameLikeOneOf(pathname, [
    managerDashboardRoute,
    managerDashboardJourneyMapRoute
  ])

export const isNavigationManagerHelpCenterSelected = (
  pathname: string
): boolean => isPathnameLike(pathname, managerHelpCenterRoute)

export const isNavigationArchivedPlansSelected = (pathname: string): boolean =>
  isPathnameLikeOneOf(pathname, [
    managerArchiveRoute,
    managerArchiveJourneyMapRoute
  ])

export const isNavigationTimelineSelected = (pathname: string): boolean =>
  isPathnameLike(pathname, timelineRoute)

export const isNavigationHelpCenterSelected = (pathname: string): boolean =>
  isPathnameLike(pathname, helpCenterRoute)

export const isNavigationUserProfileRouteSelected = (
  pathname: string
): boolean => isPathnameLike(pathname, userProfileRoute)

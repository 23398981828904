import BabyBonding from './BabyBonding'
import Miscarriage from './Miscarriage'
import Personal from './Personal'
import PreDelivery from './PreDelivery'
import Recovery from './Recovery'
import Sickness from './Sickness'
import UnpaidPersonal from './UnpaidPersonal'
import Vacation from './Vacation'

export default {
  BabyBonding,
  Miscarriage,
  Personal,
  PreDelivery,
  Recovery,
  Sickness,
  UnpaidPersonal,
  Vacation
}

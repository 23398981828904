import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import {
  mobileMaxWidthMixin,
  timelineNoticeCloseButtonMixin,
  timelineNoticeContainerMixin,
  timelineNoticeTextMixin
} from 'src/theme/utils'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
import { hasSeenTpaChanges } from 'src/utils/leaveUtils'
import withQueries from 'src/components/HOC/withQueries'
import { useNavigate } from 'react-router'
import { errorPageRoute } from 'src/routes/constants'
import { IWithQueriesProps } from 'src/react-app-env'

interface IProps extends IWithQueriesProps {
  className?: string
  leave: ILeave
  updateLeave: (changes: any) => void
}

export const dataAttrs = {
  title: () => 'timeline-updated-notice-title',
  text: () => 'timeline-updated-notice-text'
}

const { colors } = createTheme()

const Container = styled.div<{ $top?: number }>`
  ${timelineNoticeContainerMixin};
  ${mobileMaxWidthMixin};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Title = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  line-height: 130%;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
        `
      : css`
          font-weight: bold;
        `}
`

const CloseButton = styled.button`
  ${timelineNoticeCloseButtonMixin};
`

const IconWrapper = styled(Icon)`
  padding-top: 2px;
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`

const Text = styled(ReactMarkdown)`
  ${timelineNoticeTextMixin};

  ul {
    list-style: disc;
    white-space: normal;
    padding-left: 1.5em;
  }

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }
`

const LeaveUpdatedNotice = React.memo((props: IProps) => {
  const { className, leave, queries, updateLeave } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const vacationPeriods = useMemo(() => {
    if (!leave?.tpa?.changes) {
      return null
    }
    const updatedVacations = leave.tpa.changes.previous.filter(
      item =>
        item.type === 'Annual' &&
        !leave.tpa.changes.current.find(
          prevItem =>
            prevItem.type === item.type && prevItem.value === item.value
        )
    )
    return updatedVacations.map(c => ' - ' + c.value).join('\n')
  }, [leave?.tpa?.changes])

  if (!hasSeenTpaChanges(leave) || !vacationPeriods) {
    return null
  }

  const onCloseClick = async () => {
    try {
      await queries.acceptTpaChanges(true)
      await updateLeave({})
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  return (
    <Container
      className={className}
      role={'region'}
      aria-label={t('timelineUpdatedNotice.accessibility.region')}
    >
      <TitleContainer>
        <Title data-testid={dataAttrs.title()}>
          {t('timelineUpdatedNotice.view.title')}
        </Title>
        <CloseButton onClick={onCloseClick} aria-label={t('common.close')}>
          <IconWrapper
            name={'close_crossing'}
            settings={{
              fill: colors.main100
            }}
          />
        </CloseButton>
      </TitleContainer>
      <TextContainer>
        <Text
          data-testid={dataAttrs.text()}
          urlTransform={TelUrlTransformer}
          components={{
            a: LinkRenderer
          }}
        >
          {t('timelineUpdatedNotice.view.text', { vacationPeriods })}
        </Text>
      </TextContainer>
    </Container>
  )
})

LeaveUpdatedNotice.displayName = 'LeaveUpdatedNotice'

export default withQueries(LeaveUpdatedNotice)

import React from 'react'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router'
import HelpCenterPage from './components/HelpCenterPage'

const HelpCenterContainer = React.memo(() => {
  const navigate = useNavigate()
  useRedirectByUserRole({ navigate, allowedRole: 'employee' })
  usePageTitle('helpCenter')
  return <HelpCenterPage role="employee" />
})

HelpCenterContainer.displayName = 'HelpCenterContainer'

export default withRouter(HelpCenterContainer)

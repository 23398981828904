import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router'
import { errorPageRoute, welcomeRoute } from 'src/routes/constants'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { csrf } from 'src/graphql/csrf'
import { consentUrl } from 'src/config'
import { authInfo } from 'src/graphql/authInfo'
import withQueries from 'src/components/HOC/withQueries'
import { IWithQueriesProps } from 'src/react-app-env'
import redirectByUserRole from 'src/utils/redirectByUserRole'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'
import { captureResponseError } from 'src/utils/sentry'
import get from 'lodash.get'
import PrivacyView from './components/Privacy'
import LegalNoticeView from './components/LegalNotice'
import LoadingSpinner from 'src/components/LoadingSpinner'

interface IProps extends IWithQueriesProps {}

const Page = styled.div`
  height: 100%;
  position: relative;
`

export enum UserConsentAction {
  Grant = 'grant',
  Reject = 'reject'
}

export enum UserConsentSource {
  Privacy = 'privacy',
  LegalNotice = 'legalnotice'
}

enum ReasonMessageText {
  NoConsent = 'No consent',
  NoLegal = 'No legal consent'
}

export interface ConsentOptions {
  action: string
  source: string
  isComplete?: boolean
}

export const UserConsentPage = React.memo((props: IProps) => {
  const { queries } = props
  const navigate = useNavigate()
  const sharedContext: ISharedContext = useContext(SharedContext)
  const getEnableOptOut = authInfo.getEnableOptOut()
  const showLegalNotice = authInfo.getShowLegalNotice()
  const [currentSource, setCurrentSource] = useState<string>(null)

  const processSourceForReason = useCallback((reason: any) => {
    const messageText = get(
      reason,
      'networkError.result.message',
      'Unknown error occurred'
    )
    switch (messageText) {
      case ReasonMessageText.NoConsent:
        setCurrentSource(UserConsentSource.Privacy)
        break
      case ReasonMessageText.NoLegal:
        setCurrentSource(UserConsentSource.LegalNotice)
        break
      default:
        throw Error(`unsupported message text ${messageText}`)
    }
  }, [])

  const fetchMe = useCallback(async () => {
    try {
      const userResult: IUser = await queries.fetchMe({
        unavailableForLegalReasons: processSourceForReason
      })
      return userResult
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute)
      }
    }
  }, [navigate, queries, processSourceForReason])

  useEffect(() => {
    fetchMe().then(user => {
      if (user) {
        redirectByUserRole(user, navigate, sharedContext.apolloClient)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  usePageTitle('welcome')

  const submitConsent = (action: string, source: string) =>
    fetch(consentUrl(), {
      method: 'POST',
      body: JSON.stringify({ action, source }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf.getToken()
      },
      credentials: 'include'
    }).then(response => {
      if (!response.ok) {
        captureResponseError(response)
        throw Error('invalid response')
      }
    })

  const consent = useCallback(
    async ({ action, source, isComplete = false }: ConsentOptions) => {
      try {
        await submitConsent(action, source)

        if (action === UserConsentAction.Grant && isComplete) {
          const user = await fetchMe()
          if (user) {
            redirectByUserRole(user, navigate, sharedContext.apolloClient)
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          navigate(errorPageRoute)
        }
      }
    },
    [fetchMe, navigate, sharedContext]
  )

  const currentContent = useMemo(() => {
    switch (currentSource) {
      case UserConsentSource.Privacy:
        return (
          <PrivacyView
            getEnableOptOut={getEnableOptOut}
            onCancel={() => {
              consent({
                action: UserConsentAction.Reject,
                source: UserConsentSource.Privacy
              })
              navigate(welcomeRoute)
            }}
            onContinue={() => {
              consent({
                action: UserConsentAction.Grant,
                source: UserConsentSource.Privacy,
                isComplete: !showLegalNotice
              })
              if (showLegalNotice) {
                setCurrentSource(UserConsentSource.LegalNotice)
              }
            }}
          />
        )
      case UserConsentSource.LegalNotice:
        return (
          <LegalNoticeView
            onCancel={() => {
              consent({
                action: UserConsentAction.Reject,
                source: UserConsentSource.LegalNotice
              })
              navigate(welcomeRoute)
            }}
            onContinue={() =>
              consent({
                action: UserConsentAction.Grant,
                source: UserConsentSource.LegalNotice,
                isComplete: true
              })
            }
          />
        )

      default:
        return null
    }
  }, [consent, currentSource, getEnableOptOut, navigate, showLegalNotice])

  if (!currentContent) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  return <Page>{currentContent}</Page>
})

UserConsentPage.displayName = 'UserConsentPage'

export default withRouter(withQueries(UserConsentPage))

import React, { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Description } from '../ComponentStyles'
import TitleView from '../TitleView'
import ConfirmationView from '../ConfirmationView'
import ButtonsView from '../ButtonsView'
import { LinkRenderer } from 'src/utils/contentRenderUtils'

interface IProps {
  onCancel: () => void
  onContinue: () => void
}

export const LegalNotice = React.memo((props: IProps) => {
  const { onCancel, onContinue } = props
  const { t } = useTranslation()
  const [confirmed, setConfirmed] = useState<boolean>(false)

  const confirmation: ReactNode = useMemo(
    () => (
      <ConfirmationView
        onChange={setConfirmed}
        translationKey={t('legalNoticePage.confirmation')}
        confirmed={confirmed}
      />
    ),
    [confirmed, t]
  )

  const buttons: ReactNode = useMemo(
    () => (
      <ButtonsView
        cancelTitle={t('common.cancel')}
        onCancelClick={onCancel}
        continueTitle={t('common.continue')}
        onContinueClick={onContinue}
        isContinueDisabled={!confirmed}
      />
    ),
    [confirmed, onCancel, onContinue, t]
  )

  return (
    <Container>
      <TitleView text={t('legalNoticePage.title')} />
      <Description
        allowsParagraph
        components={{
          a: (linkProps: any) => LinkRenderer(linkProps)
        }}
      >
        {t('legalNoticePage.body')}
      </Description>
      {confirmation}
      {buttons}
    </Container>
  )
})

LegalNotice.displayName = 'LegalNotice'

export default LegalNotice

import {
  IIconName,
  ICountryCode,
  ITimelinePeriod,
  IMomentCurrentMinMax
} from 'src/react-app-env'
import { Moment } from 'moment'
import DueDate from './DueDate'
import EndDate from './EndDate'
import StartDate from './StartDate'
import PublicHolidays from './PublicHolidays'
import AtWork from './AtWork'
import BirthDate from './BirthDate'
import ReturnToWorkDate from './ReturnToWorkDate'
import getBaseConfig from './getBaseConfig'

import AdobeUSConfig from './Adobe/US'
import AdobeIEConfig from './Adobe/IE'
import AdobeINConfig from './Adobe/IN'
import RocheUSConfig from './Roche/US'
import IntuitiveUSConfig from './Intuitive/US'
import GoogleUSConfig from './Google/US'
import WorkdayUSConfig from './Workday/US'
import IbmUSConfig from './Ibm/US'

import get from 'lodash.get'
import { IDuration } from 'src/utils/leaveUtils'

export interface IGetPeriodConfigInput {
  period: ITimelinePeriod
  periods: ITimelinePeriod[]
  customer: string
  countryCode: ICountryCode
  leave: ILeave
  t: any
}

export type PeriodViewForPeriod = 'self' | 'startDate' | 'endDate'
export type DatePickerKeyDate = 'startDate' | 'dueDate'
export type PeriodKeyDate = 'periodStart' | 'periodEnd'

interface ITimeViewProps {
  date: Moment
  iconName: IIconName
  iconAriaLabel: string
  isFirst: boolean
  isLast: boolean
  isFrontAddable: boolean
  isCircleHidden: boolean
  skip?: boolean
  symbol?: string
}

interface ISelectedNotice {
  show: boolean
  showDoubleText?: boolean
  notShowUnused?: boolean
}

interface IPeriodTopNotice {
  message: string
  tooltip: string
  date: Moment
}

interface IPeriodInfoBlock {
  title: string
  text: string
}

interface IDetailsHolidays {
  show: boolean
  showDescription: boolean
  descriptionKey: (...args: any[]) => string
}

export interface ITimelinePeriodConfig {
  title: string
  periodName: string
  description: string
  duration: IDuration
  datePickerViewForPeriod: PeriodViewForPeriod
  detailsExtraDescription: (...args: any[]) => string
  detailsViewForPeriod: PeriodViewForPeriod
  detailsHolidays: IDetailsHolidays
  detailsHintPostfix: string
  detailsType: string
  addsOneVisualDay: boolean
  timeViewProps: ITimeViewProps
  hidesFooter: boolean
  datePickerComponent: any
  datePickerKeyDate: DatePickerKeyDate
  datePickerDescription: string
  datePickerDisableConfirm: boolean
  datePickerDisableWeekends?: boolean
  datePickerFooterInfoMessage: (...args: any[]) => string
  periodInfoBlock: (...args: any[]) => IPeriodInfoBlock
  periodPickerStartDateText: string
  periodPickerEndDateText: string
  periodKeyDate: PeriodKeyDate
  defaultActiveKeyDate?: PeriodKeyDate
  periodTopNotice: (...args: any[]) => IPeriodTopNotice
  periodPickerSelectedNotice: ISelectedNotice
  periodPickerShowWorkdays: boolean
  periodPickerShowBalance: boolean
  periodPickerInfoMessage: (...args: any[]) => string
  leaveKeyDatePickerInfoMessage: (...args: any[]) => string
  periodPickerMaxSelected: (duration: number) => number
  periodPickerTotalSelected: (duration: number) => number
  periodPickerAsWeeksPicker: boolean
  periodPickerBlankStartDate: boolean
  periodPickerCreateAddable: boolean
  periodPickerGetDateMinMax: (
    momentCurrentMinMax: IMomentCurrentMinMax
  ) => Moment
  periodPickerDisableStartDate: boolean
  removable: boolean
  alerts: string[]
  disableCreatePeriod?: boolean
  footerDatePickerTitle: string
  footerShowDetails: boolean
  useApprovedByTpa: boolean
  usePeriodRefIdSearch: boolean
  isMaximumAllowed: boolean
  intermittentPickerResetBlock: boolean
  handleDatePickerBottomOverlap: boolean
}

const typedConfigs: any = {
  DueDate,
  StartDate,
  EndDate,
  AtWork,
  BirthDate,
  PublicHolidays,
  ReturnToWorkDate,
  Adobe: {
    US: AdobeUSConfig,
    IE: AdobeIEConfig,
    IN: AdobeINConfig
  },
  Roche: {
    US: RocheUSConfig
  },
  Intuitive: {
    US: IntuitiveUSConfig
  },
  Google: {
    US: GoogleUSConfig
  },
  Workday: {
    US: WorkdayUSConfig
  },
  Ibm: {
    US: IbmUSConfig
  }
}

export const getPeriodConfig = (
  input: IGetPeriodConfigInput
): ITimelinePeriodConfig => {
  const { period, periods, leave, t, customer, countryCode } = input
  const { type } = period

  const baseConfig: ITimelinePeriodConfig = getBaseConfig({
    period,
    periods,
    leave,
    t,
    customer,
    countryCode
  })

  const config = get(
    typedConfigs,
    [customer, countryCode, type],
    typedConfigs[type]
  )

  try {
    return config(baseConfig, input)
  } catch (_) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`${type} is not implemented`)
    }

    return baseConfig
  }
}

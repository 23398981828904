import merge from 'lodash.merge'
import { defaultCustomerConfig, ICustomerConfig } from './config'
import { ITimelineContext } from 'src/features/Timeline/Context'
import { openDatePickerForPreference } from 'src/utils/leaveUtils'
import logo from 'src/assets/images/ibm-logo.svg'
import { isPublishedLeave } from 'src/utils/leaveStatusUtils'

export default (): ICustomerConfig =>
  merge(defaultCustomerConfig(), {
    companyName: () => 'IBM',
    logo,
    tpa: 'MetLife',
    leave: {
      create: {
        preferences: ['starter', 'reliever', 'middler'],
        workflow: {
          Parental: {
            parental: ({ selectedLeaveType }) =>
              selectedLeaveType?.type !== 'Pregnancy'
                ? 'selectLeavePreference'
                : null,
            selectLeavePreference: null
          }
        }
      },
      timeline: {
        showReviewAlerts: true,
        showNextStepsNotice: (leave: ILeave): boolean =>
          isPublishedLeave(leave.status) && leave.type === 'Miscarriage',
        onAfterCloseReviewAlert: (timelineContext: ITimelineContext) => {
          openDatePickerForPreference(timelineContext, 'middler')
        }
      }
    }
  })

import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { UIText, Link } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import PrivacyList from './components/PrivacyList'
import AnimateHeight from 'react-animate-height'
import { useNavigate } from 'react-router'
import { closeAccountRoute } from 'src/routes/constants'
import PrivacyItemModalView from './components/PrivacyItemModalView'

export interface PrivacyItem {
  title: string
  description: string
}

interface IProps {
  className?: string
}

const appearingKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${appearingKeyframes} 300ms linear forwards;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const DescriptionWrapper = styled(UIText)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 0 32px;
        `
      : css`
          margin: 0 0 16px;
        `}
`

const TogglePrivacySettings = styled(Link)`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;
`

const PrivacyListWrapper = styled(PrivacyList)`
  margin-top: ${props => (props.theme.isDesktop ? '32px' : '16px')};
`

const PrivacySettings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CloseAccountLink = styled(Link)`
  margin-top: ${props => (props.theme.isDesktop ? '32px' : '16px')};
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;
  color: ${props => props.theme.colors.error80};
  text-wrap: balance;

  &:hover {
    color: ${props => props.theme.colors.error100};
  }

  &:focus {
    color: ${props => props.theme.colors.error100};
  }
`

export const dataAttrs = {
  togglePrivacySettings: () => 'privacy-controls-toggle',
  closeAccountBtn: () => 'privacy-controls-close-acc-btn'
}

const PrivacyControls = React.memo((props: IProps) => {
  const { className } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showPrivacySettings, setShowPrivacySettings] = useState<boolean>(false)
  const [viewPrivacyItem, setViewPrivacyItem] = useState<PrivacyItem>(null)

  const items = t('privacyControls.items', {
    returnObjects: true
  }) as PrivacyItem[]

  return (
    <Container className={className}>
      <DescriptionWrapper allowsParagraph>
        {t('privacyControls.description')}
      </DescriptionWrapper>
      <TogglePrivacySettings
        data-testid={dataAttrs.togglePrivacySettings()}
        onClick={() => setShowPrivacySettings(!showPrivacySettings)}
      >
        {t(
          `privacyControls.toggleLink.${showPrivacySettings ? 'view' : 'hide'}`
        )}
      </TogglePrivacySettings>
      <AnimateHeight
        duration={300}
        height={showPrivacySettings ? 'auto' : 0}
        style={{ width: '100%' }}
        animateOpacity
      >
        <PrivacySettings>
          <PrivacyListWrapper
            shows={showPrivacySettings}
            items={items}
            onViewItem={item => setViewPrivacyItem(item)}
          />
          <CloseAccountLink
            data-testid={dataAttrs.closeAccountBtn()}
            onClick={() => navigate(closeAccountRoute)}
          >
            {t('privacyControls.closeAccountLink')}
          </CloseAccountLink>
        </PrivacySettings>
      </AnimateHeight>

      {viewPrivacyItem && (
        <PrivacyItemModalView
          item={viewPrivacyItem}
          onClose={() => setViewPrivacyItem(null)}
        />
      )}
    </Container>
  )
})

PrivacyControls.displayName = 'PrivacyControls'

export default PrivacyControls

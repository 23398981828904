import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'src/UIKit'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'
import { createLeaveButtons } from 'src/constants/frame'

interface IProps {
  cancelTitle: string
  onCancelClick: () => void
  continueTitle: string
  onContinueClick: () => void
  isContinueDisabled: boolean
}

export const dataAttrs = {
  buttonCancel: () => 'consent-button-cancel',
  buttonContinue: () => 'consent-button-continue'
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 32px 0 65px;
          width: calc(${createLeaveButtons.width().px} + 16px);
        `
      : css`
          flex: 1;
          align-items: flex-end;
          width: 100%;
          margin: 16px 0 0;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ButtonWrapper = styled(Button)`
  font-size: 16px;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: calc(50% - 8px);
          height: 48px;
          margin: 0 0 16px;
        `}
`

export const ButtonsView = React.memo((props: IProps) => {
  const {
    cancelTitle,
    onCancelClick,
    continueTitle,
    onContinueClick,
    isContinueDisabled
  } = props

  return (
    <ButtonsContainer>
      <ButtonWrapper
        data-testid={dataAttrs.buttonCancel()}
        onClick={onCancelClick}
        appearance={'cancel'}
      >
        {cancelTitle}
      </ButtonWrapper>
      <ButtonWrapper
        data-testid={dataAttrs.buttonContinue()}
        onClick={onContinueClick}
        disabled={isContinueDisabled}
      >
        {continueTitle}
      </ButtonWrapper>
    </ButtonsContainer>
  )
})

ButtonsView.displayName = 'ButtonsView'

export default ButtonsView

import { QueryResult, useQuery } from '@apollo/client'
import { useState } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { onQueryRejection } from 'src/graphql/QueryExecutor'
import { useNavigate } from 'react-router'
import { GLOBAL_COUNTRY } from 'src/utils/userUtils'

export type AnalyticsNumbersType =
  | 'uniqueUsers'
  | 'timelineUsers'
  | 'journeyUsers'
  | 'activeUsers'
  | 'planningUsers'
  | 'onLeaveUsers'
  | 'backAtWorkUsers'
  | 'managerActivations'

export interface IAnalyticsNumbers {
  uniqueUsers: number
  timelineUsers: number
  journeyUsers: number
  activeUsers: number
  planningUsers: number
  onLeaveUsers: number
  backAtWorkUsers: number
  managerActivations: number
}

export interface IAnalytics {
  numbers: IAnalyticsNumbers
  chart?: any
}

export interface IUseAnalytics {
  loading: boolean
  error: any
  analytics: IAnalytics
}

export type AnalyticsChartKind =
  | 'UniqueUsers'
  | 'TimelineUsers'
  | 'JourneyUsers'
  | 'ManagerActivations'
  | 'None'

export interface IUseAnalyticsParameters {
  query: any
  kind: AnalyticsChartKind
  period: number
  type: string
  countryCode: string
  skipsChart?: boolean
  skipsNumbers?: boolean
}

interface ICachedPeriod {
  numbers: IAnalyticsNumbers
  charts: any
}

interface ICache {
  [hash: string]: ICachedPeriod
}

const defaultNumbers: IAnalyticsNumbers = {
  uniqueUsers: -1,
  timelineUsers: -1,
  journeyUsers: -1,
  activeUsers: -1,
  planningUsers: -1,
  onLeaveUsers: -1,
  backAtWorkUsers: -1,
  managerActivations: -1
}

const getHash = (period: number, type: string, code: string): string =>
  `${period}_${type}_${code}`

const defaultChart: any = {}

const useAnalytics = (params: IUseAnalyticsParameters): IUseAnalytics => {
  const { query, kind, period, type, skipsChart, skipsNumbers, countryCode } =
    params
  const [cache, setCache] = useState<ICache>({})
  const navigate = useNavigate()

  const hash: string = getHash(period, type, countryCode)
  const getCachedPeriod = (): ICachedPeriod => cache[hash]

  const numbers: IAnalyticsNumbers =
    getCachedPeriod()?.numbers || defaultNumbers
  const chart: any = getCachedPeriod()?.charts[kind] || defaultChart

  const returnValue: IUseAnalytics = {
    loading: false,
    error: null,
    analytics: {
      numbers,
      chart
    }
  }

  const hasNumbers: boolean = skipsNumbers ? true : numbers !== defaultNumbers
  const hasChart: boolean =
    skipsChart || kind === 'None' ? true : chart !== defaultChart
  const skip: boolean = hasNumbers && hasChart

  const variables: any = {}

  if (kind !== 'None') {
    variables.kind = kind
  }
  if (type) {
    variables.type = type
  }

  variables.period = period
  if (countryCode !== GLOBAL_COUNTRY) {
    variables.countryCode = countryCode
  }

  const analyticsResult: QueryResult = useQuery(query, {
    fetchPolicy: 'network-only',
    variables,
    skip
  })

  if (skip) {
    return returnValue
  }

  if (analyticsResult.loading) {
    returnValue.loading = true
    return returnValue
  }

  if (analyticsResult.error) {
    onQueryRejection(analyticsResult.error, navigate)
    returnValue.error = analyticsResult.error
    return returnValue
  }

  if (analyticsResult.data) {
    const { analytics } = analyticsResult.data
    const newCache: ICache = cloneDeep(cache)
    if (!newCache[hash]) {
      newCache[hash] = {
        numbers: analytics.numbers,
        charts: {}
      }
    }

    if (analytics.chartResult) {
      newCache[hash].charts[kind] = analytics.chartResult
    } else {
      const pattern = 'chartResult'
      for (const key in analytics) {
        if (key.indexOf(pattern) !== -1) {
          const chartKey: string = key.replace(pattern, '')
          if (chartKey.length) {
            newCache[hash].charts[chartKey] = analytics[key]
          }
        }
      }
    }

    returnValue.analytics = {
      numbers: newCache[hash].numbers,
      chart: newCache[hash].charts[kind]
    }

    setCache(newCache)
  }

  return returnValue
}

export default useAnalytics

import React from 'react'
import { ICountryCode, IGraphqlQueries } from 'src/react-app-env'
import {
  IPeriodFilter,
  IPriorityFilter
} from 'src/components/JourneyMap/Filters'
import { NavigateFunction, Location } from 'react-router'

export interface IJourneyMapContext {
  priorityFilters: IPriorityFilter[]
  periodFilters: IPeriodFilter[]
  isOverdueMode: boolean
  onFilterClick: (index: number) => void
  onPeriodClick: (index: number) => void
  onResetFiltersClick: () => void
  onOverdueClick: () => void
  onMobileFiltersExpandStateDidChange: (expanded: boolean) => void
  queries: IGraphqlQueries
  navigate: NavigateFunction
  location: Location
  blurred: boolean
  onToggleError: () => void
  leave: ILeave
  countryCode: ICountryCode
}

const JourneyMapContext = React.createContext(null)

export default JourneyMapContext

import React from 'react'
import styled from 'styled-components'
import withRouter from 'src/components/hooks/useRouter'
import { useTranslation } from 'react-i18next'
import { getCurrentUserRole, switchToRole } from 'src/utils/userUtils'
import { UserRole } from 'src/react-app-env'
import RolesSwitcherRow from 'src/components/Header/components/desktop/User/components/RolesSwitcher/Row'
import { useNavigate, useLocation } from 'react-router'

interface IProps {
  user: IUser
  className?: any
}

const Container = styled.div`
  margin: 16px 16px 0;
`

const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.main50};
  opacity: 0.5;
`

export const RolesSwitcher = React.memo((props: IProps) => {
  const { user } = props
  const { roles } = user
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  if (roles.length < 2) {
    return null
  }

  const currentRole: UserRole = getCurrentUserRole(location)
  const rows: any = (roles as UserRole[]).map((r: UserRole, index: number) => (
    <RolesSwitcherRow
      active={currentRole === r}
      key={r}
      onClick={() => switchToRole(r, navigate)}
      isFirst={index === 0}
    >
      {t(`common.${r}`)}
    </RolesSwitcherRow>
  ))

  return (
    <Container>
      <Title>{`${t('common.role')}:`}</Title>
      <ul>{rows}</ul>
      <Separator />
    </Container>
  )
})

RolesSwitcher.displayName = 'RolesSwitcher'

export default withRouter(RolesSwitcher)

import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: LeaveDatePeriodPicker,
  periodKeyDate: 'periodEnd',
  periodPickerCreateAddable: true,
  periodPickerGetDateMinMax: () => input.period.periodEnd.min
})

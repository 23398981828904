import BabyBonding from './BabyBonding'
import Military from './Military'
import PreDelivery from './PreDelivery'
import Recovery from './Recovery'
import Reinstatement from './Reinstatement'
import StateParental from './StateParental'
import UnpaidParental from './UnpaidParental'
import Personal from './Personal'
import FamilyCare from './FamilyCare'
import PaidMedical from './PaidMedical'
import PartTimeMedical from './PartTimeMedical'
import Other from './Other'

export default {
  BabyBonding,
  FamilyCare,
  Military,
  Other,
  PaidMedical,
  PartTimeMedical,
  PreDelivery,
  Recovery,
  Reinstatement,
  StateParental,
  UnpaidParental,
  Personal
}

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import withRouter from 'src/components/hooks/useRouter'
import { H1, Button, Text } from 'src/UIKit'
import { welcomeRoute } from 'src/routes/constants'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'

const Container = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content min-content;
  grid-template-areas:
    'title'
    'text'
    'button';
  justify-items: center;
  margin: 0 auto;
  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  grid-area: title;
`

const TextWrapper = styled(Text)`
  grid-area: text;
`

export const ButtonWrapper = styled(Button)`
  grid-area: button;
  margin-top: 48px;

  ${mobileButtonMaxWidthMixin};
`

export const LoginErrorPage = React.memo(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  usePageTitle('loginError')

  const navigateToMainPage = useCallback(() => {
    navigate(welcomeRoute)
  }, [navigate])

  return (
    <Container>
      <Title>{t('common.oops')}</Title>
      <TextWrapper>{t('common.somethingHappenedWhileLoggingIn')}</TextWrapper>
      <ButtonWrapper onClick={navigateToMainPage}>
        {t('common.toMainPage')}
      </ButtonWrapper>
    </Container>
  )
})

LoginErrorPage.displayName = 'LoginErrorPage'

export default withRouter(LoginErrorPage)

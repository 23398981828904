import { NavigateFunction, Location, matchPath } from 'react-router'
import { routesForDeepLinks } from 'src/routes/constants'

const DEEP_LINKS_KEY = 'deepLinks'
const NAVIGATE_TO_URL = 'navigate_to_url'

const getDeepLinks = (): any => {
  let deepLinks: any = JSON.parse(localStorage.getItem(DEEP_LINKS_KEY))
  if (!deepLinks) {
    deepLinks = {}
  }

  return deepLinks
}

const setDeepLinks = (deepLinks: any) => {
  localStorage.setItem(DEEP_LINKS_KEY, JSON.stringify(deepLinks))
}

const addAction = (action: string, params: any = {}) => {
  const deepLinks = getDeepLinks()
  deepLinks[action] = params
  setDeepLinks(deepLinks)
}

const removeAction = (action: string) => {
  const deepLinks = getDeepLinks()
  delete deepLinks[action]
  setDeepLinks(deepLinks)
}

const parseAction = (location: Location): any => {
  const { pathname, hash, search } = location
  if (!pathname) {
    return
  }

  if (routesForDeepLinks.find(route => matchPath(route, pathname))) {
    addAction(NAVIGATE_TO_URL, { pathname, hash, search })
  }
}

const runAnAction = (navigate: NavigateFunction): boolean => {
  const deepLinks = getDeepLinks()
  const actions: string[] = Object.keys(deepLinks)
  if (actions.length === 0) {
    return false
  }

  const action: string = actions[0]
  const params: any = deepLinks[action]
  removeAction(action)

  if (action === NAVIGATE_TO_URL) {
    const { pathname, hash, search } = params
    navigate(pathname + (search || '') + (hash || ''))
    return true
  }

  return false
}

const forTests = {
  DEEP_LINKS_KEY,
  NAVIGATE_TO_URL
}

export { parseAction, runAnAction, forTests }

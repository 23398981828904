import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import {
  welcomeRoute,
  notificationsRoute,
  managerNotificationsRoute,
  loggedOutPageRoute
} from 'src/routes/constants'
import { IWithQueriesProps } from 'src/react-app-env'
import withQueries from 'src/components/HOC/withQueries'
import Badge from 'src/components/Badge'
import * as RouteUtils from 'src/utils/routeUtils'
import Popover from 'src/components/Popover'
import NotificationsPopoverContainer from 'src/features/Notifications/NotificationsPopoverContainer'
import { queryNotificationsFeed } from 'src/graphql/queries'
import zIndex from 'src/constants/zIndex'
import { printDisplayNoneMixin } from 'src/theme/utils'
import RouteContext from 'src/routes/Context'
import { useTranslation } from 'react-i18next'
import VeerLogo from 'src/components/VeerLogo'
import { useQuery } from '@apollo/client'
import ScreenContext from 'src/contexts/ScreenContext'
import Menu from './components/mobile/Menu'
import User from './components/desktop/User'
import Notifications from './components/Notifications'
import Navigation from './components/Navigation'
import useUser from 'src/graphql/hooks/useUser'
import { useNavigate, useLocation } from 'react-router'
import SharedContext from 'src/contexts/SharedContext'
import { header } from 'src/constants/frame'

interface IProps extends IWithQueriesProps {
  className?: string
  hideMenu?: boolean
  marginTop?: number
}

const HeaderWrapper = styled.header<{
  $hidesBorder: boolean
  $marginTop: number
}>`
  grid-area: header;
  justify-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: ${zIndex.header};
  position: fixed;
  top: ${p => p.$marginTop}px;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.light100};
  ${props =>
    !props.$hidesBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.main10};
    `}
  height: ${p => header.height(p.theme).px};
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 0 20px;
        `
      : css`
          margin: 0;
        `}

  @media print {
    top: 0;
    border-bottom-width: 0;
    display: flex;
    height: ${p => header.height(p.theme).px};
    padding: 0 20px;
  }
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const CompanyLogo = styled.img`
  max-width: 120px;
  ${props =>
    props.theme.isMobile &&
    css`
      margin-left: 12px;
      height: 25px;
    `}
`

const Separator = styled.div`
  width: 1px;
  background: ${props => props.theme.colors.dark10};

  ${props =>
    props.theme.isDesktop
      ? css`
          height: 32px;
          margin: 0 20px;
        `
      : css`
          height: 24px;
        `}

  @media print {
    height: 32px;
    margin: 0 20px;
    width: 0;
    border: 0.5px solid ${props => props.theme.colors.dark10};
  }
`

const ModeLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          margin-left: 24px;
        `
      : css`
          font-size: 12px;
          margin-left: 16px;
        `}
`

// https://github.com/styled-components/styled-components/issues/1449#issuecomment-420087359
const NavigationWrapper = styled(props => <Navigation {...props} />)`
  ${printDisplayNoneMixin}
`

const NotificationsWrapper = styled(Notifications)<any>`
  margin-left: 30px;
  margin-right: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'unset')};
  ${printDisplayNoneMixin}
`

const UserWrapper = styled(props => <User {...props} />)`
  ${printDisplayNoneMixin}
`

const ButtonContainer = styled.button<any>`
  display: flex;
  align-items: center;
  outline: none;
  position: relative;
  padding: 0;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
    border-radius: 12px;
  }
  ${printDisplayNoneMixin}
`

const BadgeWrapper = styled(Badge)`
  justify-self: end;
  ${printDisplayNoneMixin}
`

const MenuWrapper = styled(Menu)`
  margin: 0;
  ${printDisplayNoneMixin}
`

export const Header = (props: IProps) => {
  const { isManagerRoute, isHrAdminRoute, primaryColor, notificationType } =
    useContext(RouteContext)
  const { className, queries, hideMenu, marginTop } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const popoverRef: any = useRef(null)
  const notificationsRef: any = useRef(null)
  const userResult = useUser()

  const notificationResult = useQuery(queryNotificationsFeed, {
    variables: {
      type: notificationType,
      count: 4,
      after: null
    },
    skip: RouteUtils.isHrAdminRoute(pathname)
  })

  const [isNotificationsPopoverExpanded, setIsNotificationsPopoverExpanded] =
    useState<boolean>(false)

  const { customer, customerConfig } = useContext(SharedContext)
  const companyLogo = useMemo(() => customerConfig?.logo, [customerConfig])

  const modeView: ReactNode = useMemo(() => {
    let text: string
    if (isManagerRoute) {
      text = t('common.managerMode')
    }
    if (isHrAdminRoute) {
      text = t('common.hradminMode')
    }

    if (!text) {
      return null
    }
    return <ModeLabel aria-hidden>{text}</ModeLabel>
  }, [isManagerRoute, isHrAdminRoute, t])

  const onNotificationsPopoverStateChanged = useCallback(
    (expanded: boolean) => {
      setIsNotificationsPopoverExpanded(expanded)
    },
    [setIsNotificationsPopoverExpanded]
  )

  const { isDesktop } = useContext(ScreenContext)

  const renderPopover = () => (
    <Popover
      parent={notificationsRef.current}
      ref={popoverRef}
      afterStateChanged={onNotificationsPopoverStateChanged}
    >
      <NotificationsPopoverContainer />
    </Popover>
  )

  const logout = async () => {
    const result: boolean = await queries.logout()
    if (result) {
      if (isDesktop) {
        navigate(loggedOutPageRoute)
      } else {
        navigate(welcomeRoute)
      }
    }
  }

  const onNotificationsClick = () => {
    if (isDesktop) {
      if (
        RouteUtils.isPathnameLikeOneOf(pathname, [
          notificationsRoute,
          managerNotificationsRoute
        ])
      ) {
        return
      }

      const { current } = popoverRef
      if (!current) {
        return
      }

      if (current) {
        current.toggle()
      }
    } else {
      if (RouteUtils.isManagerRoute(pathname)) {
        navigate(managerNotificationsRoute)
      } else {
        navigate(notificationsRoute)
      }
    }
  }

  const renderUserRelated = (user: IUser, notifications: INotificationFeed) => {
    if (!user) {
      return null
    }
    const notificationsCount: number = notifications
      ? notifications.records.length
      : 0
    if (isDesktop) {
      return (
        <RightContainer>
          {!isHrAdminRoute && <NavigationWrapper />}
          {!isHrAdminRoute && (
            <NotificationsWrapper
              user={user}
              notifications={notifications}
              onClick={() => {
                onNotificationsClick()
              }}
              ref={notificationsRef}
              expanded={isNotificationsPopoverExpanded}
              cursorPointer={
                !!notificationsCount &&
                RouteUtils.isPathnameLikeNoneOf(pathname, [
                  notificationsRoute,
                  managerNotificationsRoute
                ])
              }
            />
          )}
          <UserWrapper user={user} logout={logout} />
        </RightContainer>
      )
    } else {
      const { unreadCounter } = notifications || { unreadCounter: 0 }
      return (
        <RightContainer>
          {unreadCounter > 0 && (
            <ButtonContainer
              onClick={() => {
                if (notificationsCount !== 0) {
                  onNotificationsClick()
                }
              }}
              aria-label={t('common.accessibilityText.unreadNotifications', {
                count: unreadCounter
              })}
            >
              <BadgeWrapper
                text={unreadCounter.toString()}
                background={primaryColor}
              />
            </ButtonContainer>
          )}
          <MenuWrapper
            user={user}
            notifications={notifications}
            logout={logout}
          />
        </RightContainer>
      )
    }
  }

  const userRelated = renderUserRelated(
    userResult.user,
    notificationResult.data?.notifications
  )

  return (
    <HeaderWrapper
      className={className}
      $marginTop={marginTop}
      $hidesBorder={RouteUtils.isPathnameLike(location.pathname, welcomeRoute)}
      aria-label={t('common.accessibilityText.region.header')}
    >
      <LeftContainer>
        <VeerLogo />
        {companyLogo && <Separator />}
        {companyLogo && (
          <CompanyLogo
            src={companyLogo}
            alt={t('common.accessibilityText.companyLogo', {
              companyName: customer
            })}
          />
        )}
        {modeView}
      </LeftContainer>
      {!hideMenu && userRelated}
      {renderPopover()}
    </HeaderWrapper>
  )
}

Header.displayName = 'Header'

export default withQueries(Header)

import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import ReactDOM from 'react-dom'
import styled, { css, keyframes } from 'styled-components'
import { printDisplayNoneMixin } from 'src/theme/utils'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { MODAL_SHOWN } from 'src/constants/events'
import zIndex from 'src/constants/zIndex'
import ScreenContext from 'src/contexts/ScreenContext'
import { IPopoverPosition, getPopoverPosition } from './methods'
import Background from './components/Background'
import { useLocation } from 'react-router'

interface IProps {
  children: any
  parent: any
  className?: any
  beforeStateChanged?: (oldIsOpen: boolean, newIsOpen: boolean) => void
  afterStateChanged?: (isOpen: boolean) => void
  // don't close the popover if mouse click happened inside the popover content rect
  allowsHitAreaCheck?: boolean
  offset?: IPopoverPosition
  minWidth?: number
  id?: string
  handleBottomOverlap?: boolean
}

const fadeInKeyFrames = keyframes`
  0% {
    opacity: 0;
    transform: translate(0px, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
}
`

const Container = styled.div<{
  $isOpen: boolean
  $initialIsOpen: boolean
  $top: number
  $left: number
  $zIndex: number
  $minWidth: number
}>`
  flex-direction: column;
  position: absolute;
  cursor: initial;
  display: flex;
  visibility: ${props => (props.$isOpen ? 'visible' : 'hidden')};
  top: ${props => `${props.$top}px`};
  left: ${props => `${props.$left}px`};
  z-index: ${props => props.$zIndex};
  ${props => {
    if (props.$isOpen) {
      return css`
        animation: ${fadeInKeyFrames} 100ms linear;
      `
    }
  }}

  ${props => {
    const minWidth = props.$minWidth
    if (minWidth > 0) {
      return css`
        min-width: ${minWidth}px;
      `
    }
  }}

  ${props => {
    if (props.$initialIsOpen) {
      return css`
        top: -3000px;
        left: -3000px;
        opacity: 0;
      `
    }
  }}
  ${printDisplayNoneMixin};
`

export const Popover = React.forwardRef((props: IProps, ref: any) => {
  const {
    className,
    children,
    parent,
    afterStateChanged,
    beforeStateChanged,
    allowsHitAreaCheck,
    offset,
    minWidth,
    id,
    handleBottomOverlap
  } = props
  const location = useLocation()
  const { isDesktop } = useContext(ScreenContext)
  const [initialIsOpen, setInitialIsOpen] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [parentZIndex, setParentZIndex] = useState(-1)
  const containerRef: any = useRef(null)
  const popoverRect: IRect = useComponentRect(containerRef)
  const forceUpdate = useForceUpdate()

  useImperativeHandle(ref, () => ({
    open,
    toggle,
    isOpen
  }))

  const changeIsOpen = useCallback(
    (value: boolean) => {
      if (beforeStateChanged) {
        beforeStateChanged(!value, value)
      }

      setIsOpen(value)
    },
    [setIsOpen, beforeStateChanged]
  )

  const onCloseEvent = useCallback(() => {
    changeIsOpen(false)
  }, [changeIsOpen])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    window.addEventListener(MODAL_SHOWN, onCloseEvent)

    return () => {
      window.removeEventListener(MODAL_SHOWN, onCloseEvent)
    }
  }, [onCloseEvent, isOpen])

  useEffect(() => {
    onCloseEvent()
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOpen) {
      return
    }

    const onFocusIn = () => {
      const activeElement = document.activeElement

      let parentNode: any = activeElement.parentNode
      while (parentNode) {
        if (parentNode === containerRef.current) {
          return
        }
        parentNode = parentNode.parentNode
        if (!parentNode) {
          changeIsOpen(false)
          return
        }
      }
    }
    document.addEventListener('focusin', onFocusIn)

    return () => {
      document.removeEventListener('focusin', onFocusIn)
    }
  }, [isOpen, changeIsOpen])

  useEffect(() => {
    if (!parent?.style) {
      return
    }

    if (isOpen) {
      const current: any = parent.style['z-index']
      if (parseInt(current, 10) !== zIndex.popover.parent) {
        setParentZIndex(current)
        parent.style['z-index'] = zIndex.popover.parent
      }
    } else {
      if (parentZIndex !== -1) {
        parent.style['z-index'] = parentZIndex
        setParentZIndex(-1)
      }
    }
  }, [isOpen, parent, setParentZIndex, parentZIndex])

  useEffect(() => {
    if (isOpen && (popoverRect.width === 0 || popoverRect.height === 0)) {
      setInitialIsOpen(true)
    }
    if (isOpen && popoverRect.width !== 0 && popoverRect.height !== 0) {
      setInitialIsOpen(false)
    }
  }, [isOpen, popoverRect, setInitialIsOpen, initialIsOpen])

  const open = useCallback(() => {
    changeIsOpen(true)
  }, [changeIsOpen])

  const toggle = useCallback(() => {
    changeIsOpen(!isOpen)
  }, [changeIsOpen, isOpen])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    const onDocumentClick = (event: MouseEvent) => {
      if (
        allowsHitAreaCheck &&
        event.x >= popoverRect.left &&
        event.x <= popoverRect.right &&
        event.y >= popoverRect.top &&
        event.y <= popoverRect.bottom
      ) {
        return
      }

      let path: any[] = (event as any).path
      if (!path) {
        // ff and safari workaround
        let done = false
        path = []
        let current: any = event.target
        while (current && !done) {
          path.push(current)
          current = current.parentElement
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
          path.push(document)
        }

        if (path.indexOf(window) === -1) {
          path.push(window)
          done = true
        }
      }

      for (const el of path) {
        try {
          if (el === parent?.parentNode) {
            return
          }
        } catch (_) {}
      }

      changeIsOpen(false)
    }

    const onWheelOrResize = () => {
      forceUpdate()
    }

    document.addEventListener('click', onDocumentClick)
    window.addEventListener('wheel', onWheelOrResize)
    window.addEventListener('touchmove', onWheelOrResize)
    window.addEventListener('resize', onWheelOrResize)

    return () => {
      document.removeEventListener('click', onDocumentClick)
      window.removeEventListener('wheel', onWheelOrResize)
      window.removeEventListener('touchmove', onWheelOrResize)
      window.removeEventListener('resize', onWheelOrResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, forceUpdate])

  useEffect(() => {
    if (afterStateChanged) {
      afterStateChanged(isOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const position: IPopoverPosition = useMemo(() => {
    if (!popoverRect || !parent) {
      return { top: 0, left: 0 }
    }

    const parentRect: any = parent.getBoundingClientRect()
    return getPopoverPosition({
      popoverRect,
      parentRect,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      limit: isDesktop ? 32 : 16,
      customOffset: offset,
      handleBottomOverlap
    })
  }, [offset, parent, popoverRect, isDesktop, handleBottomOverlap])

  if (!parent) {
    return null
  }

  return ReactDOM.createPortal(
    <Container
      id={id}
      ref={containerRef}
      className={className}
      $isOpen={isOpen || initialIsOpen}
      $initialIsOpen={initialIsOpen}
      $zIndex={zIndex.popover.parent + 1}
      $minWidth={minWidth}
      $top={position.top}
      $left={position.left}
    >
      <Background>{children}</Background>
    </Container>,
    parent
  )
})

Popover.displayName = 'Popover'

export default Popover

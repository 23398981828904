import React from 'react'
import styled, { css } from 'styled-components'
import { Icon, Button } from 'src/UIKit'
import { getCanBeShown, setCanBeShown } from 'src/utils/viewNextStepsNotice'
import createTheme from 'src/theme'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import {
  mobileMaxWidthMixin,
  timelineNoticeCloseButtonMixin,
  timelineNoticeContainerMixin,
  timelineNoticeTextMixin
} from 'src/theme/utils'
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router'
import { journeyMapRoute } from 'src/routes/constants'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  type?: string
  top: number
}

export const dataAttrs = {
  title: () => `next-steps-notice-title`
}

const { colors } = createTheme()

const Container = styled.div<{ $top?: number }>`
  ${timelineNoticeContainerMixin};
  ${mobileMaxWidthMixin};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
        `
      : css`
          font-weight: bold;
        `}
`

const CloseButton = styled.button`
  ${timelineNoticeCloseButtonMixin};
`

const TextContainer = styled.div<any>`
  flex: 1;
  display: flex;
  align-items: center;
`

const Text = styled(ReactMarkdown)`
  ${timelineNoticeTextMixin};

  p {
    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }
`

export const NextStepsButton = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: 160px;
          width: 100%;
        `
      : css`
          width: 100%;
          flex: 1;
          height: 48px;
          font-size: 16px;
          margin: 0 4px 0 8px;
        `}
`

const ButtonsContainer = styled.div`
  display: flex;
  padding-top: ${props => (props.theme.isDesktop ? '10px' : '6px')};
`

const ViewNextStepsNotice = React.memo((props: IProps) => {
  const { className, top, type } = props
  const { t } = useTranslation()
  const forceUpdate = useForceUpdate()
  const navigate = useNavigate()

  if (!getCanBeShown()) {
    return null
  }

  return (
    <Container className={className} $top={top}>
      <TitleContainer>
        <Title>
          {t([
            `viewNextStepsNotice.title_${type}`,
            'viewNextStepsNotice.title'
          ])}
        </Title>
        <CloseButton
          onClick={() => {
            setCanBeShown(false)
            forceUpdate()
          }}
          aria-label={t('common.accessibilityText.close')}
        >
          <Icon name={'close_crossing'} settings={{ fill: colors.main100 }} />
        </CloseButton>
      </TitleContainer>
      <TextContainer data-testid={dataAttrs.title()}>
        <Text>
          {t([
            `viewNextStepsNotice.content_${type}`,
            'viewNextStepsNotice.content'
          ])}
        </Text>
      </TextContainer>
      <ButtonsContainer>
        <NextStepsButton
          appearance="cancel"
          onClick={() => {
            setCanBeShown(false)
            navigate(journeyMapRoute)
          }}
        >
          {t('viewNextStepsNotice.viewNextSteps')}
        </NextStepsButton>
      </ButtonsContainer>
    </Container>
  )
})

ViewNextStepsNotice.displayName = 'ViewNextStepsNotice'

export default ViewNextStepsNotice

import React, { useCallback, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Toggle from 'src/components/Toggle'
import ScreenContext from 'src/contexts/ScreenContext'
import SupplementPayDetails from './SupplementPayDetails'
import { useTranslation } from 'react-i18next'
import Tooltip from 'src/components/Tooltip'
import { getLeaveTypeDisabilityGroupName } from 'src/utils/leaveUtils'

interface IProps {
  className?: string
  canUseSupplementPay: boolean
  useSupplementPay: boolean
  sickHoursToBeUsed: number
  vacationHoursToBeUsed: number
  onUseSupplementPayChanged: (useSupplementPay: boolean) => void
  disabled?: boolean
  leaveType: ILeaveType
  leaveSubtype: ILeaveSubType
  containsPartTimeMedical: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`

const RowContainer = styled.div<any>`
  display: flex;
  align-items: center;
  width: 100%;
  margin: ${props => (props.theme.isDesktop ? '0' : '12px 0 0')};
`

const TitleToggleContainer = styled.div<{ $disabled: boolean }>`
  cursor: ${props => (props.$disabled ? 'unset' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => {
    if (props.theme.isDesktop) {
      return css`
        margin-right: ${props.$disabled ? '17px' : '40px'};
      `
    }
  }}

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
    `}
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled.span`
  user-select: none;
  font-style: normal;
  margin-right: 12px;
  color: ${props => props.theme.colors.dark60};
  font-size: ${props => (props.theme.isMobile ? '16px' : '14px')};
  font-weight: ${props => (props.theme.isMobile ? '500' : '400')};
`

const NotAvailableText = styled.span`
  user-select: none;
  color: ${props => props.theme.colors.dark60};
  font-weight: normal;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          margin-right: 12px;
        `
      : css`
          font-size: 12px;
          padding-top: 10px;
        `}
`

const SupplementPayControlBar = React.memo((props: IProps) => {
  const {
    className,
    canUseSupplementPay,
    useSupplementPay,
    onUseSupplementPayChanged,
    sickHoursToBeUsed,
    vacationHoursToBeUsed,
    disabled,
    leaveType,
    leaveSubtype,
    containsPartTimeMedical
  } = props
  const { t } = useTranslation()
  const { isMobile } = useContext(ScreenContext)

  const onClick = useCallback(() => {
    if (disabled) {
      return
    }
    onUseSupplementPayChanged(!useSupplementPay)
  }, [useSupplementPay, onUseSupplementPayChanged, disabled])

  const title: string = useMemo(() => t('supplementPay.title'), [t])
  const usedDetails = useMemo(
    () =>
      !isMobile && (
        <SupplementPayDetails
          sickHoursToBeUsed={sickHoursToBeUsed}
          vacationHoursToBeUsed={vacationHoursToBeUsed}
        />
      ),
    [isMobile, sickHoursToBeUsed, vacationHoursToBeUsed]
  )

  const notAvailableText = useMemo(
    () =>
      !canUseSupplementPay && (
        <NotAvailableText aria-hidden>
          <Tooltip
            body={t([
              ...(containsPartTimeMedical
                ? [
                    `supplementPay.notAvailableTooltip.body_${leaveType}_PartTimeMedical`
                  ]
                : []),
              `supplementPay.notAvailableTooltip.body_${leaveType}_${leaveSubtype}`,
              `supplementPay.notAvailableTooltip.body_${leaveType}`,
              'supplementPay.notAvailableTooltip.body'
            ])}
          >
            {t('supplementPay.notAvailableSupplement')}
          </Tooltip>
        </NotAvailableText>
      ),
    [canUseSupplementPay, leaveSubtype, leaveType, containsPartTimeMedical, t]
  )

  const group = getLeaveTypeDisabilityGroupName(leaveType)

  return (
    <Container className={className}>
      <RowContainer>
        <TitleToggleContainer
          $disabled={disabled}
          onClick={isMobile ? null : onClick}
        >
          <LeftContainer>
            <Text aria-hidden>
              <Tooltip
                title={t('supplementPay.tooltip.title')}
                body={t(
                  `supplementPay.tooltip.description_${group}`,
                  'supplementPay.tooltip.description'
                )}
              >
                {title}
              </Tooltip>
            </Text>
          </LeftContainer>
          <Toggle
            toggled={useSupplementPay}
            ariaLabel={title}
            disabled={disabled}
            onClick={isMobile ? onClick : null}
          />
        </TitleToggleContainer>
        {!isMobile && notAvailableText}
        {usedDetails}
      </RowContainer>
      {isMobile && notAvailableText}
    </Container>
  )
})

SupplementPayControlBar.displayName = 'SupplementPayControlBar'

export default SupplementPayControlBar

import React from 'react'
import { IGraphqlQueries } from 'src/react-app-env'
import { IPriorityFilter } from 'src/components/JourneyMap/Filters'
import { NavigateFunction, Location } from 'react-router'

export interface IManagerJourneyMapContext {
  isOverdueMode: boolean
  onFilterClick: (index: number) => void
  onResetFiltersClick: () => void
  onOverdueClick: () => void
  onMobileFiltersExpandStateDidChange: (expanded: boolean) => void
  queries: IGraphqlQueries
  navigate: NavigateFunction
  location: Location
  blurred: boolean
  onToggleError: () => void
  priorityFilters: IPriorityFilter[]
  unarchivePlan: () => void
  currentAbsence: IAbsence
  isArchiveMode: boolean
  clickBack: () => void
}

const ManagerJourneyMapContext = React.createContext(null)

export default ManagerJourneyMapContext

import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import {
  isInformationalPeriod,
  isBondingPeriodNotSynced,
  isBondingPeriodInPast
} from 'src/utils/periodUtils'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import InfoMessageDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/InfoMessageDatePicker'
import moment from 'moment'
import { DATE_FORMAT, DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'

const MIN_DAYS_USED_BONDING = 5

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const showPeriodInfoNotice = isInformationalPeriod(input.period)
  const isMinDaysUsed =
    (input.period?.schedule?.usedInPeriod ?? MIN_DAYS_USED_BONDING) <
    MIN_DAYS_USED_BONDING

  const getAlerts = () => {
    const alerts = baseConfig.alerts
    if (isBondingPeriodInPast(input.leave, input.period)) {
      alerts.push('bondingInThePast')
    }
    if (isMinDaysUsed && input.period.appearance !== 'Addable') {
      alerts.push('bondingMinimumIncrements')
    }
    return alerts
  }

  return {
    ...baseConfig,
    periodPickerSelectedNotice: {
      show: true,
      showDoubleText: true
    },
    datePickerComponent: showPeriodInfoNotice
      ? InfoMessageDatePicker
      : MultiDatePicker,
    datePickerDisableConfirm: isMinDaysUsed,
    disableCreatePeriod: showPeriodInfoNotice,
    detailsExtraDescription: (t: any) =>
      isBondingPeriodNotSynced(input.leave, input.period)
        ? t(
            `timelineHints.periods.${baseConfig.detailsType}.extra.pendingConfirmation`
          )
        : null,
    detailsHolidays: {
      ...baseConfig.detailsHolidays,
      show: true
    },
    periodTopNotice: (t: any, useFormatWithoutDay: boolean) => {
      const date = input.period.blocks?.find(
        block => block.type.toLowerCase() === 'bonding leave'
      )?.endDate

      return date
        ? {
            tooltip: t('timeline.periodTopNotice.tooltip'),
            message: t('timeline.periodTopNotice.message'),
            date: moment(date)
              .utc()
              .format(useFormatWithoutDay ? DATE_FORMAT : DATE_FORMAT_WITH_DAY)
          }
        : null
    },
    datePickerFooterInfoMessage: (t: any) =>
      isMinDaysUsed
        ? t('timeline.datePickerAlerts.bondingLessThanNotice.text')
        : null,
    alerts: getAlerts(),
    // TODO: When the parameter sendTimelineToTPAEnabled is removed,
    // needs to remove this condition and the corresponding SendToTpaDisabled keys in the translation
    detailsHintPostfix:
      input.leave.metadata?.sendTimelineToTPAEnabled === false
        ? 'SendToTpaDisabled'
        : baseConfig.detailsHintPostfix,
    handleDatePickerBottomOverlap: true,
    // TODO: When the parameter sendTimelineToTPAEnabled is removed,
    // needs to remove this condition and the corresponding _sendToTpaDisabled keys in the translation
    datePickerDescription:
      input.leave.metadata?.sendTimelineToTPAEnabled === false
        ? input.t('timeline.leaveDurationPicker.BabyBonding_sendToTpaDisabled')
        : baseConfig.datePickerDescription
  }
}

import React, { ReactNode } from 'react'
import {
  eligibilityNoticePageRoute,
  publishedLeaveRoute,
  journeyMapRoute,
  timelineRoute
} from 'src/routes/constants'
import {
  isNewOrDraftLeave,
  isPublishedOrBabyArrivedLeave
} from 'src/utils/leaveStatusUtils'

import {
  CreateLeaveStage,
  IGraphqlExecutorOptions,
  IGraphqlQueries,
  ILeaveGroupWithTypes
} from 'src/react-app-env'
import CreateFamilyCareView from 'src/features/Leave/components/CreateFamilyCareView'
import CreateLeaveGroupView from './components/UI/CreateLeaveGroupView'
import CreateLeaveTpaApprovedViewContainer from 'src/features/Leave/components/TpaApprovedView/CreateLeaveTpaApprovedViewContainer'
import CreateMedicalView from 'src/features/Leave/components/CreateMedicalView'
import CreateMilitaryView from 'src/features/Leave/components/CreateMilitaryView'
import CreatePersonalView from 'src/features/Leave/components/CreatePersonalView'
import CreateSicknessView from 'src/features/Leave/components/CreateSicknessView'
import CreateStartDateView from 'src/features/Leave/components/CreateStartDateView'
import MedicalBeforeWeStartView from 'src/features/Leave/components/MedicalBeforeWeStartView'
import MilitaryDutyOrTrainingView from 'src/features/Leave/components/MilitaryDutyOrTrainingView'
import MiscarriageLeaveStatus from 'src/features/Leave/components/MiscarriageLeaveStatus'
import MiscarriageLengthView from 'src/features/Leave/components/MiscarriageLengthView'
import PickLeaveView from 'src/features/Leave/components/PickLeaveView'
import ReturningFromMilitaryView from './components/ReturningFromMilitaryView'
import SelectWorkSchedule from './components/SelectWorkSchedule'
import SetupOneWeekSchedule from './components/SetupOneWeekSchedule'
import SetupTwoWeekSchedule from './components/SetupTwoWeekSchedule'

import Workflow, { wf } from 'src/utils/workflow'
import { isRoche } from 'src/utils/userUtils'
import { NavigateFunction, createSearchParams } from 'react-router'
import SelectStateView from './components/SelectStateView'
import SelectLeavePreference from './components/SelectLeavePreference'
import { Moment } from 'moment'
import { LEAVE_PREFERENCE_DATE } from 'src/utils/dateUtils'
import CongratsView from './components/CongratsView'
import {
  ICustomerConfig,
  ICustomerLeaveCreateConfig
} from 'src/config/customers/config'
import SelectTpaLeaveView from './components/SelectTpaLeaveView'

enum MultipleBirthsLeaveType {
  MultiAdoption = 'MultiAdoption',
  MultiPartner = 'MultiPartner',
  MultiPregnancy = 'MultiPregnancy',
  MultiSurrogate = 'MultiSurrogate'
}

export const onLeaveResult = (props: {
  t: any
  navigate: NavigateFunction
  customer: string
  useTpaLeave: boolean
  toastManager: any
  leaveResult: ILeave
  setIsFetchingLeave: (value: boolean) => void
}): any => {
  const {
    t,
    navigate,
    customer,
    useTpaLeave,
    toastManager,
    leaveResult,
    setIsFetchingLeave
  } = props

  if (leaveResult) {
    if (useTpaLeave) {
      navigate({
        pathname: publishedLeaveRoute,
        search: createSearchParams({ dest: 'timeline' }).toString()
      })
      return
    }

    if (isNewOrDraftLeave(leaveResult.status)) {
      const notice: string = leaveResult.note
      if (notice && !isRoche(customer)) {
        navigate(eligibilityNoticePageRoute, {
          state: { notice }
        })
      } else {
        navigate(timelineRoute)
      }
    } else if (isPublishedOrBabyArrivedLeave(leaveResult.status)) {
      navigate(journeyMapRoute)
    } else {
      try {
        toastManager.addError(t('common.somethingWentWrong'), 'default')
      } catch (_) {
        return undefined
      }
    }
  } else {
    setIsFetchingLeave(false)
  }
}

export const addNewLeaveGroupWithName = (
  name: string,
  leaveGroups: ILeaveGroupWithTypes[]
): ILeaveGroupWithTypes => {
  const group: ILeaveGroupWithTypes = {
    name,
    enabled: false,
    hidden: true,
    leaveTypes: []
  }
  leaveGroups.push(group)
  return group
}

export const getLeaveGroupWithName = (
  name: string,
  leaveGroups: ILeaveGroupWithTypes[]
): ILeaveGroupWithTypes => {
  for (const g of leaveGroups) {
    if (g.name === name) {
      return g
    }
  }

  return null
}

export const addLeaveTypeInfoToGroup = (
  leaveTypeInfo: ILeaveTypeInfo,
  leaveGroups: ILeaveGroupWithTypes[]
): void => {
  let group: ILeaveGroupWithTypes = getLeaveGroupWithName(
    leaveTypeInfo.group,
    leaveGroups
  )

  if (!group) {
    group = addNewLeaveGroupWithName(leaveTypeInfo.group, leaveGroups)
  }

  if (leaveTypeInfo.enabled) {
    group.enabled = true
  }

  if (!leaveTypeInfo.hidden) {
    group.hidden = false
  }

  group.leaveTypes.push(leaveTypeInfo)
}

export const canSelectLeavePreference = (dueDate: Moment) =>
  dueDate.startOf('day').isSameOrAfter(LEAVE_PREFERENCE_DATE)

const workflowParams = (
  config: ICustomerLeaveCreateConfig,
  selectedLeaveGroupName: string
) => {
  const stages = config.workflow[selectedLeaveGroupName]
  const init = stages.init || selectedLeaveGroupName.toLowerCase()
  return { init, stages }
}

export const getLeaveCreateWorkflow = (
  config: ICustomerLeaveCreateConfig,
  leaveGroups: any
): Workflow<CreateLeaveStage> => {
  if (leaveGroups.length === 1) {
    const selectedLeaveGroupName = leaveGroups[0].name
    const { init, stages } = workflowParams(config, selectedLeaveGroupName)
    return wf<CreateLeaveStage>({ init, stages })
  }

  return wf<CreateLeaveStage>({
    init: 'pickGroup',
    stages: {
      pickGroup: ({ selectedLeaveGroupName, workflow }) => {
        if (!selectedLeaveGroupName) {
          return 'error'
        }
        const { init, stages } = workflowParams(config, selectedLeaveGroupName)
        workflow.addStages(stages)
        return init
      }
    }
  })
}

export const getCurrentStageView = (
  stage: CreateLeaveStage,
  customerConfig: ICustomerConfig
): ReactNode => {
  switch (stage) {
    case 'parental':
    case 'paternity':
      return <CreateLeaveGroupView selectorWidth={'360px'} />
    case 'sabbatical':
      return <CreateStartDateView leaveType={'sabbatical'} />
    case 'medicalBeforeWeStart':
      return <MedicalBeforeWeStartView />
    case 'medical':
      return <CreateMedicalView />
    case 'family':
      return <CreateFamilyCareView />
    case 'militaryTraining':
      return <MilitaryDutyOrTrainingView />
    case 'military':
      return <CreateMilitaryView />
    case 'militaryReturning':
      return <ReturningFromMilitaryView />
    case 'personal':
      return <CreatePersonalView />
    case 'tpaApproval':
      return <CreateLeaveTpaApprovedViewContainer />
    case 'pickGroup':
      return <PickLeaveView />
    case 'sickness':
      return <CreateSicknessView />
    case 'miscarriage':
      return <CreateLeaveGroupView selectorWidth={'360px'} />
    case 'miscarriageLength':
      return <MiscarriageLengthView />
    case 'miscarriageLeaveStatus':
      return <MiscarriageLeaveStatus />
    case 'selectWorkSchedule':
      return <SelectWorkSchedule />
    case 'setupOneWeekSchedule':
      return <SetupOneWeekSchedule />
    case 'setupTwoWeekSchedule':
      return <SetupTwoWeekSchedule />
    case 'selectState':
      return <SelectStateView selectorWidth={'360px'} />
    case 'selectLeavePreference':
      const { preferences } = customerConfig.leave.create
      return <SelectLeavePreference preferences={preferences} />
    case 'congrats':
      return <CongratsView />
    case 'selectTpa':
      return <SelectTpaLeaveView />

    default:
      return null
  }
}

export const createLeaveMutation = async (
  queries: IGraphqlQueries,
  options: IGraphqlExecutorOptions,
  props: any
): Promise<ILeave> => {
  const {
    customerLeaveCreateConfig,
    extra,
    injuryState,
    isMoreThanOneChild,
    leaveStatus,
    selectedDueDate,
    selectedEndDate,
    selectedLeaveGroupName,
    selectedLeaveType,
    selectedReinstatementDate,
    selectedStartDate,
    subtype,
    tpaApprovalState
  } = props

  switch (selectedLeaveGroupName) {
    case 'Medical':
    case 'Personal':
      return queries.createLeaveMedicalOrPersonal(
        selectedLeaveGroupName,
        selectedStartDate,
        selectedEndDate,
        tpaApprovalState === 'approved',
        injuryState === 'yes',
        extra
      )
    case 'Family':
      return customerLeaveCreateConfig.useFamilyEndDate
        ? queries.createLeaveFamily(selectedStartDate, selectedEndDate)
        : queries.createLeaveFamily(selectedStartDate)
    case 'Military':
      return queries.createLeaveMilitary(
        selectedStartDate,
        selectedEndDate,
        selectedReinstatementDate,
        tpaApprovalState === 'approved'
      )
    case 'Sickness':
      return queries.createLeaveSickness(selectedStartDate, selectedEndDate)
    case 'Miscarriage':
      return queries.createLeaveMiscarriage(
        selectedLeaveType.type,
        selectedLeaveType.type === 'Miscarriage' ? subtype : null,
        selectedDueDate,
        leaveStatus === 'yesOnLeave' ? selectedStartDate : null
      )
    case 'Sabbatical':
      return queries.createLeaveSabbatical(selectedStartDate)
    default:
      return queries.createLeaveParental(
        isMoreThanOneChild
          ? getMultipleBirthsLeaveType(selectedLeaveType.type)
          : selectedLeaveType.type,
        selectedDueDate,
        extra
      )
  }
}

export const createFromTpaLeaveMutation = async (
  queries: IGraphqlQueries,
  options: IGraphqlExecutorOptions,
  props: any
): Promise<ILeave> => {
  const {
    extra,
    selectedDueDate,
    selectedStartDate,
    selectedEndDate,
    selectedLeaveType
  } = props
  return queries.createLeaveFromTpa(
    extra,
    selectedLeaveType.type,
    selectedDueDate,
    selectedStartDate,
    selectedEndDate,
    options
  )
}

const getMultipleBirthsLeaveType = (
  leaveType: ILeaveType
): MultipleBirthsLeaveType => {
  switch (leaveType) {
    case 'Pregnancy':
      return MultipleBirthsLeaveType.MultiPregnancy
    case 'Partner':
      return MultipleBirthsLeaveType.MultiPartner
    case 'Adoption':
      return MultipleBirthsLeaveType.MultiAdoption
    case 'Surrogate':
      return MultipleBirthsLeaveType.MultiSurrogate
    default:
      throw Error(`unsupported leave type ${leaveType}`)
  }
}

export const isMultiBirthLeaveType = (leaveType: ILeaveType) =>
  leaveType.startsWith('Multi')

export const processEligibilityStatus = (
  leaveType: ILeaveTypeInfo,
  t: any,
  navigate: NavigateFunction
) => {
  const { status, reason } = leaveType.eligibility || {}

  if (status === 'NonEligible' && reason !== 'None') {
    const title = t('eligibilityNotice.page.title', {
      leaveType: leaveType.type
    })

    const notice = t(`eligibilityNotice.page.text_${reason}`, {
      leaveType: leaveType.type
    })

    const button = {
      title: t('common.back'),
      appearance: 'cancel'
    }

    navigate(eligibilityNoticePageRoute, {
      state: { title, notice, button }
    })
  }
}

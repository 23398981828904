import React, { useContext } from 'react'
import { Button, H1 } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { loginUrl } from 'src/config'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'
import redirectByUserRole from 'src/utils/redirectByUserRole'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const TextWrapper = styled.p`
  color: ${props => props.theme.colors.dark60};
  line-height: 150%;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
          font-size: 16px;
        `
      : css`
          width: 100%;
          font-size: 14px;
          text-align: center;
        `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin-bottom: 16px;
    `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}

  ${mobileButtonMaxWidthMixin};
`

export const ThanksPage = React.memo(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const sharedContext: ISharedContext = useContext(SharedContext)

  const userResult: IUseUser = useUser('network-only')
  const isLoggedIn = !!userResult.user

  usePageTitle('thanks')

  return (
    <Container>
      <H1>{t('thanksPage.title')}</H1>
      <TextWrapper>{t('thanksPage.body')}</TextWrapper>
      <ButtonContainer>
        <ButtonWrapper
          onClick={() => {
            if (isLoggedIn) {
              redirectByUserRole(
                userResult.user,
                navigate,
                sharedContext.apolloClient
              )
            } else {
              window.open(loginUrl(), '_self')
            }
          }}
        >
          {t(
            isLoggedIn ? 'thanksPage.buttonGoHome' : 'thanksPage.buttonSignIn'
          )}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})

ThanksPage.displayName = 'ThanksPage'

export default withRouter(ThanksPage)

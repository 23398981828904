import React, { useEffect, useMemo, useState } from 'react'
import { Button, H1, MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { journeyMapRoute, timelineRoute } from 'src/routes/constants'
import useLeaveWithDates, {
  IUseLeaveWithDates
} from 'src/graphql/hooks/useLeaveWithDates'
import withQueries from 'src/components/HOC/withQueries'
import { IWithQueriesProps, IWithToastManager } from 'src/react-app-env'
import LoadingSpinner from 'src/components/LoadingSpinner'
import LeaveInfoView from 'src/components/LeaveInfoView'
import { isPublishedOrBabyArrivedLeave } from 'src/utils/leaveStatusUtils'
import usePrevious from 'src/components/hooks/usePrevious'
import { withToastManager } from 'src/components/ToastManager'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { useNavigate, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import moment from 'moment'

interface IProps extends IWithQueriesProps, IWithToastManager {}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
      margin: 0 auto;
    `}

  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  width: 100%;
`

const TextWrapper = styled(MarkdownText)`
  color: ${props => props.theme.colors.dark60};
  line-height: 150%;
  white-space: pre-wrap;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 736px;
          font-size: 16px;
        `
      : css`
          width: 100%;
          font-size: 14px;
        `}
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          align-items: center;
          margin: 32px 0 0;
        `
      : css`
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          margin: 0 0 16px;
          width: 100%;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}
`

const LoadingSpinnerContainer = styled.div`
  margin: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const LeaveInfoViewWrapper = styled(LeaveInfoView)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 24px 0 0;
        `
      : css`
          margin: 16px 0 0;
        `}
`

export const dataAttrs = {
  title: () => 'plan-page-title',
  body: () => 'plan-page-body'
}

export const PlanPage = React.memo((props: IProps) => {
  const { toastManager } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [isClosingOut, setIsClosingOut] = useState(false)

  const leaveResult: IUseLeaveWithDates = useLeaveWithDates({})
  const prevLeaveResult: IUseLeaveWithDates = usePrevious(leaveResult)

  useEffect(() => {
    if (leaveResult.error) {
      navigate(journeyMapRoute)
    }

    if (leaveResult.leave) {
      if (prevLeaveResult.leave === leaveResult.leave) {
        return
      }
      const { status, id } = leaveResult.leave
      if (!isPublishedOrBabyArrivedLeave(status)) {
        navigate(timelineRoute)
      }
      if ((state as any)?.leaveId && (state as any).leaveId !== id) {
        navigate(-1)
        window.setTimeout(() => {
          toastManager.addInfo(t('common.wrongPlanReference'))
        }, 100)
      }
    }
  }, [leaveResult, prevLeaveResult, navigate, state, t, toastManager])

  useRedirectByUserRole({ navigate, allowedRole: 'employee' })
  usePageTitle('outdatedPlan')

  const autoSuspendDate: string = useMemo(
    () =>
      leaveResult.leave?.metadata?.autoSuspendDate
        ? moment(leaveResult.leave.metadata.autoSuspendDate)
            .utc()
            .format(DATE_FORMAT)
        : null,
    [leaveResult.leave]
  )

  return (
    <Container>
      <Title data-testid={dataAttrs.title()}>{t('planPage.title')}</Title>
      <TextWrapper data-testid={dataAttrs.body()} allowsParagraph>
        {t('planPage.body', { autoSuspendDate })}
      </TextWrapper>
      {isClosingOut || leaveResult?.loading || !leaveResult?.leave ? (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ) : (
        <>
          <LeaveInfoViewWrapper leave={leaveResult.leave} />
          <ButtonsContainer>
            <ButtonWrapper
              appearance={'cancel'}
              onClick={() => {
                navigate(journeyMapRoute)
              }}
            >
              {t('planPage.buttonContinue')}
            </ButtonWrapper>
            <ButtonWrapper
              onClick={async () => {
                setIsClosingOut(true)
                await props.queries.suspendLeave(false, false)
                navigate(journeyMapRoute)
              }}
            >
              {t('planPage.buttonCloseOut')}
            </ButtonWrapper>
          </ButtonsContainer>
        </>
      )}
    </Container>
  )
})

PlanPage.displayName = 'PlanPage'

export default withToastManager(withQueries(PlanPage))

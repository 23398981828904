import React, { useCallback, useMemo, useState } from 'react'
import { Button, H1, MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'
import {
  errorPageRoute,
  deniedClaimStatusRoute,
  timelineRoute
} from 'src/routes/constants'
import withQueries from 'src/components/HOC/withQueries'
import { IWithQueriesProps } from 'src/react-app-env'

import { isPathnameLike } from 'src/utils/routeUtils'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import { useNavigate, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
import LoadingSpinner from 'src/components/LoadingSpinner'

interface IProps extends IWithQueriesProps {}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
      margin: 0 auto;
    `}

  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  width: 100%;
`

const TextWrapper = styled(MarkdownText)`
  color: ${props => props.theme.colors.dark60};
  line-height: 150%;
  white-space: pre-wrap;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
          font-size: 16px;
        `
      : css`
          width: 100%;
          font-size: 14px;
        `}

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          align-items: center;
          margin: 32px 0 0;
        `
      : css`
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          margin: 0 0 16px;
          width: 100%;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}
`

export const dataAttrs = {
  title: () => 'claim-status-title',
  body: () => 'claim-status-body'
}

export const ClaimStatusPage = React.memo((props: IProps) => {
  const { queries } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const employeeEmail: string = useMemo(
    () => state && (state as any).employeeEmail,
    [state]
  )

  const periodStartDate: string = useMemo(
    () => state && (state as any).periodStartDate,
    [state]
  )

  const routeKey: string = isPathnameLike(pathname, deniedClaimStatusRoute)
    ? 'denied'
    : 'extensionDenied'

  usePageTitle(`${routeKey}Claim`)

  const onButtonClicked = useCallback(async () => {
    try {
      setIsLoading(true)
      const result: boolean = await queries.confirmDeniedLeave()
      if (result) {
        navigate(timelineRoute)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [navigate, queries])

  if (isLoading) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  return (
    <Container>
      <Title data-testid={dataAttrs.title()}>
        {t(`claimStatusPage.title.${routeKey}`)}
      </Title>
      <TextWrapper
        data-testid={dataAttrs.body()}
        allowsParagraph
        urlTransform={TelUrlTransformer}
        components={{ a: LinkRenderer }}
      >
        {t(`claimStatusPage.body.${routeKey}`, {
          employeeEmail,
          periodStartDate
        })}
      </TextWrapper>
      <ButtonContainer>
        <ButtonWrapper
          onClick={() => {
            onButtonClicked()
          }}
        >
          {t(routeKey === 'denied' ? 'common.close' : 'common.next')}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})

ClaimStatusPage.displayName = 'ClaimStatusPage'

export default withQueries(ClaimStatusPage)

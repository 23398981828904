import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import bgImage from 'src/assets/images/clouds-background-min.jpg'
import withRouter from 'src/components/hooks/useRouter'
import { useLocation, useNavigate, matchPath } from 'react-router'
import {
  helpCenterRoute,
  journeyMapRoute,
  notificationsRoute,
  timelineRoute,
  routesWithFooter,
  welcomeRoute,
  managerDashboardRoute,
  managerArchiveRoute,
  managerNotificationsRoute,
  routesWithoutMenu,
  managerHelpCenterRoute,
  globalSurveysRoute,
  routesWithoutBanner,
  userProfileRoute
} from 'src/routes/constants'
import { isPathnameLike, isPathnameLikeOneOf } from 'src/utils/routeUtils'
import { printBackgroundWhiteMixin } from 'src/theme/utils'
import usePrevious from 'src/components/hooks/usePrevious'
import ScreenContext from 'src/contexts/ScreenContext'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import onAppStarted from 'src/utils/onAppStarted'
import { useIdleTimer } from 'react-idle-timer'
import { header, footer } from 'src/constants/frame'
import SharedContext from 'src/contexts/SharedContext'
import InformationBanner from '../InformationBanner'
import SurveyGlobalView, { ISurveyParams } from '../Surveys/SurveyGlobalView'
import { withDialogManager } from '../DialogManager'
import { IWithDialogManager } from 'src/react-app-env'

interface IProps extends IWithDialogManager {
  children: any
  client: ApolloClient<NormalizedCacheObject>
  setPathname: (p: string) => void
  setIsLoading: (l: boolean) => void
  setCustomer: (c: string) => void
}

const Grid = styled.div<{
  $pathname: string
  $rendersFooter: boolean
  $bannerHeight: number
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:
    calc(${p => header.height(p.theme).px} + ${p => p.$bannerHeight}px)
    auto 40px;
  ${props =>
    props.$rendersFooter
      ? css`
          grid-template-areas:
            'header'
            'children'
            'footer';
        `
      : css`
          grid-template-areas:
            'header'
            'children'
            'children';
        `}
  min-height: 100%;

  ${props =>
    isPathnameLike(props.$pathname, welcomeRoute)
      ? css`
          background-color: linear-gradient(
              0deg,
              #fcfcfc 0%,
              rgb(252 252 252 / 0%) 22.56%
            ),
            linear-gradient(0deg, #d6e9ff, #d6e9ff);
          background-image: url(${bgImage});
          background-blend-mode: normal, color, normal;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          background: ${props.theme.colors.backgroundColor};
        `}

  ${printBackgroundWhiteMixin}

  @media print {
    display: flex;
    width: 297mm;
  }
`

const ChildrenWrapper = styled.div<{
  $pathname: string
  $bannerHeight: number
}>`
  grid-area: children;
  justify-self: center;

  @media print {
    height: auto;
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          height: calc(
            var(--vh, 1vh) * 100 - ${props.$bannerHeight}px -
              ${header.height(props.theme).px} - ${footer.height().px}
          );
        `
      : css`
          height: calc(
            var(--vh, 1vh) * 100 - ${props.$bannerHeight}px -
              ${header.height(props.theme).px}
          );
        `}

  ${props =>
    isPathnameLike(props.$pathname, welcomeRoute) &&
    css`
      align-self: center;
      height: auto;
    `}

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
    `}

  ${props => {
    const pathname = props.$pathname

    if (isPathnameLike(pathname, userProfileRoute)) {
      return css`
        height: auto;
      `
    }

    if (isPathnameLike(pathname, welcomeRoute)) {
      return css`
        ${props.theme.isDesktop
          ? css`
              align-self: center;
            `
          : css`
              width: unset;
            `}
      `
    }

    if (isPathnameLike(pathname, timelineRoute)) {
      return css`
        ${props.theme.isDesktop
          ? css`
              min-width: 960px;
              width: 100%;
            `
          : css`
              width: 100%;
            `}
      `
    }

    if (
      isPathnameLikeOneOf(pathname, [
        journeyMapRoute,
        managerDashboardRoute,
        managerArchiveRoute
      ])
    ) {
      return css`
        justify-self: stretch;
      `
    }

    if (
      isPathnameLikeOneOf(pathname, [
        notificationsRoute,
        managerNotificationsRoute
      ])
    ) {
      return css`
        ${props.theme.isDesktop &&
        css`
          width: 100%;
        `}
      `
    }

    if (
      isPathnameLikeOneOf(pathname, [helpCenterRoute, managerHelpCenterRoute])
    ) {
      return css`
        ${props.theme.isDesktop
          ? css`
              width: 716px;
              height: 100%;
            `
          : css`
              width: unset;
              height: 100%;
            `}
      `
    }
  }}
`

export const HeaderWrapper = styled(Header)`
  height: ${props => header.height(props.theme).px};
`

export const FooterWrapper = styled(Footer)`
  height: ${footer.height().px};
`

export const PageWrapper = React.memo((props: IProps) => {
  const { children, client, setPathname, setIsLoading, dialogManager } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const prevPathname: string = usePrevious(pathname)
  const { isDesktop } = useContext(ScreenContext)
  const { customerConfig, bannerContext } = useContext(SharedContext)
  const [surveyGlobalParams, setSurveyGlobalParams] =
    useState<ISurveyParams>(null)

  useEffect(() => {
    if (pathname !== prevPathname) {
      window.scrollTo(0, 0)
    }
  }, [pathname, prevPathname])

  useEffect(() => {
    const onResize = () => {
      window.setTimeout(() => {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        // delay for animated orientation change
      }, 500)
    }

    window.addEventListener('resize', onResize)
    onResize()
    onAppStarted(
      client,
      navigate,
      location,
      () => setIsLoading(false),
      customerConfig
    )

    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPathname(location.pathname)

    const surveysMatch = matchPath(globalSurveysRoute, location.pathname)
    if (surveysMatch) {
      setSurveyGlobalParams({
        surveyId: surveysMatch.params.id,
        answerValue: surveysMatch.params.value
      })
    }
  }, [setPathname, location])

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: () => {
      navigate(welcomeRoute)
      setSurveyGlobalParams(null)
      client.clearStore()
      dialogManager.remove()
    }
  })

  const bannerView: ReactNode = useMemo(() => {
    if (isPathnameLikeOneOf(pathname, routesWithoutBanner)) {
      return null
    }

    return <InformationBanner />
  }, [pathname])

  const headerView: ReactNode = useMemo(() => {
    if (isPathnameLikeOneOf(pathname, [welcomeRoute])) {
      return null
    }

    const hideMenu = isPathnameLikeOneOf(pathname, routesWithoutMenu)
    return (
      <HeaderWrapper
        hideMenu={hideMenu}
        marginTop={bannerContext.bannerHeight}
      />
    )
  }, [bannerContext.bannerHeight, pathname])

  const footerView: ReactNode = useMemo(() => {
    if (!isDesktop) {
      return null
    }
    return isPathnameLikeOneOf(pathname, routesWithFooter) && <FooterWrapper />
  }, [pathname, isDesktop])

  return (
    <>
      {bannerView}
      <Grid
        $pathname={location.pathname}
        $rendersFooter={!!footerView}
        $bannerHeight={bannerContext.bannerHeight}
      >
        {headerView}
        <ChildrenWrapper
          $pathname={location.pathname}
          $bannerHeight={bannerContext.bannerHeight}
          role={'main'}
        >
          {surveyGlobalParams && (
            <SurveyGlobalView
              surveyParams={surveyGlobalParams}
              onClose={() => {
                setSurveyGlobalParams(null)
              }}
            />
          )}
          {surveyGlobalParams && !isDesktop ? <div /> : children}
        </ChildrenWrapper>
        {footerView}
      </Grid>
    </>
  )
})

PageWrapper.displayName = 'PageWrapper'

export default withDialogManager(withRouter(PageWrapper))

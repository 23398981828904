import React, { Fragment, useContext, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { IWithQueriesProps } from 'src/react-app-env'
import createTheme from 'src/theme'
import { openItem } from 'src/features/Notifications/methods'
import withQueries from 'src/components/HOC/withQueries'
import { useTranslation } from 'react-i18next'
import NoRecordsView from 'src/features/Notifications/components/NoRecordsView'
import RouteContext from 'src/routes/Context'
import { Icon } from 'src/UIKit'
import useKeyActionHandler from 'src/components/hooks/useKeyActionHandler'
import NotificationsList from '../NotificationsList'
import { useNavigate } from 'react-router'

const { colors } = createTheme()

interface IProps extends IWithQueriesProps {
  records: INotification[]
  fetching: boolean
  fetchCount: number
  onMarkAllAsReadClicked: () => void
  onSeeAllClicked: () => void
  popover?: any
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(2, min-content) 1fr;
  grid-template-areas:
    'title'
    'list'
    'bottom';
`

const BottomWrapper = styled.div`
  grid-area: bottom;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 16px;
`

const Link = styled.a`
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  color: ${props => props.theme.colors.main90};

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }
`

const ListWrapper = styled(NotificationsList)`
  grid-area: list;
`

const rotateFeyFrames = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
}
`

const FetchingImage = styled.div`
  grid-area: list;
  width: 12px;
  height: 20px;
  place-self: end center;
  transform-origin: center center;
  animation: ${rotateFeyFrames} 2s linear 0s infinite;
`

const NotificationsPopoverContent = React.memo((props: IProps) => {
  const {
    onMarkAllAsReadClicked,
    records,
    fetching,
    onSeeAllClicked,
    popover
  } = props

  const navigate = useNavigate()
  const { t } = useTranslation()

  const markAllAsReadRef: any = useRef(null)
  const seeAllRef: any = useRef(null)

  useKeyActionHandler(markAllAsReadRef.current, onMarkAllAsReadClicked)

  useKeyActionHandler(seeAllRef.current, onSeeAllClicked)

  const togglePopover = () => {
    if (popover) {
      popover.toggle()
    }
  }

  const hasRecords: boolean = records.length !== 0
  const { notificationType } = useContext(RouteContext)

  const renderRecordsView = () => (
    <Fragment>
      <ListWrapper
        items={records}
        fetching={fetching}
        useAlternativeItems
        onItemClicked={notification => {
          togglePopover()
          openItem(
            notification,
            navigate,
            props.queries.readNotification,
            notificationType
          )
        }}
      />
      <BottomWrapper>
        <Link
          onClick={onMarkAllAsReadClicked}
          tabIndex={0}
          role={'button'}
          ref={markAllAsReadRef}
        >
          {t('common.markAllAsRead')}
        </Link>
        <Link
          onClick={() => {
            togglePopover()
            onSeeAllClicked()
          }}
          tabIndex={0}
          role={'button'}
          ref={seeAllRef}
        >
          {t('common.seeAll')}
        </Link>
      </BottomWrapper>
      {fetching && (
        <FetchingImage>
          <Icon
            name={'hourglass'}
            settings={{ fill: colors.main100 }}
            ariaLabel={t('common.accessibilityText.loadingIcon')}
          />
        </FetchingImage>
      )}
    </Fragment>
  )

  const renderNoRecordsView = () => <NoRecordsView />

  return <Grid>{hasRecords ? renderRecordsView() : renderNoRecordsView()}</Grid>
})

NotificationsPopoverContent.displayName = 'NotificationsPopoverContent'

export default withQueries(NotificationsPopoverContent)

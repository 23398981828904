import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { isFirefox, isSafari } from 'src/utils/deviceTypes'
import { Button, H1, Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import zIndex from 'src/constants/zIndex'
import ReactMarkdown from 'react-markdown'
import ModalsView from 'src/components/ModalsView'
import ItemInfoView from 'src/components/JourneyMap/JourneyMapItemView/components/InfoView'
import { useTranslation } from 'react-i18next'
import PrintButton from 'src/components/PrintButton'
import {
  mobileButtonMaxWidthMixin,
  mobileMaxWidthMixin,
  printDisplayNoneMixin
} from 'src/theme/utils'
import PrintFooter from 'src/components/PrintFooter'
import BackButton from 'src/components/BackButton'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import SurveyContainer, {
  SURVEY_STATE
} from 'src/components/Surveys/SurveyContainer'
import { withToastManager } from 'src/components/ToastManager'
import { IWithQueriesProps, IWithToastManager } from 'src/react-app-env'
import { userProfileRoute } from 'src/routes/constants'
import {
  createMeeting,
  IMeetingLinkParams,
  parseMeetingLink
} from 'src/utils/icsMaker'
import { Moment } from 'moment'
import UserContext, { IUserContext } from 'src/contexts/UserContext'
import useCreateMetric from 'src/graphql/hooks/useCreateMetric'
import {
  MetricEventJourneyMapClick,
  MetricEventJourneyMapImpression
} from 'src/constants/metrics'
import withQueries from 'src/components/HOC/withQueries'
import ReactFocusLock from 'react-focus-lock'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import VideoView from 'src/components/JourneyMap/JourneyMapItemView/components/VideoView'
import ScreenContext from 'src/contexts/ScreenContext'
import AttachmentView from '../AttachmentView'
import CompletedView from '../CompletedView'
import { NavigateFunction } from 'react-router'
import { LinkRenderer } from 'src/utils/contentRenderUtils'

const { colors } = createTheme()

interface IProps extends IWithToastManager, IWithQueriesProps {
  className?: string
  item: IJourneyMapItem
  onItemCompletedClick: () => void
  onClose: () => void
  navigate: NavigateFunction
  leaveId?: string
}

const Container = styled.div`
  z-index: ${zIndex.journeyMap.itemView.container};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media print {
    margin-top: 32px;
  }
`

const ContentContainer = styled.div<{ $isSurvey?: boolean }>`
  background: ${props => props.theme.colors.light100};
  display: flex;
  flex-direction: column;
  ${props =>
    props.theme.isDesktop
      ? css`
          border-radius: 16px;
          max-width: 1440px;
          width: 65vw;
          max-height: 90vh;
          min-height: 40%;
          height: ${props.$isSurvey ? '700px' : 'unset'};
        `
      : css`
          width: 100%;
          height: 100%;
        `}

  @media print {
    border-radius: 0;
    width: 100%;
    max-height: unset;
    min-height: unset;
    box-shadow: none;
    display: block;
  }
`

const TitleAndCloseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TitleWrapper = styled(H1)`
  text-align: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 auto;
          max-width: 90%;
        `
      : css`
          @media not print {
            border: unset;
            display: inline-block;
            padding: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 130%;
            color: ${props.theme.colors.dark80};
            width: 100%;
          }

          @media print {
            color: ${props.theme.colors.dark60};
            display: table;
            margin: 0 auto;
          }
        `};
`

export const CloseButton = styled.button`
  transition: border 100ms;
  width: 30px;
  height: 30px;
  margin: 9px 14px -15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  ${printDisplayNoneMixin};

  &:hover {
    border: 2px solid ${props => props.theme.colors.main100};
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main100};
  }
`

const CloseIcon = styled(Icon)`
  height: 14px;
`

const ScrollView = styled.div`
  flex: 1;
  overflow-y: auto;
  ${props =>
    props.theme.isDesktop
      ? css`
          -ms-overflow-style: -ms-autohiding-scrollbar;
          scrollbar-width: none;
          padding: 16px 40px;
        `
      : css`
          width: calc(100% - 32px);
          padding: 0 16px 8px;
          margin: 0 auto;
        `}

  ${mobileMaxWidthMixin};

  @media print {
    overflow-y: visible;
    margin-bottom: 16px;
    margin-left: 0;
    padding: 16px 40px;
  }
`

const MobileItemInfoContainer = styled.div`
  margin: 0 16px;
`

const Description = styled(ReactMarkdown)`
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }

  ul {
    list-style: disc;
    padding: 0.5em 0 0.5em 2em;
  }

  ol {
    list-style: decimal;
    padding: 0.5em 0 0.5em 2em;
  }

  li:last-child {
    padding-bottom: 0.5em;
  }

  h4,
  strong {
    font-weight: 500;
  }

  em {
    font-style: italic;
  }

  code {
    text-decoration: underline;
  }

  p {
    padding-bottom: 0.5em;
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
        `
      : css`
          font-size: 14px;
          text-align: justify;
          margin: 16px 0 80px;
        `}
  @media print {
    font-size: 14pt;
    ${isFirefox() &&
    css`
      font-size: 12px;
    `}

    p {
      position: relative;
      page-break-inside: avoid;
    }

    a {
      color: ${props => props.theme.colors.dark60};
    }
  }
`

const BottomPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 16px;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
  min-height: 80px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  ${printDisplayNoneMixin}
`

const PrintFooterWrapper = styled(PrintFooter)`
  position: fixed;
  bottom: 2mm;
  left: 0;
  right: 0;
  margin: 0 11mm;

  ${isSafari() &&
  css`
    position: relative;
  `}
`

const PrintAndCtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const MarkCompletedWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const BackAndMarkCompletedWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  ${printDisplayNoneMixin}
`

const CtaButtonMobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 80%);
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 80px;
  z-index: ${zIndex.journeyMap.itemView.mobileCtaButtonWrapper};
`

const CtaButtonWrapper = styled(Button)<{ $isSurvey?: boolean }>`
  ${props =>
    props.theme.isDesktop
      ? css`
          &:not(:first-child) {
            margin-left: 32px;
          }

          ${() =>
            props.$isSurvey &&
            css`
              width: 290px;
            `}
        `
      : css`
          font-weight: normal;
          font-size: 14px;
          line-height: 130%;
          border-radius: 16px;
          height: 48px;
          width: calc(100% - 32px);
        `}

  ${mobileButtonMaxWidthMixin};
  ${printDisplayNoneMixin}
`

export const CompletedViewWrapper = styled(CompletedView)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-right: 16px;
        `
      : css`
          margin-left: 8px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        `}
`

const MarkCompletedText = styled.span<{ $disabled: boolean }>`
  user-select: none;
  font-weight: normal;
  color: ${props => props.theme.colors.main100};

  ${props =>
    props.theme.isDesktop
      ? css`
          cursor: pointer;
          font-size: 16px;
          line-height: 100%;

          &:hover {
            color: ${props.theme.colors.main110};
          }
        `
      : css`
          font-size: 14px;
          line-height: 150%;
        `}

  ${props =>
    props.$disabled &&
    css`
      cursor: unset;
      color: ${props.theme.colors.main40};

      &:hover {
        color: ${props.theme.colors.main40};
      }
    `}
`

const AttachmentsContainer = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  padding: 16px 0 0;
  color: ${props => props.theme.colors.dark60};
  border-top: ${props => (props.theme.isDesktop ? '1px' : '0px')} solid
    ${props => props.theme.colors.main20};
`

const CancelSurvey = styled.span`
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.main100};
  padding: 8px 8px 8px 0;
`

export const JourneyMapItemView = React.memo((props: IProps) => {
  const { className, item, onItemCompletedClick, onClose, navigate } = props
  const { headline, type, completed, cta, attachments, content } = item
  const managerJourneyMapContext: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )
  const userContext: IUserContext = useContext(UserContext)
  const { isDesktop, isMobile, isMobileLandscape } = useContext(ScreenContext)
  const [surveyState, setSurveyState] = useState(SURVEY_STATE.ready)
  const containerRef = useRef(null)
  const { t } = useTranslation()
  const isSurvey: boolean = useMemo(() => type === 'Survey', [type])
  const scrollViewRef: any = useRef(null)
  const scrollViewRect: IRect = useComponentRect(scrollViewRef)
  const id = [props.leaveId, item.id].filter(Boolean).join('/')

  useCreateMetric({ eventType: MetricEventJourneyMapImpression, id })

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === 27) {
        onClose()
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onClose])

  const sendCtaClickMetric = useCallback(async () => {
    await props.queries.createMetric(
      { eventType: MetricEventJourneyMapClick, id },
      { anyError: (): void => undefined }
    )
  }, [id, props.queries])

  const onCtaClick = useCallback(async () => {
    const params: IMeetingLinkParams = parseMeetingLink(cta.link)
    if (params) {
      await sendCtaClickMetric()

      const { start, duration, title, description, attendeeEmail, uid } = params
      const end: Moment = start.clone().add(duration, 'seconds')
      try {
        await createMeeting({
          uid,
          title,
          description,
          organizer: {
            name: userContext.fullName,
            email: userContext.email
          },
          attendees: [
            {
              name: managerJourneyMapContext?.currentAbsence?.employee?.name,
              email: attendeeEmail,
              rsvp: true,
              role: 'REQ-PARTICIPANT',
              partstat: 'NEEDS-ACTION'
            }
          ],
          start,
          end
        })
      } catch (_) {
        props.toastManager.addError(t('common.somethingWentWrong'))
      }

      return
    }

    sendCtaClickMetric()

    if (cta.link.indexOf(userProfileRoute) === 0) {
      navigate(cta.link)
    } else {
      window.open(cta.link, '_blank', 'noopener')
    }
  }, [
    cta,
    navigate,
    managerJourneyMapContext,
    sendCtaClickMetric,
    t,
    userContext.email,
    userContext.fullName,
    props.toastManager
  ])

  const titleView: ReactNode = useMemo(() => {
    if (isDesktop) {
      return (
        <div>
          <CloseButton
            onClick={onClose}
            aria-label={t('common.accessibilityText.closeButton')}
          >
            <CloseIcon
              name={'close_crossing'}
              settings={{ fill: colors.main100 }}
            />
          </CloseButton>
          <TitleAndCloseWrapper>
            <TitleWrapper>{headline}</TitleWrapper>
          </TitleAndCloseWrapper>
        </div>
      )
    } else {
      return <TitleWrapper>{headline}</TitleWrapper>
    }
  }, [t, headline, onClose, isDesktop])

  const itemInfoView: ReactNode = useMemo(
    () => <ItemInfoView item={item} />,
    [item]
  )

  const descriptionView: ReactNode = useMemo(
    () => (
      <Description
        components={{
          a: LinkRenderer
        }}
      >
        {content}
      </Description>
    ),
    [content]
  )

  const attachmentsView: ReactNode = useMemo(() => {
    const filtered: ILink[] =
      attachments && attachments.filter((a: ILink) => a.type !== 'video')

    if (!filtered.length) {
      return null
    }

    return (
      <AttachmentsContainer>
        Attachments:
        {filtered.map((attachment: ILink) => (
          <AttachmentView key={attachment.link} attachment={attachment} />
        ))}
      </AttachmentsContainer>
    )
  }, [attachments])

  const videoView: ReactNode = useMemo(() => {
    const videoLinks: ILink[] =
      attachments && attachments.filter((a: ILink) => a.type === 'video')

    if (!videoLinks.length) {
      return null
    }

    return (
      <>
        {videoLinks.map((l: ILink) => (
          <VideoView
            key={l.link}
            title={l.text}
            src={l.link}
            parentWidth={scrollViewRect.width}
          />
        ))}
      </>
    )
  }, [attachments, scrollViewRect])

  const scrollView: ReactNode = useMemo(() => {
    if (isDesktop) {
      return (
        <ScrollView ref={scrollViewRef}>
          {descriptionView}
          {videoView}
          {attachmentsView}
        </ScrollView>
      )
    } else {
      return (
        <ScrollView ref={scrollViewRef}>
          {titleView}
          {itemInfoView}
          {videoView}
          {descriptionView}
          {attachmentsView}
        </ScrollView>
      )
    }
  }, [
    titleView,
    itemInfoView,
    attachmentsView,
    descriptionView,
    videoView,
    isDesktop
  ])

  const backButtonView: ReactNode = useMemo(() => {
    if (
      isSurvey &&
      (surveyState === SURVEY_STATE.started ||
        surveyState === SURVEY_STATE.submitted)
    ) {
      return (
        <CancelSurvey onClick={onClose}>
          {surveyState === SURVEY_STATE.started
            ? t('surveys.cancelSurvey')
            : ''}
        </CancelSurvey>
      )
    } else {
      return <BackButton onClick={onClose} />
    }
  }, [isSurvey, surveyState, onClose, t])

  const markCompletedWrapper: ReactNode = useMemo(() => {
    const toggleDisabled: boolean =
      managerJourneyMapContext && managerJourneyMapContext.isArchiveMode
    const completedProps = {
      onClick: toggleDisabled ? (): void => undefined : onItemCompletedClick
    }
    const mark = (
      <CompletedViewWrapper completed={completed} disabled={toggleDisabled} />
    )
    const text = (
      <MarkCompletedText $disabled={toggleDisabled} aria-hidden>
        {t('common.markAsCompleted')}
      </MarkCompletedText>
    )
    if (isDesktop) {
      return (
        <MarkCompletedWrapper {...completedProps}>
          {mark}
          {text}
        </MarkCompletedWrapper>
      )
    } else {
      return (
        <MarkCompletedWrapper {...completedProps}>
          {text}
          {mark}
        </MarkCompletedWrapper>
      )
    }
  }, [completed, managerJourneyMapContext, onItemCompletedClick, t, isDesktop])

  const backAndMarkCompletedWrapper: ReactNode = useMemo(
    () => (
      <BackAndMarkCompletedWrapper>
        {backButtonView}
        {markCompletedWrapper}
      </BackAndMarkCompletedWrapper>
    ),
    [backButtonView, markCompletedWrapper]
  )

  const printButtonView: ReactNode = useMemo(() => {
    if (isMobile || isSurvey) {
      return null
    }

    return <PrintButton />
  }, [isSurvey, isMobile])

  const ctaButtonView: ReactNode = useMemo(() => {
    let ctaProps: any
    let ctaHandler: any

    if (isSurvey) {
      let key: string
      switch (surveyState) {
        case SURVEY_STATE.ready:
          key = 'surveys.startSurvey'
          ctaHandler = () => {
            setSurveyState(SURVEY_STATE.started)
          }
          break
        case SURVEY_STATE.submitted:
          key = 'common.close'
          ctaHandler = () => {
            onClose()
          }
          break
        default:
          break
      }
      ctaProps = { text: t(key) }
    } else {
      ctaProps = cta
      ctaHandler = onCtaClick
    }

    if (!ctaProps) {
      return null
    }

    if (isDesktop) {
      return (
        <CtaButtonWrapper onClick={ctaHandler} $isSurvey={isSurvey}>
          {ctaProps.text}
        </CtaButtonWrapper>
      )
    }

    return (
      <CtaButtonMobileContainer>
        <CtaButtonWrapper onClick={ctaHandler}>
          {ctaProps.text}
        </CtaButtonWrapper>
      </CtaButtonMobileContainer>
    )
  }, [cta, isSurvey, onClose, onCtaClick, surveyState, t, isDesktop])

  const bottomPanelView: ReactNode = useMemo(
    () => (
      <React.Fragment>
        <BottomPanel>
          {markCompletedWrapper}
          <PrintAndCtaContainer>
            {printButtonView}
            {ctaButtonView}
          </PrintAndCtaContainer>
        </BottomPanel>
        <PrintFooterWrapper />
      </React.Fragment>
    ),
    [markCompletedWrapper, ctaButtonView, printButtonView]
  )

  const onSurveySubmitted = useCallback(() => {
    if (!completed) {
      onItemCompletedClick()
    }
    setSurveyState(SURVEY_STATE.submitted)
  }, [completed, onItemCompletedClick, setSurveyState])

  const onSurveyError = useCallback(
    (close: boolean) => {
      if (close) {
        onClose()
      }
      props.toastManager.addError(t('common.somethingWentWrong'))
    },
    [props.toastManager, onClose, t]
  )

  const surveyView: ReactNode = useMemo(() => {
    const surveyContainer = <SurveyContainer />
    return React.cloneElement(surveyContainer, {
      surveyId: content,
      absenceId: managerJourneyMapContext?.currentAbsence?.id,
      onSubmit: onSurveySubmitted,
      state: surveyState,
      onStart: () => {
        setSurveyState(SURVEY_STATE.started)
      },
      onClose,
      onError: onSurveyError
    })
  }, [
    content,
    surveyState,
    setSurveyState,
    onClose,
    onSurveyError,
    onSurveySubmitted,
    managerJourneyMapContext?.currentAbsence?.id
  ])

  const contentContainerView = useMemo(() => {
    if (isSurvey) {
      if (isDesktop) {
        return (
          <ContentContainer $isSurvey>
            {titleView}
            {itemInfoView}
            {surveyView}
            {surveyState !== SURVEY_STATE.started && bottomPanelView}
          </ContentContainer>
        )
      } else {
        return (
          <ContentContainer>
            <ReactFocusLock autoFocus={false}>
              {backAndMarkCompletedWrapper}
              {surveyState === SURVEY_STATE.ready && titleView}
              {!isMobileLandscape && surveyState === SURVEY_STATE.ready && (
                <MobileItemInfoContainer>
                  {itemInfoView}
                </MobileItemInfoContainer>
              )}
              {surveyView}
            </ReactFocusLock>
          </ContentContainer>
        )
      }
    }

    if (isDesktop) {
      return (
        <ContentContainer>
          {titleView}
          {itemInfoView}
          {scrollView}
          {bottomPanelView}
        </ContentContainer>
      )
    } else {
      return (
        <ContentContainer>
          <ReactFocusLock autoFocus={false}>
            {backAndMarkCompletedWrapper}
            {scrollView}
            {ctaButtonView}
            <PrintFooterWrapper />
          </ReactFocusLock>
        </ContentContainer>
      )
    }
  }, [
    backAndMarkCompletedWrapper,
    bottomPanelView,
    ctaButtonView,
    isSurvey,
    itemInfoView,
    isMobileLandscape,
    scrollView,
    surveyState,
    surveyView,
    titleView,
    isDesktop
  ])

  const containerProps: any = {
    className,
    ref: containerRef,
    onClick: (event: any) => {
      if (isDesktop && event.target === containerRef.current) {
        onClose()
      }
    }
  }

  if (isDesktop) {
    return (
      <ModalsView {...containerProps} role={'dialog'}>
        <ReactFocusLock>{contentContainerView}</ReactFocusLock>
      </ModalsView>
    )
  }

  return <Container {...containerProps}>{contentContainerView}</Container>
})

JourneyMapItemView.displayName = 'JourneyMapItemView'

export default withToastManager(withQueries(JourneyMapItemView))

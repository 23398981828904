import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import JourneyMapContext, {
  IJourneyMapContext
} from 'src/features/JourneyMap/Context'
import LeaveHeading from 'src/components/LeaveHeading'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import { useTranslation } from 'react-i18next'
import UnarchiveButton from 'src/components/JourneyMap/UnarchiveButton'
import ScreenContext from 'src/contexts/ScreenContext'
import { Icon } from 'src/UIKit'
import SyncStatusView from 'src/components/SyncStatusView'
import SharedContext from 'src/contexts/SharedContext'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          background: ${props.theme.colors.light95};
          box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
          position: absolute;
          top: 0;
          left: 0;
          z-index: ${zIndex.journeyMap.header};
          height: 80px;
        `
      : css`
          background: ${props.theme.colors.light100};
          height: 51px;
        `}
`

const LeaveHeadingWrapper = styled(LeaveHeading)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px 0 20px;
        `
      : css`
          margin: 0 16px;
        `}
`

const ActionButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 32px;
`

const Button = styled.button`
  margin-left: 20px;
  border-radius: 24px;
  padding: 14px;
  outline-offset: 0 !important;

  &:hover {
    background: ${props => props.theme.colors.main20};
  }
`

const IconWrapper = styled(Icon)`
  width: 16px;
  height: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const SyncStatusViewWrapper = styled(SyncStatusView)`
  margin-right: 12px;
`

export const JourneyMapHeader = React.memo(() => {
  const journeyMapContext: IJourneyMapContext = useContext(JourneyMapContext)
  const managerContext: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const { customerConfig } = useContext(SharedContext)

  const title: string = useMemo(() => {
    if (journeyMapContext) {
      return (
        journeyMapContext.leave?.type &&
        t(`types.${journeyMapContext.leave.type}`)
      )
    }

    const employee = managerContext?.currentAbsence?.employee

    if (!employee?.name) {
      return null
    }

    return managerContext.isArchiveMode
      ? employee.name
      : t('managerJourneyMap.tasks', { name: employee.name })
  }, [journeyMapContext, managerContext, t])

  const actionButtonView: ReactNode = useMemo(() => {
    if (!managerContext) {
      return null
    }
    const { isArchiveMode, unarchivePlan } = managerContext

    return isArchiveMode ? (
      <ActionButtonContainer>
        <UnarchiveButton onClick={unarchivePlan} />
      </ActionButtonContainer>
    ) : null
  }, [managerContext])

  if ((!isDesktop && managerContext) || (isDesktop && !managerContext)) {
    return null
  }

  return (
    <Container>
      {managerContext && (
        <Button onClick={managerContext.clickBack}>
          <IconWrapper name="arrow_prev" />
        </Button>
      )}
      <TitleContainer>
        <LeaveHeadingWrapper>{title}</LeaveHeadingWrapper>
        {!managerContext &&
          journeyMapContext?.leave?.tpa?.syncFailedAt &&
          customerConfig.leave.tpaSync.showSyncStatus(
            journeyMapContext.leave
          ) && (
            <SyncStatusViewWrapper
              tpa={journeyMapContext.leave.tpa}
              leaveType={journeyMapContext.leave.type}
            />
          )}
      </TitleContainer>
      {actionButtonView}
    </Container>
  )
})

JourneyMapHeader.displayName = 'JourneyMapHeader'

export default JourneyMapHeader

import React, {
  ReactNode,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect
} from 'react'
import LoadingSpinner from 'src/components/LoadingSpinner'
import withQueries from 'src/components/HOC/withQueries'
import {
  errorPageRoute,
  managerDashboardRoute,
  notFoundPageRoute
} from 'src/routes/constants'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { useNavigate, useParams } from 'react-router'
import ManagerPreviousVersionsContext, {
  IManagerPreviousVersionsContext
} from '../PreviousVersionsContext'
import PreviousVersionsTopViewContainer from 'src/components/Dashboard/PreviousVersionsTopViewContainer'
import AccordionUnified from 'src/components/AccordionUnified'
import { IIconName, IWithQueriesProps } from 'src/react-app-env'
import styled, { css } from 'styled-components'
import AbsenceTimeline from 'src/features/ManagerJourneyMap/components/AbsenceTimeline'
import ScreenContext from 'src/contexts/ScreenContext'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { groupBy } from 'lodash'

interface IProps extends IWithQueriesProps {}

const dateFormat = 'MMM DD, YYYY hh:mm a'
const monthFormat = 'MMMM YYYY'

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 312px;
  min-width: 232px;
  width: 100%;
  overflow-y: auto;
  ${props =>
    props.theme.isDesktop &&
    css`
      -ms-overflow-style: -ms-autohiding-scrollbar;
      scrollbar-width: none;
    `}
`

const DatesAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 104px;
  max-height: calc(100vh - 150px);
  width: 232px;
  ${props => {
    if (props.theme.isMobile) {
      return css`
        margin: 0;
        box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
        z-index: 3;
      `
    }
  }}
`

const RightContainer = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-y: auto;
  overflow-x: unset;
  ${props => {
    if (props.theme.isDesktop) {
      return css`
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: none;
        display: flex;
        flex-direction: row;
        min-width: 724px;
        width: auto;
      `
    } else {
      return css`
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: ${props.$visible ? -1 : 3};
        ${props.$visible &&
        css`
          overflow: hidden;
        `}
      `
    }
  }}
`

const TimelineContainer = styled.div`
  min-width: ${props => (props.theme.isDesktop ? '348px' : 'auto')};
  max-width: ${props => (props.theme.isDesktop ? '348px' : 'auto')};

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
    `};
`

const TimelineTitle = styled.span<{ $current: boolean }>`
  display: block;
  font-weight: normal;
  line-height: 100%;
  text-align: center;
  color: ${props =>
    props.$current ? props.theme.colors.main90 : props.theme.colors.dark60};
  background: ${props => props.theme.colors.backgroundColor};
  border-radius: 16px;
  border: 1px dotted
    ${props =>
      props.$current ? props.theme.colors.main90 : props.theme.colors.dark60};
  padding: 4px 8px;
  width: fit-content;
  font-size: 14px;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 24px auto 12px;

          &:first-child {
            margin-top: 104px;
          }
        `
      : css`
          margin: 16px auto 12px;
        `}
`

const TimelineBackground = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          border: 1px solid ${props.theme.colors.dark05};
          padding: 16px;
          border-radius: 8px;
        `
      : css`
          padding: 0 16px;
          margin: 8px 0;
        `}
`

const Separator = styled.div`
  min-height: 1px;
  background: #ebeff2;
  width: 100%;
  margin-top: 16px;
`

const MobileDatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: calc(100% - 32px);
  padding: 24px 16px 16px;
  background-color: ${props => props.theme.colors.ligth100};
`

const MobileDatesTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const MobileDatesIconWrapper = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 19px;
  padding: 2px;
`

const MobileDatesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const NoPastTimelineIconWrapper = styled(Icon)`
  margin: 48px;
  display: flex;
  justify-content: center;
`

export const dataAttrs = {
  pastTimeline: () => 'past-timeline'
}

export const DashboardPreviousVersionsContainer = React.memo(
  (props: IProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isDesktop } = useContext(ScreenContext)
    const [showDates, setShowDates] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [absenceTimelineHistory, setAbsenceTimelineHistory] = useState(null)
    const [currentAbsence, setCurrentAbsence] = useState(null)
    const [historyDates, setHistoryDates] = useState(null)
    const [loading, setLoading] = useState(null)

    const onClickCloseDates = useCallback(
      () => setShowDates(false),
      [setShowDates]
    )

    usePageTitle('managerDashboard')
    useRedirectByUserRole({ navigate, allowedRole: 'manager' })

    const { absenceId } = useParams()

    const fetchAbsence = useCallback(async () => {
      try {
        setLoading(true)
        // TODO: use query to fetch single absence?
        const result = await props.queries.fetchAbsencesDashboard(false, {
          fetchPolicy: 'network-only'
        })
        const absence = result.find(a => a.id === absenceId)
        if (!absence) {
          navigate(notFoundPageRoute)
        }
        setCurrentAbsence(absence)

        const dates = absence.timelineHistoryDates
        const groupedDates = groupBy(dates, ({ createdAt }) =>
          moment(createdAt).format(monthFormat)
        )
        setHistoryDates(groupedDates)
        // open first date by default
        if (dates.length) {
          setSelectedMonth(moment(dates[0].createdAt).format(monthFormat))
          setSelectedDate(dates[0])
        }
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          navigate(errorPageRoute, {
            state: { error: error.message }
          })
        }
      } finally {
        setLoading(false)
      }
    }, [props, navigate, setCurrentAbsence, absenceId])

    useEffect(() => {
      fetchAbsence()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchAbsenceTimelineHistory = useCallback(async () => {
      if (!currentAbsence || selectedDate === null) {
        return
      }
      try {
        setLoading(true)
        const result = await props.queries.fetchAbsenceTimelineHistory(
          currentAbsence.id,
          selectedDate.id
        )
        setAbsenceTimelineHistory(result)
      } catch (error) {
        console.error(error)
        if (error instanceof Error) {
          navigate(errorPageRoute, {
            state: { error: error.message }
          })
        }
      } finally {
        setLoading(false)
      }
    }, [props, currentAbsence, selectedDate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      fetchAbsenceTimelineHistory()
    }, [fetchAbsenceTimelineHistory])

    const pastTimelineTitle: string = useMemo(
      () =>
        absenceTimelineHistory?.timeline.length
          ? moment(absenceTimelineHistory.versionAt).format(dateFormat)
          : t('manager.previousVersions.noPreviousVersions'),
      [absenceTimelineHistory, t]
    )

    const datesData = useMemo(() => {
      if (!historyDates) {
        return []
      }
      return Object.keys(historyDates).map(month => ({
        data: {
          rootItemTitle: month,
          rootItemIconName: 'calendar' as IIconName,
          subItems: historyDates[month].map(date => ({
            title: moment(date.createdAt).format(dateFormat),
            iconName: 'dot_small' as IIconName,
            onClick: () => {
              setSelectedDate(date)
              setShowDates(false)
            },
            selected: selectedDate.id === date.id
          }))
        },
        onRootClick: () => {
          setSelectedMonth(month)
        },
        expanded: selectedMonth === month
      }))
    }, [historyDates, selectedDate, selectedMonth])

    const datesAccordion: ReactNode[] = useMemo(
      () =>
        datesData.map((dates, i) => (
          <div key={i}>
            <AccordionUnified {...dates} />
            {i !== datesData.length - 1 && <Separator />}
          </div>
        )),
      [datesData]
    )

    const desktopDates: ReactNode = useMemo(
      () =>
        !!datesAccordion.length && (
          <LeftContainer>
            <DatesAccordionContainer>{datesAccordion}</DatesAccordionContainer>
          </LeftContainer>
        ),
      [datesAccordion]
    )

    const mobileDates: ReactNode = useMemo(
      () =>
        showDates ? (
          <MobileDatesContainer>
            <MobileDatesTitle>
              {t('manager.previousVersions.datesTitle')}
            </MobileDatesTitle>
            <MobileDatesIconWrapper
              name="close_crossing"
              onClick={onClickCloseDates}
            />
            <MobileDatesContent>{datesAccordion}</MobileDatesContent>
          </MobileDatesContainer>
        ) : null,
      [datesAccordion, showDates, onClickCloseDates, t]
    )

    if (loading && absenceTimelineHistory == null) {
      return <LoadingSpinner fadesIn fullScreen />
    }

    if (
      absenceTimelineHistory === null &&
      (historyDates == null || datesData.length > 0)
    ) {
      return null
    }

    const contextValue: IManagerPreviousVersionsContext = {
      clickBack: () =>
        navigate(managerDashboardRoute, { state: { absenceId } }),
      showDates,
      setShowDates,
      currentAbsence
    }

    return (
      <ManagerPreviousVersionsContext.Provider value={contextValue}>
        <PreviousVersionsTopViewContainer>
          {isDesktop ? desktopDates : mobileDates}
          <RightContainer $visible={!!showDates}>
            <TimelineContainer data-testid={dataAttrs.pastTimeline()}>
              <TimelineTitle $current={false}>
                {pastTimelineTitle}
              </TimelineTitle>
              <TimelineBackground>
                {absenceTimelineHistory?.timeline.length ? (
                  <AbsenceTimeline
                    absence={
                      {
                        timeline: absenceTimelineHistory.timeline,
                        startDate: absenceTimelineHistory.startDate,
                        endDate: absenceTimelineHistory.endDate
                      } as IAbsence
                    }
                    showCurrent={false}
                  />
                ) : (
                  <NoPastTimelineIconWrapper
                    name={'pagick_unfortunately'}
                    ariaLabel={t('common.accessibilityText.notFound')}
                  />
                )}
              </TimelineBackground>
            </TimelineContainer>
            {isDesktop && (
              <TimelineContainer>
                <TimelineTitle $current>
                  {t('manager.previousVersions.currentTimelineTitle')}
                </TimelineTitle>
                <TimelineBackground>
                  <AbsenceTimeline
                    absence={
                      {
                        timeline: currentAbsence.timeline,
                        startDate: currentAbsence.startDate,
                        endDate: currentAbsence.endDate
                      } as IAbsence
                    }
                    showCurrent
                  />
                </TimelineBackground>
              </TimelineContainer>
            )}
          </RightContainer>
        </PreviousVersionsTopViewContainer>
      </ManagerPreviousVersionsContext.Provider>
    )
  }
)

DashboardPreviousVersionsContainer.displayName =
  'DashboardPreviousVersionsContainer'

export default withQueries(DashboardPreviousVersionsContainer)

import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import {
  IDialogData,
  IMomentCurrentMinMax,
  ITimelinePeriod,
  IWithDialogManager
} from 'src/react-app-env'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import DatePickerAlert from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Alert'
import ScreenContext from 'src/contexts/ScreenContext'
import {
  getPeriodDuration,
  calculateDuration,
  getChangesForPeriod,
  getExtraParameters
} from '../methods'
import { isBlankDate, areSameDates } from 'src/utils/dateUtils'
import { useResizeDetector } from 'react-resize-detector'
import InfoMessageView from '../components/InfoMessageView'
import {
  isCertificationRequired,
  findTimelinePeriod
} from 'src/utils/leaveUtils'
import DescriptionText from '../components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import { getPeriodKeyDate } from 'src/utils/periodUtils'
import SelectedMessage from '../components/SelectedMessage'
import ApprovedByTpaView from '../components/ApprovedByTpaView'
import { withDialogManager } from 'src/components/DialogManager'
import RemoveDialogContent from '../components/RemoveDialogContent'
import {
  AlertsContainer,
  DateSelectors,
  StyledDatePicker
} from '../components/Datepickers'
import Buttons from '../components/Buttons'
import { ContentContainer } from '../components/ContentContainer'
import ContainerView from '../components/ContainerView'

interface IProps extends IWithDialogManager {
  className?: string
  period: ITimelinePeriod
  y?: number
  alignsToTop?: boolean
  bottomOffset?: number
  onExit: () => void
}

const TitleWrapper = styled(Title)`
  margin: 0 16px 20px;
`

const DescriptionTextWrapper = styled(DescriptionText)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 8px 24px 0;
        `}
`

const SelectedNoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: self-start;
  margin: 0 16px;
`

const InfoMessageWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 10px 16px;
`

const ApprovedByTpaWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 0 16px;
`

/**
 * Sends period changes with ID field.
 */
const LeaveDatePeriodPicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave,
    onNewChanges,
    timelinePeriods,
    leaveHolidays,
    onCalendarActiveDateChanged,
    onCancelChanges
  } = context
  const { alignsToTop, onExit, period, dialogManager } = props
  const { appearance } = period
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const [initialPeriod] = useState(period)
  const { refID } = initialPeriod
  const [isDurationApproved, setIsDurationApproved] = useState<boolean>(false)
  const [wasAddable] = useState(appearance === 'Addable')

  const containerRef: any = useRef(null)
  const forceUpdate = useForceUpdate()

  const [activeStartDate, setActiveStartDate] = useState(undefined)
  const [activeEndDate, setActiveEndDate] = useState(undefined)

  const removeDialogView: IDialogData = useMemo(
    () => ({
      title: t('timeline.removeDialog.title'),
      buttons: [
        {
          title: t('common.cancel'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('timeline.removeDialog.yes'),
          onClick: () => {
            onExit()
            const extra = getExtraParameters({
              period,
              isDurationApproved
            })
            const changes: any = getChangesForPeriod({
              period,
              intermittentBlockChanges: { isNew: wasAddable },
              duration: 0,
              extra
            })
            onNewChanges(changes, true)
          },
          order: 2
        }
      ],
      children: (
        <RemoveDialogContent
          periodType={period.type}
          isDurationApproved={isDurationApproved}
          setIsDurationApproved={setIsDurationApproved}
        />
      )
    }),
    [isDurationApproved, onExit, onNewChanges, period, t, wasAddable]
  )

  // When parameters that have context dependencies are used in a dependency, there is a constant change in those parameters.
  // For now it is decided to track the change of state in dependency.
  useEffect(() => {
    if (dialogManager?.opened) {
      dialogManager.update(removeDialogView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDurationApproved])

  const alerts: string[] = Array.from(period.timelineConfig.alerts)

  if (
    period.type === 'Recovery' &&
    isCertificationRequired(leave, period.type)
  ) {
    alerts.push(t('postDeliveryDisabilityCertificateRequired'))
  }

  if (
    (period.type === 'PreDelivery' || period.type === 'Sickness') &&
    isCertificationRequired(leave, period.type) &&
    !alerts.includes('preDeliverySickTimeCertificateRequired')
  ) {
    alerts.push(t('preDeliverySickTimeCertificateRequired'))
  }

  const canBeRemoved = useMemo(() => period.isRemovable, [period])
  const onRemove = useCallback(() => {
    onExit()
    const changes: any = getChangesForPeriod({
      period,
      intermittentBlockChanges: { isNew: wasAddable },
      duration: 0
    })
    onNewChanges(changes, true)
  }, [onNewChanges, onExit, period, wasAddable])

  const onRemoveCallback = useCallback(() => {
    if (period.timelineConfig.useApprovedByTpa) {
      dialogManager.add(removeDialogView)
    } else {
      onRemove()
    }
  }, [dialogManager, onRemove, period, removeDialogView])

  const updatedPeriod = findTimelinePeriod(timelinePeriods, period) || period

  const startDateMinMax = useMemo(
    () =>
      isBlankDate(updatedPeriod.periodStart.current)
        ? {
            ...updatedPeriod.periodStart,
            current: null
          }
        : updatedPeriod.periodStart,
    [updatedPeriod]
  )

  const endDateMinMax = useMemo(
    () =>
      isBlankDate(updatedPeriod.periodEnd.current)
        ? {
            ...updatedPeriod.periodEnd,
            current: null
          }
        : updatedPeriod.periodEnd,
    [updatedPeriod]
  )

  const onCancel = useCallback(() => {
    onExit()
    onCancelChanges()
  }, [onExit, onCancelChanges])

  const onChange = useCallback(
    (date: Moment) => {
      const { periodKeyDate } = updatedPeriod.timelineConfig
      const changes: any = getChangesForPeriod({
        period: { ...period, refID },
        intermittentBlockChanges: { isNew: wasAddable },
        duration: calculateDuration(updatedPeriod, periodKeyDate, date)
      })
      onNewChanges(changes)
    },
    [onNewChanges, updatedPeriod, period, wasAddable, refID]
  )

  useEffect(() => {
    if (
      period.appearance === 'Addable' &&
      period.timelineConfig.periodPickerCreateAddable
    ) {
      const { periodKeyDate, periodPickerGetDateMinMax } = period.timelineConfig
      const date =
        periodKeyDate === 'periodEnd'
          ? periodPickerGetDateMinMax(period.periodEnd)
          : periodPickerGetDateMinMax(period.periodStart)
      onChange(date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getDateMinMax = (dateMinMax: IMomentCurrentMinMax) => {
      const keyDate =
        period.timelineConfig.defaultActiveKeyDate ||
        period.timelineConfig.periodKeyDate
      return keyDate === 'periodStart'
        ? dateMinMax.min.utc().toDate()
        : dateMinMax.max.utc().toDate()
    }

    setActiveStartDate(
      startDateMinMax.current
        ? startDateMinMax.current.utc().toDate()
        : getDateMinMax(startDateMinMax)
    )

    setActiveEndDate(
      endDateMinMax.current
        ? endDateMinMax.current.utc().toDate()
        : getDateMinMax(endDateMinMax)
    )
  }, [startDateMinMax, endDateMinMax, period])

  const onConfirm = useCallback(() => {
    const extra = getExtraParameters({
      period,
      isDurationApproved
    })
    const changes: any = getChangesForPeriod({
      period: { ...period, refID },
      intermittentBlockChanges: { isNew: wasAddable },
      duration: getPeriodDuration(updatedPeriod),
      extra
    })
    onNewChanges(changes, true)
  }, [
    onNewChanges,
    period,
    updatedPeriod,
    wasAddable,
    isDurationApproved,
    refID
  ])

  const hasChanges: boolean = useMemo(() => {
    const samePeriodStart = areSameDates(
      initialPeriod.periodStart.current,
      updatedPeriod.periodStart.current
    )
    const samePeriodEnd = areSameDates(
      initialPeriod.periodEnd.current,
      updatedPeriod.periodEnd.current
    )
    return (
      !samePeriodStart ||
      !samePeriodEnd ||
      (initialPeriod === updatedPeriod && updatedPeriod.isDirty)
    )
  }, [updatedPeriod, initialPeriod])

  const buttonsView: ReactNode = useMemo(
    () => (
      <Buttons
        hasChanges={hasChanges}
        isConfirmDisabled={false}
        onCancel={onCancel}
        onClose={onExit}
        onConfirm={() => {
          onExit()
          onConfirm()
        }}
        onRemove={
          canBeRemoved && period.appearance !== 'Addable' && !hasChanges
            ? onRemoveCallback
            : null
        }
      />
    ),
    [
      hasChanges,
      onCancel,
      onExit,
      canBeRemoved,
      period.appearance,
      onRemoveCallback,
      onConfirm
    ]
  )

  const onResize = useCallback(() => {
    if (isDesktop && !alignsToTop) {
      forceUpdate()
    }
  }, [forceUpdate, isDesktop, alignsToTop])

  useResizeDetector({
    targetRef: containerRef,
    handleHeight: true,
    onResize
  })

  const alertsView: ReactNode = useMemo(() => {
    if (alerts.length === 0) {
      return null
    }

    return (
      <AlertsContainer>
        {alerts.map((key: string) => (
          <DatePickerAlert
            key={key}
            alertTranslationKey={key}
            metadata={leave.metadata}
          />
        ))}
      </AlertsContainer>
    )
  }, [alerts, leave.metadata])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = period.timelineConfig.datePickerFooterInfoMessage(t, leave)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [leave, period, t])

  const selectedPeriodView: ReactNode = useMemo(
    () =>
      updatedPeriod?.timelineConfig?.periodPickerSelectedNotice.show &&
      updatedPeriod?.schedule && (
        <SelectedMessage
          used={updatedPeriod.schedule.usedInPeriod}
          unused={updatedPeriod.schedule.totalUnused}
          usedCalendarDays={updatedPeriod.schedule?.usedInPeriodCalendarDays}
          unusedCalendarDays={updatedPeriod.schedule?.totalUnusedCalendarDays}
          externalDurationType={updatedPeriod.schedule.durationType}
          showDoubleText={
            updatedPeriod.timelineConfig?.periodPickerSelectedNotice
              .showDoubleText
          }
          notShowUnused={
            updatedPeriod.timelineConfig?.periodPickerSelectedNotice
              .notShowUnused
          }
        />
      ),
    [updatedPeriod]
  )

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.date', {
        periodName: period.timelineConfig.periodName,
        keyDate: getPeriodKeyDate(period)
      }),
    [t, period]
  )

  const approvedByTpaView: ReactNode = useMemo(
    () =>
      period.timelineConfig.useApprovedByTpa && (
        <ApprovedByTpaWrapper>
          <ApprovedByTpaView
            approved={isDurationApproved}
            hasChanges={hasChanges}
            onClick={() => {
              setIsDurationApproved(!isDurationApproved)
            }}
          />
        </ApprovedByTpaWrapper>
      ),
    [isDurationApproved, setIsDurationApproved, hasChanges, period]
  )

  const startDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        holidays={leaveHolidays}
        disabled={getPeriodKeyDate(period) === 'end'}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        title={t('timeline.periodPicker.startDate')}
        momentCurrentMinMax={startDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onChange}
        activeStartDate={activeStartDate}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveStartDate(action.activeStartDate)
        }}
      />
    ),
    [
      leaveHolidays,
      period,
      t,
      startDateMinMax,
      onChange,
      onCalendarActiveDateChanged,
      activeStartDate
    ]
  )

  const endDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        stickRight
        holidays={leaveHolidays}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        title={t('timeline.periodPicker.endDate')}
        disabled={getPeriodKeyDate(period) === 'start'}
        momentCurrentMinMax={endDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onChange}
        activeStartDate={activeEndDate}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveEndDate(action.activeStartDate)
        }}
      />
    ),
    [
      leaveHolidays,
      period,
      t,
      endDateMinMax,
      onChange,
      onCalendarActiveDateChanged,
      activeEndDate
    ]
  )

  return (
    <ContainerView {...props}>
      <ContentContainer>
        {alertsView}
        <TitleWrapper>{title}</TitleWrapper>
        {period.timelineConfig?.datePickerDescription && (
          <DescriptionTextWrapper>
            {period.timelineConfig.datePickerDescription}
          </DescriptionTextWrapper>
        )}
        <DateSelectors>
          {startDatePicker}
          {endDatePicker}
        </DateSelectors>
        <SelectedNoticeWrapper>{selectedPeriodView}</SelectedNoticeWrapper>
        {infoMessageView}
        {approvedByTpaView}
        {buttonsView}
      </ContentContainer>
    </ContainerView>
  )
})

LeaveDatePeriodPicker.displayName = 'LeaveDatePeriodPicker'

export default withDialogManager(LeaveDatePeriodPicker)

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import withQueries from 'src/components/HOC/withQueries'
import { IWithQueriesProps } from 'src/react-app-env'
import HrAdminPage from 'src/features/HrAdmin/components/Page'
import HrAdminContext, {
  IHrAdminContext
} from 'src/features/HrAdmin/HrAdminContext'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useCreateMetric from 'src/graphql/hooks/useCreateMetric'
import { MetricEventHrAdminDashboardImpression } from 'src/constants/metrics'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { errorPageRoute } from 'src/routes/constants'
import { IAccordionSubItem } from 'src/components/Accordion'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'
import { HR_ADMIN_SUB_ITEM_SELECTED } from 'src/constants/events'
import { useNavigate } from 'react-router'
import { GLOBAL_COUNTRY } from 'src/utils/userUtils'
import { useTranslation } from 'react-i18next'

interface IState {
  insightsSubItemTitles: string[]
  surveysSubItemTitles: string[]
  insightsLeaveGroups: string[]
  surveysLeaveGroups: string[]
  selectedInsightSubItemIndex: number
  selectedSurveySubItemIndex: number
  selectedAnalyticsType: string
  lastClickedRootItem: any
}

type ActionType =
  | 'set_data'
  | 'insight_root_clicked'
  | 'survey_root_clicked'
  | 'insight_sub_item_selected'
  | 'survey_sub_item_selected'

interface IPayload {
  leaveGroups?: string[]
  insightsSubItemTitles?: string[]
  surveysSubItemTitles?: string[]
  selectedInsightSubItemIndex?: number
  selectedSurveySubItemIndex?: number
}

interface IAction {
  type: ActionType
  payload?: IPayload
}

const initialState: IState = {
  insightsSubItemTitles: [],
  surveysSubItemTitles: [],
  insightsLeaveGroups: [],
  surveysLeaveGroups: [],
  selectedInsightSubItemIndex: 0,
  selectedSurveySubItemIndex: -1,
  selectedAnalyticsType: null,
  lastClickedRootItem: 'insights'
}

const reducer = (state: IState, action: IAction) => {
  const { type, payload } = action
  switch (type) {
    case 'set_data':
      const { insightsSubItemTitles, surveysSubItemTitles, leaveGroups } =
        payload
      let selectedInsightSubItemIndex = Math.min(
        state.selectedInsightSubItemIndex,
        0
      )
      let selectedSurveySubItemIndex = Math.min(
        state.selectedSurveySubItemIndex,
        0
      )
      let lastClickedRootItem = state.lastClickedRootItem
      if (leaveGroups.length === 0) {
        selectedInsightSubItemIndex = 0
        selectedSurveySubItemIndex = -1
        lastClickedRootItem = 'insights'
      }

      return {
        ...state,
        insightsLeaveGroups: [null, ...leaveGroups],
        surveysLeaveGroups: leaveGroups,
        insightsSubItemTitles,
        surveysSubItemTitles,
        lastClickedRootItem,
        selectedAnalyticsType:
          state.insightsLeaveGroups[selectedInsightSubItemIndex],
        selectedInsightSubItemIndex,
        selectedSurveySubItemIndex
      }
    case 'insight_root_clicked':
      return {
        ...state,
        lastClickedRootItem: 'insights'
      }
    case 'survey_root_clicked':
      return {
        ...state,
        lastClickedRootItem: 'surveys'
      }
    case 'insight_sub_item_selected':
      return {
        ...state,
        selectedInsightSubItemIndex: payload.selectedInsightSubItemIndex,
        selectedSurveySubItemIndex: -1,
        selectedAnalyticsType:
          state.insightsLeaveGroups[payload.selectedInsightSubItemIndex]
      }
    case 'survey_sub_item_selected':
      return {
        ...state,
        selectedSurveySubItemIndex: payload.selectedSurveySubItemIndex,
        selectedInsightSubItemIndex: -1
      }
    default:
      throw new Error()
  }
}

export const HrAdminContainer = (props: IWithQueriesProps) => {
  const { t } = useTranslation()
  const { queries } = props
  const [countryConfigs, setCountryConfigs] = useState<ICountryConfig[]>([])
  const [countryCodes, setCountryCodes] = useState<string[]>([])
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>()
  const [state, dispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()

  useRedirectByUserRole({ navigate, allowedRole: 'hradmin' })
  useCreateMetric({ eventType: MetricEventHrAdminDashboardImpression })

  usePageTitle('hrAdminDashboard')

  const sharedContext: ISharedContext = useContext(SharedContext)
  const customer = sharedContext.customer

  useEffect(() => {
    if (customer) {
      fetchCountryConfig()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    const config: IConfig = countryConfigs.find(
      c => c.countryCode === selectedCountryCode
    )

    const groupNames = config?.leaveTypes.map(lt => lt.group)
    const groups: any[] = [...new Set(groupNames)]
    const titles: string[] = groups.map((g: string) =>
      t(`hrAdmin.leftPanelItems.${selectedCountryCode}.${g}`)
    )
    dispatch({
      type: 'set_data',
      payload: {
        leaveGroups: groups,
        insightsSubItemTitles: [t('hrAdmin.leftPanelItems.summary'), ...titles],
        surveysSubItemTitles: [...titles]
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode])

  const fetchCountryConfig = async () => {
    try {
      const configs = await queries.fetchCountryConfigs()
      const codes = configs.map(config => config.countryCode)
      setCountryConfigs(configs)
      setCountryCodes(codes)
      setSelectedCountryCode(codes.length > 1 ? GLOBAL_COUNTRY : codes[0])
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const onInsightSubItemClick = useCallback(
    (index: number) => {
      dispatch({
        type: 'insight_sub_item_selected',
        payload: { selectedInsightSubItemIndex: index }
      })
      window.dispatchEvent(new Event(HR_ADMIN_SUB_ITEM_SELECTED))
    },
    [dispatch]
  )

  const insightsSubItems: IAccordionSubItem[] = useMemo(
    () =>
      state.insightsSubItemTitles.map((title: string, index: number) => ({
        title,
        iconName: 'insights_sub_item',
        onClick: () => {
          onInsightSubItemClick(index)
        },
        selected: state.selectedInsightSubItemIndex === index
      })),
    [state, onInsightSubItemClick]
  )

  const onSurveySubItemClick = useCallback(
    (index: number) => {
      dispatch({
        type: 'survey_sub_item_selected',
        payload: { selectedSurveySubItemIndex: index }
      })
      window.dispatchEvent(new Event(HR_ADMIN_SUB_ITEM_SELECTED))
    },
    [dispatch]
  )

  const surveysSubItems: IAccordionSubItem[] = useMemo(
    () =>
      state.surveysSubItemTitles.map((title: string, index: number) => ({
        title,
        iconName: 'surveys_sub_item',
        onClick: () => {
          onSurveySubItemClick(index)
        },
        selected: state.selectedSurveySubItemIndex === index
      })),
    [state, onSurveySubItemClick]
  )

  const onInsightRootClick = useCallback(() => {
    dispatch({ type: 'insight_root_clicked' })
  }, [dispatch])

  const onSurveyRootClick = useCallback(() => {
    dispatch({
      type: 'survey_root_clicked'
    })
  }, [dispatch])

  const contextValue: IHrAdminContext = useMemo(
    () => ({
      lastClickedRootItem: state.lastClickedRootItem,
      selectedInsightSubItemIndex: state.selectedInsightSubItemIndex,
      selectedSurveySubItemIndex: state.selectedSurveySubItemIndex,
      insightsLeaveGroups: state.insightsLeaveGroups,
      surveysLeaveGroups: state.surveysLeaveGroups,
      insightSubItemTitles: state.insightsSubItemTitles,
      surveysSubItemTitles: state.surveysSubItemTitles,
      selectedAnalyticsType: state.selectedAnalyticsType,
      insights: {
        data: {
          rootItemTitle: t('hrAdmin.leftPanelItems.insights'),
          rootItemIconName: 'insights',
          subItems: insightsSubItems
        },
        onRootClick: onInsightRootClick
      },
      surveys: {
        data: {
          rootItemTitle: t('hrAdmin.leftPanelItems.surveys'),
          rootItemIconName: 'surveys',
          subItems: surveysSubItems
        },
        onRootClick: onSurveyRootClick
      },
      countryCodes,
      selectedCountryCode,
      setSelectedCountryCode
    }),
    [
      state,
      t,
      insightsSubItems,
      surveysSubItems,
      onInsightRootClick,
      onSurveyRootClick,
      countryCodes,
      selectedCountryCode,
      setSelectedCountryCode
    ]
  )

  useEffect(() => {
    sharedContext.setHrAdminContext(contextValue)
  }, [sharedContext, contextValue])

  useEffect(
    () => () => {
      sharedContext.setHrAdminContext(null)
    },
    [sharedContext]
  )

  return (
    <HrAdminContext.Provider value={contextValue}>
      <HrAdminPage />
    </HrAdminContext.Provider>
  )
}

HrAdminContainer.displayName = 'HrAdminContainer'

export default withQueries(HrAdminContainer)

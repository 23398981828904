import React from 'react'
import { H1 } from 'src/UIKit'

interface IProps {
  text: string
}

export const dataAttrs = {
  title: () => 'consent-title'
}

export const TitleView = React.memo((props: IProps) => {
  const { text } = props

  return (
    <H1 id={'title'} data-testid={dataAttrs.title()}>
      {text}
    </H1>
  )
})

TitleView.displayName = 'TitleView'

export default TitleView

import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import DatePicker from 'src/components/DatePicker'
import moment, { Moment } from 'moment'
import zIndex from 'src/constants/zIndex'
import { H1, Button } from 'src/UIKit'
import { IMomentCurrentMinMax, IWithToastManager } from 'src/react-app-env'
import DatePickerSingleLineView from 'src/components/DatePicker/components/views/SingleLineView'
import SubtypeSelector from 'src/features/Timeline/components/common/SubtypeSelector'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { withToastManager } from 'src/components/ToastManager'
import usePrevious from 'src/components/hooks/usePrevious'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import { shouldShowSubtypeSelector } from 'src/utils/leaveUtils'
import useBirthDate, { IBirthDate } from 'src/graphql/hooks/useBirthDate'
import { pageButtons } from 'src/constants/frame'

interface IProps extends IWithToastManager {
  defaultDueDate: Moment
  subtypes: ILeaveSubType[]
  initialSubtype: ILeaveSubType
  onCancel: () => void
  onSubmit: (
    birthDate: Moment,
    subtype: ILeaveSubType,
    disabilityEndDate: Moment
  ) => void
}

const Container = styled.div`
  position: relative;
  z-index: ${zIndex.timeline.birthDateOverlay};
  top: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.backgroundColor};
  display: flex;
  justify-content: center;
  height: 100%;

  ${props =>
    props.theme.isDesktop &&
    css`
      bottom: 40px;
    `}
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: calc(100% - 32px);
          padding: 0 16px 16px;
        `}

  ${mobileMaxWidthMixin};
`

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  text-align: left;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
          margin-top: 16px;
        `}
`

const mobilePaddingMixin = css`
  ${props =>
    props.theme.isMobile &&
    css`
      width: calc(100% - 32px);
      padding: 16px;
    `}
`

const DatePickerWrapper = styled(DatePicker)`
  width: 100%;
  ${mobilePaddingMixin};
`

const SubtypeSelectorWrapper = styled(SubtypeSelector)`
  ${mobilePaddingMixin};
`

const elementsContainerMixin = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
          background: ${props.theme.colors.light100};
          border: 1px solid ${props.theme.colors.dark05};
          box-sizing: border-box;
          border-radius: 8px;
          width: calc(640px - 48px);
          padding: 24px;
        `
      : css`
          border: 1px solid ${props.theme.colors.dark05};
          border-radius: 8px;
          width: 100%;
          margin-top: 16px;
        `}
`

const ControlsContainer = styled.div`
  ${elementsContainerMixin}
`

const transitionMixin = css`
  transition: all 300ms ease-in-out;
`

const DisabilityPickerContainer = styled.div<{ $shown: boolean }>`
  ${elementsContainerMixin}
  ${transitionMixin}
  ${props =>
    props.$shown
      ? css`
          opacity: 1;
          pointer-events: all;
          ${props.theme.isDesktop &&
          css`
            padding-top: 24px;
            height: 64px;
            justify-content: center;
          `}
        `
      : css`
          opacity: 0;
          height: 0;
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
          pointer-events: none;
        `}
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => props.theme.colors.dark05};
  ${props =>
    props.theme.isDesktop &&
    css`
      margin: 24px 0;
    `}
`

export const DisabilityButton = styled.button`
  cursor: pointer;
  line-height: 115%;
  color: ${props => props.theme.colors.main100};
  font-size: 14px;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
        `
      : css`
          margin-top: 16px;
        `}

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.main110};
  }

  &:focus {
    text-decoration: underline;
    color: ${props => props.theme.colors.main110};
  }
`

const ButtonsContainer = styled.div`
  gap: 16px;
  display: flex;
  justify-content: center;
  max-width: 736px;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
          align-items: center;
        `
      : css`
          flex: 1;
          align-items: flex-end;
          width: 100%;
        `}
`

const mobileButtonsMixin = css`
  height: 48px;
  font-size: 16px;
  flex: 1;
`

const CancelButton = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: ${pageButtons.minWidth().px};
        `
      : css`
          ${mobileButtonsMixin}
        `}
`

const SubmitButton = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: ${pageButtons.minWidth().px};
        `
      : css`
          ${mobileButtonsMixin}
        `}
`

export const BirthDateOverlay = React.memo((props: IProps) => {
  const {
    defaultDueDate,
    subtypes,
    initialSubtype,
    onCancel,
    onSubmit,
    toastManager
  } = props
  const { t } = useTranslation()

  const [leaveChanges, setLeaveChanges] = useState({
    birthDate: defaultDueDate
  })

  const { leave, error, loading }: IBirthDate = useBirthDate(leaveChanges)

  const [subtype, setSubtype] = useState(initialSubtype)
  const [birthDate, setBirthDate] = useState(null)
  const [disabilityEndDate, setDisabilityEndDate] = useState(null)
  const [isDisabilityEndMode, setIsDisabilityEndMode] = useState(false)
  const prevIsDisabilityEndMode: boolean = usePrevious(isDisabilityEndMode)

  const disabilityPickerRef: any = useRef(null)

  const onDisabilityClick = useCallback(() => {
    setIsDisabilityEndMode(!isDisabilityEndMode)
  }, [setIsDisabilityEndMode, isDisabilityEndMode])

  const text: string = useMemo(() => t('babyHasArrived.text'), [t])
  const calendarTitle = useMemo(() => `${t('common.dateOfBirth')}:`, [t])

  const isShowSubtypeSelector: boolean = useMemo(
    () => leave && shouldShowSubtypeSelector(leave, subtypes),
    [leave, subtypes]
  )

  useEffect(() => {
    if (
      !prevIsDisabilityEndMode &&
      isDisabilityEndMode &&
      disabilityPickerRef.current
    ) {
      disabilityPickerRef.current.focus()
    }
  }, [isDisabilityEndMode, prevIsDisabilityEndMode])

  useEffect(() => {
    const variables: any = subtype ? { subtype } : {}
    variables.birthDate = birthDate || defaultDueDate
    setLeaveChanges(variables)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthDate, subtype])

  useEffect(() => {
    if (!isDisabilityEndMode && disabilityEndDate) {
      setDisabilityEndDate(null)
    }
  }, [isDisabilityEndMode, setDisabilityEndDate, disabilityEndDate])

  useEffect(() => {
    if (error) {
      toastManager.addError(t('common.somethingWentWrong'))
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const { isDesktop } = useContext(ScreenContext)

  const birthDateView: ReactNode = useMemo(
    () => (
      <DatePickerWrapper
        TopViewComponent={DatePickerSingleLineView}
        title={calendarTitle}
        momentCurrentMinMax={{
          min: moment('1970-01-01'),
          current: birthDate,
          max: moment()
        }}
        onDateChanged={(date: any) => {
          setBirthDate(date)
          setDisabilityEndDate(null)
        }}
        description=""
        mobileDialogTitle={calendarTitle}
        datePlaceholder={
          birthDate
            ? null
            : t(`common.${isDesktop ? 'selectDate' : 'tapToSelect'}`)
        }
        useDefaultTopOffset
        stickRight
      />
    ),
    [calendarTitle, setBirthDate, birthDate, t, isDesktop]
  )

  const deliveryMethodView: ReactNode = useMemo(
    () =>
      isShowSubtypeSelector && (
        <SubtypeSelectorWrapper
          appearance={'single_line'}
          customTitle={t('babyHasArrived.subtypeSelectorTitle')}
          subtypes={subtypes}
          subtype={subtype}
          onLeaveSubtypeChanged={(st: ILeaveSubType) => {
            setSubtype(st)
            setDisabilityEndDate(null)
          }}
        />
      ),
    [subtypes, subtype, setSubtype, t, isShowSubtypeSelector]
  )

  const disabilityEndDateView: ReactNode = useMemo(() => {
    let currentDisabilityEnd: IMomentCurrentMinMax = null
    if (leave?.dates.disabilityEnd?.current) {
      currentDisabilityEnd = {
        current: disabilityEndDate
          ? disabilityEndDate
          : moment(leave.dates.disabilityEnd.current).utc(),
        min: moment(leave.dates.disabilityEnd.min).utc(),
        max: moment(leave.dates.disabilityEnd.max).utc()
      }
    }

    return (
      <DatePickerWrapper
        TopViewComponent={DatePickerSingleLineView}
        title={t('babyHasArrived.disabilityEndDateTitle')}
        momentCurrentMinMax={currentDisabilityEnd}
        onDateChanged={(date: any) => {
          setDisabilityEndDate(date)
        }}
        description=""
        mobileDialogTitle={t('babyHasArrived.disabilityEndDateTitle')}
        disabled={!isDisabilityEndMode}
        topViewComponentRef={disabilityPickerRef}
        useDefaultTopOffset
        stickRight
      />
    )
  }, [disabilityEndDate, isDisabilityEndMode, leave?.dates.disabilityEnd, t])

  const hasDisabilityEndDate: boolean = useMemo(
    () => !!leave?.dates.disabilityEnd,
    [leave?.dates.disabilityEnd]
  )

  if (!leave) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  return (
    <Container>
      <InnerContainer>
        <H1 role={'alert'}>{t('babyHasArrived.title')}</H1>
        <Text>{text}</Text>
        <ControlsContainer>
          {birthDateView}
          {isShowSubtypeSelector && <Separator />}
          {deliveryMethodView}
        </ControlsContainer>
        {hasDisabilityEndDate && (
          <>
            <DisabilityButton onClick={onDisabilityClick}>
              {t(
                `babyHasArrived.${
                  isDisabilityEndMode
                    ? 'disabilityNoteCancel'
                    : 'disabilityNote'
                }`
              )}
            </DisabilityButton>
            <DisabilityPickerContainer $shown={isDisabilityEndMode}>
              {disabilityEndDateView}
            </DisabilityPickerContainer>
          </>
        )}
        <ButtonsContainer>
          <CancelButton appearance={'cancel'} onClick={onCancel}>
            {t('common.cancel')}
          </CancelButton>
          <SubmitButton
            onClick={() => {
              onSubmit(birthDate, subtype, disabilityEndDate)
            }}
            disabled={!birthDate}
          >
            {t('common.submit')}
          </SubmitButton>
        </ButtonsContainer>
      </InnerContainer>
      {loading && <LoadingSpinner fadesIn fullScreen />}
    </Container>
  )
})

BirthDateOverlay.displayName = 'BirthDateOverlay'

export default withToastManager(BirthDateOverlay)

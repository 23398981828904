import React, { useEffect, useMemo } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
  generatePath
} from 'react-router'
import useAbsences, { IUseAbsences } from 'src/graphql/hooks/useAbsences'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import {
  managerArchiveJourneyMapRoute,
  managerDashboardJourneyMapRoute,
  notFoundPageRoute
} from 'src/routes/constants'

export const AbsenceRedirectHandler = React.memo(() => {
  const navigate = useNavigate()

  useRedirectByUserRole({ navigate, allowedRole: 'manager' })

  const { absenceId, itemId } = useParams()
  const [searchParams] = useSearchParams()

  const absencesResult: IUseAbsences = useAbsences()
  const { absences } = absencesResult

  // TODO: remove support of backward compatibility in future releases
  // needed for old email links: /manager/journeymap/{absenceId}?id={itemId}
  const queryParamItemId = useMemo(() => {
    const id = searchParams.get('itemId')
    // assume old itemid has the following format: <absenceId>/<itemId>
    return id?.split('/')[1]
  }, [searchParams])

  useEffect(() => {
    if (absences?.length) {
      const record = absences.find(abs => abs.id === absenceId)
      if (!record) {
        navigate(notFoundPageRoute)
      }

      const route = record?.archived
        ? managerArchiveJourneyMapRoute
        : managerDashboardJourneyMapRoute

      const routeItem = generatePath(route, {
        absenceId,
        itemId: itemId || queryParamItemId
      })

      navigate(routeItem)
    }
  }, [navigate, absenceId, itemId, queryParamItemId, absences])

  return null
})

AbsenceRedirectHandler.displayName = 'AbsenceRedirectHandler'

export default AbsenceRedirectHandler

import React from 'react'
import { Button, H1, MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router'
import { welcomeRoute } from 'src/routes/constants'
import usePageTitle from 'src/components/hooks/usePageTitle'

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const TextWrapper = styled(MarkdownText)<any>`
  white-space: pre-wrap;
  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: 100%;
        `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin-bottom: 16px;
    `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}
`

export const LoggedOutPage = React.memo(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  usePageTitle('signedOut')
  return (
    <Container aria-labelledby={'title text'}>
      <H1 id={'title'}>{t('loggedOutPage.title')}</H1>
      <TextWrapper allowsParagraph id={'text'}>
        {t('loggedOutPage.body')}
      </TextWrapper>
      <ButtonContainer>
        <ButtonWrapper
          onClick={() => {
            navigate(welcomeRoute)
          }}
        >
          {t('loggedOutPage.buttonTitle')}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})

LoggedOutPage.displayName = 'LoggedOutPage'

export default withRouter(LoggedOutPage)

import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import beforeDueDatePeriod from '../beforeDueDatePeriod'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const isSameStartMinMax = input.period.periodStart.max.isSame(
    input.period.periodStart.min
  )
  const isSameEndMinMax = input.period.periodEnd.max.isSame(
    input.period.periodEnd.min
  )
  const isBeforeDueDatePeriod = beforeDueDatePeriod(input)
  return {
    ...baseConfig,
    periodPickerSelectedNotice: {
      show: true,
      showDoubleText: true
    },
    datePickerComponent: isBeforeDueDatePeriod
      ? LeaveDatePeriodPicker
      : MultiDatePicker,
    periodPickerDisableStartDate: isSameStartMinMax,

    periodKeyDate: isSameEndMinMax ? 'periodStart' : baseConfig.periodKeyDate,
    periodPickerCreateAddable: isBeforeDueDatePeriod
      ? true
      : baseConfig.periodPickerCreateAddable
  }
}

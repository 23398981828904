import React, { useState, useRef, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Popover from 'src/components/Popover'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router'
import { userProfileRoute } from 'src/routes/constants'
import PopoverArrow from 'src/components/Popover/components/Arrow'
import PopoverList, { IListItem } from 'src/components/Popover/components/List'
import RouteContext from 'src/routes/Context'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import RolesSwitcher from 'src/components/Header/components/desktop/User/components/RolesSwitcher'
import { getIsEmployee } from 'src/utils/userUtils'
import { UserRole } from 'src/react-app-env'

interface IProps {
  user: IUser
  className?: any
  logout: () => void
}

const Container = styled.div<{ $addsMarginTop: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${props =>
    props.$addsMarginTop &&
    css`
      margin-top: 11px;
    `}
`

const Button = styled.button<{ $color: string }>`
  user-select: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: color 100ms linear;
  padding: 0 6px;
  border-radius: 16px;
  border: 2px solid transparent;

  &:focus {
    outline: none;
    border: 2px solid ${props => props.$color};
  }

  ${props =>
    props.$color &&
    css`
      color: ${props.$color};

      &:hover {
        color: ${darken(0.1, props.$color)};
      }
    `}
`

const Text = styled.span<{ $color: string }>`
  font-family: Roboto, serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  white-space: nowrap;
  color: ${props => props.theme.colors.main100};

  &:hover {
    color: ${props => props.theme.colors.main90};
  }

  ${props =>
    props.$color &&
    css`
      color: ${props.$color};

      &:hover {
        color: ${darken(0.1, props.$color)};
      }
    `}
`

const ManagerLabel = styled.span`
  margin-right: 22px;
  cursor: pointer;
  user-select: none;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-align: right;
  line-height: 100%;
  color: ${props => props.theme.colors.sub60};
`

const PopoverListWrapper = styled(PopoverList)`
  min-width: 224px;
`

export const User = React.memo((props: IProps) => {
  const { isManagerRoute, isHrAdminRoute, primaryColor } =
    useContext(RouteContext)
  const { user, className, logout } = props
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const navigate = useNavigate()
  const popoverRef = useRef(null)
  const wrapperRef = useRef(null)
  const { t } = useTranslation()

  const renderPopover = () => {
    const items: IListItem[] = []
    if (getIsEmployee(user.roles as UserRole[])) {
      items.push({
        title: t('header.profileAndSettings'),
        action: () => {
          navigate(userProfileRoute)
        }
      })
    }

    items.push({
      title: t('header.signOut'),
      action: () => {
        logout()
      }
    })

    return (
      <Popover
        parent={wrapperRef.current}
        ref={popoverRef}
        beforeStateChanged={onPopoverChangesState}
      >
        <>
          <RolesSwitcher user={user} />
          <PopoverListWrapper items={items} />
        </>
      </Popover>
    )
  }

  const onPopoverChangesState = (oldIsOpen: boolean, newIsOpen: boolean) => {
    setIsPopoverOpen(newIsOpen)
  }

  const onClick = () => {
    const { current } = popoverRef
    if (!current) {
      return
    }

    if (current) {
      current.toggle()
    }
  }

  const text = useMemo(() => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName.charAt(0)}.`
    }
    return t('common.menu')
  }, [user, t])

  const renderLabel = () => {
    if (!isManagerRoute && !isHrAdminRoute) {
      return null
    }

    let labelKey: string

    if (isManagerRoute) {
      labelKey = 'managerLowerCase'
    }

    if (isHrAdminRoute) {
      labelKey = 'hr'
    }

    return <ManagerLabel aria-hidden>{t(`common.${labelKey}`)}</ManagerLabel>
  }

  return (
    <Container
      className={className}
      onClick={onClick}
      $addsMarginTop={isManagerRoute || isHrAdminRoute}
      ref={wrapperRef}
    >
      <Button
        $color={primaryColor}
        aria-haspopup
        aria-expanded={isPopoverOpen}
        aria-label={t('common.menu')}
      >
        <Text aria-hidden $color={primaryColor}>
          {text}
        </Text>
        <PopoverArrow isOpen={isPopoverOpen} color={primaryColor} />
        {renderPopover()}
      </Button>
      {renderLabel()}
    </Container>
  )
})

User.displayName = 'User'

export default withRouter(User)

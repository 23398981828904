import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button, H1, MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useNavigate } from 'react-router'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { errorPageRoute } from 'src/routes/constants'
import withQueries from 'src/components/HOC/withQueries'
import { IWithQueriesProps } from 'src/react-app-env'
import LoadingSpinner from 'src/components/LoadingSpinner'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import redirectByUserRole from 'src/utils/redirectByUserRole'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'

interface IProps extends IWithQueriesProps {}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const Title = styled(H1)`
  width: 100%;
`

const TextWrapper = styled(MarkdownText)`
  color: ${props => props.theme.colors.dark60};
  line-height: 150%;
  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: 100%;
        `}

  p {
    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin-bottom: 16px;
    `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}
`

export const InfoPage = React.memo((props: IProps) => {
  const { queries } = props
  const userResult: IUseUser = useUser()
  const sharedContext: ISharedContext = useContext(SharedContext)
  const navigate = useNavigate()
  const [blockerPages, setBlockerPages] = useState<IAlert[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  usePageTitle('welcome')

  const currentPageItem: IAlert = useMemo(
    () => blockerPages[currentPageIndex],
    [blockerPages, currentPageIndex]
  )

  const fetchBlockerPages = useCallback(async () => {
    try {
      setIsLoading(true)
      const alerts: IAlert[] = await queries.fetchAlerts()
      const blockerPageItems: IAlert[] = alerts.filter(
        (a: IAlert) => a.type === 'BlockerPage'
      )
      setBlockerPages(blockerPageItems)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute)
      }
    } finally {
      setIsLoading(false)
    }
  }, [navigate, queries])

  useEffect(() => {
    fetchBlockerPages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // handle the empty page after loading or last page then redirect the user
    if (
      (!isLoading && !userResult.loading && blockerPages.length === 0) ||
      (currentPageIndex > 0 && currentPageIndex === blockerPages.length)
    ) {
      redirectByUserRole(userResult.user, navigate, sharedContext.apolloClient)
    }
  }, [
    blockerPages,
    currentPageIndex,
    navigate,
    sharedContext,
    userResult,
    isLoading
  ])

  const closeBlockerPage = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true)
        await queries.closeAlert(id)
      } catch (error) {
        if (error instanceof Error) {
          navigate(errorPageRoute, {
            state: { error: error.message }
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [navigate, queries]
  )

  if (userResult.loading || isLoading || !currentPageItem) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  return (
    <Container>
      <Title>{currentPageItem.title}</Title>
      <TextWrapper allowsParagraph>{currentPageItem.content}</TextWrapper>
      <ButtonContainer>
        <ButtonWrapper
          onClick={() => {
            closeBlockerPage(currentPageItem.id)
            setCurrentPageIndex(prevIndex => prevIndex + 1)
          }}
        >
          {currentPageItem.cta.text}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})

InfoPage.displayName = 'InfoPage'

export default withQueries(InfoPage)

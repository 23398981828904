import {
  hasApproximatePayment,
  isOpenEndedPeriodMode
} from 'src/utils/periodUtils'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const isOpenEndedPeriod: boolean = isOpenEndedPeriodMode(input.period)
  const isApproximatePayment: boolean = hasApproximatePayment(input.period)

  return {
    ...baseConfig,
    datePickerComponent: MultiDatePicker,
    alerts: input.leave.metadata?.LTDwarning
      ? ['longTermDisabilityMedicalExtend']
      : baseConfig.alerts,
    periodPickerDisableStartDate: isOpenEndedPeriod,
    datePickerFooterInfoMessage: (t: any) =>
      isOpenEndedPeriod
        ? t('timeline.datePickerAlerts.openEndedNotice.text')
        : null,
    detailsType: isApproximatePayment
      ? `${baseConfig.detailsType}_Approximate`
      : baseConfig.detailsType
  }
}

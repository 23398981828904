import React, { ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { closeAccountRoute } from 'src/routes/constants'
import { getPrivacyPolicyUrl, getTermsUrl } from 'src/config'
import { Container, Description } from '../ComponentStyles'
import TitleView from '../TitleView'
import ConfirmationView from '../ConfirmationView'
import ButtonsView from '../ButtonsView'

interface IProps {
  getEnableOptOut: boolean
  onCancel: () => void
  onContinue: () => void
}

const Link = styled.button`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  color: ${props => props.theme.colors.main100};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const Privacy = React.memo((props: IProps) => {
  const { getEnableOptOut, onCancel, onContinue } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const termsUrl = getTermsUrl()
  const privacyPolicyUrl = getPrivacyPolicyUrl()

  const bodyText: string = useMemo(
    () =>
      getEnableOptOut
        ? t('userConsentPage.bodyWithLink')
        : t('userConsentPage.body'),
    [getEnableOptOut, t]
  )

  const confirmation: ReactNode = useMemo(
    () => (
      <ConfirmationView
        onChange={setConfirmed}
        translationKey={t('userConsentPage.confirmation', {
          termsUrl,
          privacyPolicyUrl
        })}
        confirmed={confirmed}
      />
    ),
    [confirmed, privacyPolicyUrl, t, termsUrl]
  )

  const buttons: ReactNode = useMemo(
    () => (
      <ButtonsView
        cancelTitle={t('common.cancel')}
        onCancelClick={onCancel}
        continueTitle={t('common.continue')}
        onContinueClick={onContinue}
        isContinueDisabled={!confirmed}
      />
    ),
    [t, onCancel, onContinue, confirmed]
  )

  return (
    <Container>
      <TitleView text={t('userConsentPage.title')} />
      <Description
        allowsParagraph
        components={{
          a: (linkProps: any) => (
            <Link onClick={() => navigate(closeAccountRoute)}>
              {linkProps.children}
            </Link>
          )
        }}
      >
        {bodyText}
      </Description>
      {confirmation}
      {buttons}
    </Container>
  )
})

Privacy.displayName = 'Privacy'

export default Privacy

import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Moment } from 'moment'
import styled from 'styled-components'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { useNavigate } from 'react-router'
import withQueries from 'src/components/HOC/withQueries'
import zIndex from 'src/constants/zIndex'
import { IWithQueriesProps } from 'src/react-app-env'
import {
  errorPageRoute,
  timelineRoute,
  createLeaveRoute
} from 'src/routes/constants'
import LoadingSpinner from 'src/components/LoadingSpinner'
import PregnancyLossContext, {
  NumberOfExpectedChildren,
  IPregnancyLossContext
} from './context'
import { getWorkflow, getCurrentStageView, PregnancyLossStage } from './methods'

interface IProps extends IWithQueriesProps {}

const Page = styled.div`
  height: 100%;
  position: relative;
  z-index: ${zIndex.vacationSteps.page};
`

export const PregnancyChangeContainer = React.memo((props: IProps) => {
  const navigate = useNavigate()

  useRedirectByUserRole({ navigate, allowedRole: 'employee' })

  const [leave, setLeave] = useState(null)
  const [isFetching, setFetching] = useState<boolean>()
  const [currentStage, setCurrentStage] =
    useState<PregnancyLossStage>('pregnancyChange')
  const [isMiscarriageOrStillBirth, setIsMiscarriageOrStillBirth] =
    useState<boolean>(true)
  const [dueDate, setDueDate] = useState<Moment>(null)
  const [expectedChildren, setExpectedChildren] =
    useState<NumberOfExpectedChildren>(null)

  const fetchLeaveBasic = useCallback(async () => {
    try {
      setFetching(true)
      const leaveResult: ILeave =
        await props.queries.fetchLeaveWithDatesAndMetadata({
          notFound: () => navigate(createLeaveRoute)
        })
      setLeave(leaveResult)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setFetching(false)
    }
  }, [props, navigate, setFetching])

  const resetToMiscarriage = useCallback(async () => {
    try {
      setFetching(true)
      const extra = {} as IExtraResetToMiscarriageInput
      if (expectedChildren) {
        extra.expectedChildren = expectedChildren as IExpectedChildren
      }
      await props.queries.resetToMiscarriage({
        date: dueDate.toDate().toISOString(),
        extra
      })
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setFetching(false)
    }
  }, [props, navigate, setFetching, dueDate, expectedChildren])

  useEffect(() => {
    fetchLeaveBasic()
    // eslint-disable-next-line
  }, [])

  const workflow = useMemo(() => getWorkflow(), [])

  const onNextStage = useCallback(async () => {
    const newStage = workflow.moveNext()
    if (newStage) {
      if (newStage === 'pregnancyChangeSuccess') {
        await resetToMiscarriage()
      }
      setCurrentStage(newStage)
    } else {
      navigate(timelineRoute)
    }
  }, [workflow, setCurrentStage, navigate, resetToMiscarriage])

  const onPrevStage = useCallback(() => {
    const newStage = workflow.moveBack()

    if (newStage) {
      setCurrentStage(newStage)
    } else {
      navigate(timelineRoute)
    }
  }, [workflow, setCurrentStage, navigate])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue: IPregnancyLossContext = {
    leave,
    onNextStage,
    onPrevStage,

    dueDate,
    setDueDate,

    expectedChildren,
    setExpectedChildren,

    isMiscarriageOrStillBirth,
    setIsMiscarriageOrStillBirth
  }

  useEffect(() => {
    if (workflow) {
      workflow.setContext(contextValue)
      setCurrentStage(workflow.current)
    }
  }, [workflow, contextValue])

  if (isFetching || !leave) {
    return <LoadingSpinner fullScreen fadesIn />
  }

  if (leave && !leave.metadata?.miscarriageSupported) {
    navigate(timelineRoute)
    return
  }

  return (
    <Page>
      <PregnancyLossContext.Provider value={contextValue}>
        {getCurrentStageView(currentStage)}
      </PregnancyLossContext.Provider>
    </Page>
  )
})

PregnancyChangeContainer.displayName = 'PregnancyChangeContainer'

export default withQueries(PregnancyChangeContainer)

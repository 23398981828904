import {
  createLeaveRoute,
  managerDashboardRoute,
  notificationsRoute,
  timelineRoute,
  userProfileRoute
} from 'src/routes/constants'
import { isPathnameLike } from 'src/utils/routeUtils'
import { isNewOrDraftLeave, isSuspendedLeave } from 'src/utils/leaveStatusUtils'
import { NavigateFunction, Location } from 'react-router'

export default (
  status: ILeaveStatus,
  navigate: NavigateFunction,
  location: Location
): boolean => {
  const { pathname } = location
  const isUserProfileRoute: boolean = isPathnameLike(pathname, userProfileRoute)
  const isNotificationsRoute: boolean = isPathnameLike(
    pathname,
    notificationsRoute
  )
  const isManagerJourneyMapRoute: boolean = isPathnameLike(
    pathname,
    managerDashboardRoute
  )

  if (isUserProfileRoute || isNotificationsRoute || isManagerJourneyMapRoute) {
    return false
  }

  let redirectRoute: string = null
  if (isNewOrDraftLeave(status)) {
    redirectRoute = timelineRoute
  } else if (isSuspendedLeave(status)) {
    redirectRoute = createLeaveRoute
  }

  const willRedirect = !isPathnameLike(pathname, redirectRoute)

  if (willRedirect) {
    navigate(redirectRoute)
  }

  return willRedirect
}

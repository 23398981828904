import { LeavePhase, Priority } from 'src/react-app-env'
import moment, { Moment } from 'moment'
import {
  IFilter,
  IPeriodFilter,
  IPriorityFilter
} from 'src/components/JourneyMap/Filters'
import { ITEM_COMPLETE_ANIMATION_DURATION } from 'src/features/JourneyMap/constants'
import first from 'lodash.first'
import {
  timelineRoute,
  journeyMapRoute,
  managerDashboardJourneyMapRoute,
  managerArchiveJourneyMapRoute
} from 'src/routes/constants'
import redirectByLeaveStatus from 'src/utils/redirectByLeaveStatus'
import { isPathnameLike } from 'src/utils/routeUtils'
import { getNowMoment, getUTCMomentFromDate } from 'src/utils/dateUtils'
import { MetricEventJourneyMapImpression } from 'src/constants/metrics'
import { Location, NavigateFunction, generatePath } from 'react-router'
import cloneDeep from 'lodash.clonedeep'

export interface IJourneyMapStats {
  totalItemsCount: number
  currentItemsCount: number
  overdueItemsCount: number
}

const defaultPriorities: Priority[] = ['Critical', 'High', 'Medium', 'Low']
const defaultPeriods: LeavePhase[] = [
  'Planning',
  'OnLeave',
  'BackToWork',
  'AtWork'
]

const getOverDueItems = (items: IJourneyMapItem[]): IJourneyMapItem[] => {
  const now: Moment = getNowMoment()
  return items.filter(
    (item: IJourneyMapItem) =>
      (item as any).animatesCompleted ||
      (!item.completed && now.isAfter(item.dueDate))
  )
}

const tasksThisWeek = (items: IJourneyMapItem[]): IJourneyMapItem[] => {
  const now: Moment = getNowMoment()
  const start = now.clone().startOf('week')
  const end = now.clone().endOf('week')

  return items.filter(
    (item: IJourneyMapItem) =>
      !item.completed &&
      end.isSameOrAfter(item.dueDate) &&
      start.isSameOrBefore(item.dueDate)
  )
}

const getTodayOrLaterItem = (journeyMap: IJourneyMapItem[]) => {
  const now: Moment = getNowMoment()
  let item: IJourneyMapItem = null
  for (item of journeyMap) {
    if (item.dueDate.isSameOrAfter(now)) {
      break
    }
  }
  return item
}

const filterItems = (
  items: IJourneyMapItem[],
  priorityFilters: IPriorityFilter[],
  periodFilters: IPeriodFilter[],
  isOverdueMode: boolean
): IJourneyMapItem[] => {
  if (!items) {
    return []
  }

  if (isOverdueMode) {
    return getOverDueItems(items)
  }

  let result: IJourneyMapItem[] = []

  const activePriorityFilters: string[] = []
  priorityFilters.forEach((f: IPriorityFilter) => {
    if (f.checked) {
      activePriorityFilters.push(f.priority)
    }
  })
  if (activePriorityFilters.length === 0) {
    result = items
  } else {
    const newItems: IJourneyMapItem[] = items.filter(
      (i: IJourneyMapItem) => activePriorityFilters.indexOf(i.priority) !== -1
    )
    result = newItems
  }

  const activePeriodFilters: string[] = []
  periodFilters.forEach((p: IPeriodFilter) => {
    if (p.checked) {
      activePeriodFilters.push(p.type)
    }
  })
  if (activePeriodFilters.length !== 0) {
    const newItems: IJourneyMapItem[] = result.filter(
      (i: IJourneyMapItem) => activePeriodFilters.indexOf(i.phase) !== -1
    )
    result = newItems
  }

  return result
}

const createPeriodFilters = (selected = []): IPeriodFilter[] =>
  defaultPeriods.map((phase: LeavePhase) => ({
    label: null,
    type: phase,
    checked: selected.includes(phase),
    disabled: false
  }))

const createPriorityFilters = (selected = []): IPriorityFilter[] =>
  defaultPriorities.map((priority: Priority) => ({
    priority,
    checked: selected.includes(priority),
    disabled: false
  }))

const switchFiltersDisability = (
  filters: IFilter[],
  disabled: boolean
): any[] => filters.map((f: IFilter) => ({ ...f, checked: false, disabled }))

const flipFilter = (index: number, filters: IFilter[]): any[] => {
  if (!filters || filters.length === 0) {
    return filters
  }
  if (index < 0) {
    return filters
  }
  if (index > filters.length - 1) {
    return filters
  }

  const filtersCopy: IFilter[] = [...filters]
  const filter: IFilter = filtersCopy[index]
  filtersCopy[index] = { ...filter, checked: !filter.checked }
  return filtersCopy
}

const resetFilters = (filters: IFilter[]): any[] => {
  if (!filters || filters.length === 0) {
    return filters
  }

  return filters.map((filter: IFilter) => ({ ...filter, checked: false }))
}

const toggleItemCompleted = (
  itemId: string,
  absenceId: string,
  deferred: boolean,
  query: any,
  removingItemsId: string[],
  setRemovingItemsId: (ids: string[]) => void,
  onToggleError: () => void
) => {
  if (deferred) {
    setRemovingItemsId([...removingItemsId, itemId])
  }
  window.setTimeout(
    () => {
      if (absenceId) {
        query(itemId, absenceId, { badRequest: onToggleError })
      } else {
        query(itemId, { badRequest: onToggleError })
      }
    },
    deferred ? ITEM_COMPLETE_ANIMATION_DURATION : 0
  )
}

const getItemById = (id: string, items: IJourneyMapItem[]): IJourneyMapItem => {
  if (!id) {
    return null
  }
  if (!Array.isArray(items)) {
    return null
  }

  const item: IJourneyMapItem = first(
    items.filter((i: IJourneyMapItem) => i.id === id)
  )

  return item ? item : null
}

const fetchLeaveJourneyMap = async ({
  navigate,
  location,
  queries,
  setIsFetching,
  shouldSendImpressionMetric,
  setLeave
}: any) => {
  try {
    setIsFetching(true)
    const leaveResult: ILeave = await queries.fetchLeaveJourneyMap({
      fetchPolicy: 'cache-first',
      notFound: () => {
        navigate(timelineRoute)
      }
    })

    setIsFetching(false)

    if (leaveResult) {
      const { status } = leaveResult
      redirectByLeaveStatus(status, navigate, location)
      if (shouldSendImpressionMetric) {
        sendJourneyMapImpressionMetric({ queries, leaveId: leaveResult.id })
      }
    }

    setLeave(leaveResult)
  } catch (_error) {
    navigate(timelineRoute)
  }
}

const sendJourneyMapImpressionMetric = async ({ queries, leaveId }: any) => {
  await queries.createMetric(
    {
      eventType: MetricEventJourneyMapImpression,
      id: leaveId
    },
    { anyError: (): void => undefined }
  )
}

const openItemIfNeeded = (
  navigate: NavigateFunction,
  location: Location,
  params: any,
  currentLeaveId: string,
  items: IJourneyMapItem[],
  setSelectedItemId: (id: string) => void
): void => {
  const { leaveId, absenceId, itemId } = params
  const parentId: string = leaveId || absenceId

  if (!itemId || !parentId) {
    return
  }

  const item = items.find((i: IJourneyMapItem) => i.id === itemId)
  if (parentId === currentLeaveId && item) {
    setSelectedItemId(itemId)
  }

  let newPath = journeyMapRoute
  if (isPathnameLike(location.pathname, managerDashboardJourneyMapRoute)) {
    newPath = generatePath(managerDashboardJourneyMapRoute, {
      absenceId: parentId,
      itemId: ''
    })
  } else if (isPathnameLike(location.pathname, managerArchiveJourneyMapRoute)) {
    newPath = generatePath(managerArchiveJourneyMapRoute, {
      absenceId: parentId,
      itemId: ''
    })
  }

  if (newPath && newPath !== location.pathname) {
    navigate(newPath, { replace: true, state: {} })
  }
}

const getJourneyMapStats = (
  journeyMap: IJourneyMapItem[],
  priorityFilters: IPriorityFilter[],
  periodFilters: IPeriodFilter[],
  isOverdueMode: boolean
): IJourneyMapStats => {
  const totalItemsCount: number = journeyMap.length
  const currentItemsCount: number = totalItemsCount
    ? filterItems(journeyMap, priorityFilters, periodFilters, isOverdueMode)
        .length
    : 0
  const overdueItemsCount: number = totalItemsCount
    ? getOverDueItems(journeyMap).length
    : 0

  return { totalItemsCount, currentItemsCount, overdueItemsCount }
}

const convertJourneyMapDueDateToMoment = (journeyMap: IJourneyMapItem[]) => {
  const result = cloneDeep(journeyMap)

  if (result?.length > 0) {
    result.forEach((j: IJourneyMapItem) => {
      if (!moment.isMoment(j.dueDate)) {
        j.dueDate = getUTCMomentFromDate(new Date(j.dueDate.toString()))
      }
    })
  }

  return result
}

const showStaticJourneyMap = (leave: ILeave): boolean =>
  leave.type === 'Miscarriage' && leave.metadata?.transitionFromType

export {
  createPeriodFilters,
  createPriorityFilters,
  filterItems,
  getOverDueItems,
  tasksThisWeek,
  switchFiltersDisability,
  getTodayOrLaterItem,
  flipFilter,
  resetFilters,
  toggleItemCompleted,
  getItemById,
  fetchLeaveJourneyMap,
  openItemIfNeeded,
  getJourneyMapStats,
  convertJourneyMapDueDateToMoment,
  showStaticJourneyMap
}

import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

// eslint-disable-next-line react/display-name
export default (Component: any) => (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  return <Component {...props} router={{ location, navigate, params }} />
}

import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  ReactNode
} from 'react'
import { useNavigate } from 'react-router'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import ClaimUpdatePage from './components/ClaimUpdatePage'
import { IWithDialogManager, IWithQueriesProps } from 'src/react-app-env'
import withQueries from 'src/components/HOC/withQueries'
import LoadingSpinner from 'src/components/LoadingSpinner'
import {
  errorPageRoute,
  timelineRoute,
  createLeaveRoute
} from 'src/routes/constants'
import IntroductionView from './components/IntroductionView'
import ReviewDatesView from './components/ReviewDatesView'
import { ws } from 'src/utils/workflow'
import { withDialogManager } from 'src/components/DialogManager'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { isPregnancyLeaveWithoutBabyArrived } from 'src/utils/leaveUtils'

interface IProps extends IWithQueriesProps, IWithDialogManager {}

export type ClaimUpdateStage = 'introduction' | 'reviewDates'

const AlertContent = styled(ReactMarkdown)`
  white-space: pre-wrap;
`

const ClaimUpdateContainer = React.memo((props: IProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [leave, setLeave] = useState<ILeave>(null)
  const [isFetching, setFetching] = useState<boolean>()
  const [claimUpdateDetails, setClaimUpdateDetails] =
    useState<IClaimUpdateDetails>(null)
  const [currentStage, setCurrentStage] = useState<ClaimUpdateStage>(null)
  const [isChecked, setIsChecked] = useState<boolean>(false)

  useRedirectByUserRole({ navigate, allowedRole: 'employee' })
  usePageTitle('claimUpdate')

  const fetchLeave = useCallback(async () => {
    try {
      setFetching(true)
      const { leave: leaveResult } = await props.queries.fetchLeaveWithUser(
        {
          fetchPolicy: 'network-only'
        },
        {
          notFound: () => navigate(createLeaveRoute)
        },
        true
      )
      return leaveResult
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setFetching(false)
    }
  }, [props, navigate])

  const fetchClaimUpdateDetails = useCallback(async () => {
    try {
      setFetching(true)
      const claimUpdateDetailsResult: IClaimUpdateDetails =
        await props.queries.fetchClaimUpdateDetails({
          fetchPolicy: 'network-only',
          notFound: () => navigate(timelineRoute)
        })
      setClaimUpdateDetails(claimUpdateDetailsResult)
      setIsChecked(claimUpdateDetailsResult.skipWelcomePage)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setFetching(false)
    }
  }, [props, navigate])

  const requestTpaUpdate = useCallback(
    async (setSkipWelcomePage: boolean) => {
      try {
        setFetching(true)
        const result: boolean = await props.queries.requestTpaUpdate(
          setSkipWelcomePage,
          {
            badRequest: (error: any) => {
              navigate(errorPageRoute, {
                state: { error }
              })
            }
          }
        )

        if (result) {
          navigate(timelineRoute)
        }
      } catch (error) {
        if (error instanceof Error) {
          navigate(errorPageRoute, {
            state: { error: error.message }
          })
        }
      } finally {
        setFetching(false)
      }
    },
    [navigate, props.queries]
  )

  const onShowDialog = useCallback(() => {
    props.dialogManager.add({
      title: t('claimUpdate.sentDialog.title'),
      children: (
        <AlertContent>
          {t(
            isPregnancyLeaveWithoutBabyArrived(leave)
              ? 'claimUpdate.sentDialog.body_NoBabyArrivedPregnancy'
              : 'claimUpdate.sentDialog.body'
          )}
        </AlertContent>
      ),
      buttons: [
        {
          title: t('common.close'),
          onClick: (): void => undefined
        }
      ]
    })
  }, [leave, props.dialogManager, t])

  useEffect(() => {
    fetchLeave().then((leaveResult: ILeave) => {
      if (leaveResult?.isDirty) {
        navigate(timelineRoute)
      } else {
        setLeave(leaveResult)
        fetchClaimUpdateDetails()
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (claimUpdateDetails) {
      setCurrentStage(
        claimUpdateDetails.skipWelcomePage ? 'reviewDates' : 'introduction'
      )
    }
  }, [claimUpdateDetails])

  const workflow = useMemo(
    () =>
      claimUpdateDetails?.skipWelcomePage
        ? ws<ClaimUpdateStage>(['reviewDates'])
        : ws<ClaimUpdateStage>(['introduction', 'reviewDates']),
    [claimUpdateDetails?.skipWelcomePage]
  )

  const onNextStage = useCallback(() => {
    const newStage = workflow.moveNext()

    if (newStage) {
      setCurrentStage(newStage)
    } else {
      requestTpaUpdate(isChecked)
      onShowDialog()
    }
  }, [workflow, requestTpaUpdate, isChecked, onShowDialog])

  const onPrevStage = useCallback(() => {
    const newStage = workflow.moveBack()

    if (newStage) {
      setCurrentStage(newStage)
    } else {
      navigate(timelineRoute)
    }
  }, [workflow, navigate])

  const getCurrentStageView = useCallback(
    (stage: ClaimUpdateStage): ReactNode => {
      switch (stage) {
        case 'introduction':
          return (
            <IntroductionView
              name={stage}
              leaveType={leave?.type}
              checked={isChecked}
              onChecked={setIsChecked}
              onBackClick={onPrevStage}
              onNextClick={onNextStage}
            />
          )
        case 'reviewDates':
          return (
            <ReviewDatesView
              name={stage}
              leaveType={leave?.type}
              claimUpdateDetails={claimUpdateDetails}
              onBackClick={onPrevStage}
              onNextClick={onNextStage}
            />
          )

        default:
          return null
      }
    },
    [claimUpdateDetails, isChecked, leave?.type, onNextStage, onPrevStage]
  )

  const childrenView = useMemo(
    () => getCurrentStageView(currentStage),
    [currentStage, getCurrentStageView]
  )

  if (isFetching || !leave || !claimUpdateDetails) {
    return <LoadingSpinner fullScreen fadesIn />
  }

  if (leave && !leave.metadata?.hasChangesForTPA) {
    navigate(timelineRoute)
    return
  }

  return <ClaimUpdatePage name={currentStage}>{childrenView}</ClaimUpdatePage>
})

ClaimUpdateContainer.displayName = 'ClaimUpdateContainer'

export default withDialogManager(withQueries(ClaimUpdateContainer))

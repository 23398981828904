import styled, { css } from 'styled-components'
import { MarkdownText } from 'src/UIKit'

const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      height: 100%;
      padding: 0 16px;
    `}
`

const Description = styled(MarkdownText)`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  letter-spacing: 0.15px;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: 100%;
          font-size: 14px;
        `}
  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}

    &:hover {
      text-decoration: underline;
    }
  }
`

export { Container, Description }

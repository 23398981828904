import React from 'react'
import styled, { css } from 'styled-components'
import { Checkbox, MarkdownText } from 'src/UIKit'

interface IProps {
  translationKey: string
  onChange: (value: boolean) => void
  confirmed: boolean
}

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 40px;

  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: 100%;
          max-width: 736px;
        `
      : css`
          width: 100%;
        `}
`

const ConfirmationTextContainer = styled.span`
  user-select: none;
  cursor: pointer;
  padding: 2px 0 0 18px;
`

const ConfirmationText = styled(MarkdownText)`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ConfirmationView = React.memo((props: IProps) => {
  const { onChange, translationKey, confirmed } = props

  return (
    <ConfirmationContainer>
      <Checkbox
        name="checkbox"
        checked={confirmed}
        onChange={() => {
          onChange(!confirmed)
        }}
        ariaLabel={translationKey}
      />
      <ConfirmationTextContainer
        onClick={() => {
          onChange(!confirmed)
        }}
      >
        <ConfirmationText
          allowsParagraph
          components={{
            a: (linkProps: any) => (
              <a
                href={linkProps.href}
                onClick={e => {
                  e.stopPropagation()
                }}
                target={'_blank'}
                rel="noopener noreferrer"
                aria-label={linkProps.href}
              >
                {linkProps.children}
              </a>
            )
          }}
        >
          {translationKey}
        </ConfirmationText>
      </ConfirmationTextContainer>
    </ConfirmationContainer>
  )
})

ConfirmationView.displayName = 'ConfirmationView'

export default ConfirmationView

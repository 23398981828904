import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import Navigation from 'src/components/Header/components/Navigation'
import Notifications from 'src/components/Header/components/Notifications'
import {
  loggedOutPageRoute,
  managerNotificationsRoute,
  notificationsRoute
} from 'src/routes/constants'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate, useLocation } from 'react-router'
import zIndex from 'src/constants/zIndex'
import { HR_ADMIN_SUB_ITEM_SELECTED, MENU_OPEN } from 'src/constants/events'
import Disclaimer from 'src/components/Disclaimer'
import RouteContext from 'src/routes/Context'
import { getCurrentUserRole, switchToRole } from 'src/utils/userUtils'
import { useTranslation } from 'react-i18next'
import { UserRole } from 'src/react-app-env'
import { isPathnameLike } from 'src/utils/routeUtils'
import { Icon } from 'src/UIKit'
import RoleSwitchModalView from 'src/components/Header/components/mobile/RoleSwitchModalView'
import SharedContext from 'src/contexts/SharedContext'
import createTheme from 'src/theme'
import HrAdminMenuContent from './components/HrAdminMenuContent'
import ReactFocusLock from 'react-focus-lock'
import { KEY_ENTER, KEY_SPACE } from 'src/constants/keys'
const { colors } = createTheme()

const OPEN_TIME = 300
const CLOSE_TIME = 200

let timeoutId: number

interface IProps {
  className?: string
  user: IUser
  notifications: INotificationFeed
  logout: () => void
  testNoRandom?: boolean
}

const Wrapper = styled.div`
  padding: 16px;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: -5px;
    border-radius: 15px;
  }
`

const fadeInKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`

const Overlay = styled.div<{ $isOpening: boolean; $isClosing: boolean }>`
  z-index: ${zIndex.menu.overlay};
  background: rgb(60 90 120 / 30%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);

  ${(props: any) => {
    if (props.$isOpening) {
      return css`
        animation: ${fadeInKeyFrames} ${OPEN_TIME}ms ease-out forwards;
      `
    }
    if (props.$isClosing) {
      return css`
        animation: ${fadeInKeyFrames} ${CLOSE_TIME}ms ease-in reverse forwards;
      `
    }
  }}
`

const slideInKeyFrames = keyframes`
  0% {
    transform: translate(100%, 0px);
  }
  100% {
    transform: translate(0%, 0px);
}
`

const Content = styled.div<{
  $isOpening: boolean
  $isClosing: boolean
  $bannerHeight: number
}>`
  z-index: ${zIndex.menu.content};
  position: fixed;
  top: ${props => props.$bannerHeight}px;
  right: 0;
  width: 248px;
  height: calc(100vh - ${props => props.$bannerHeight + 32}px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #fdfeff 0%, #f4f9ff 100%), #f4f8fe;
  box-shadow: -4px 0 20px rgb(17 42 72 / 25%);
  padding: 16px 0;

  ${(props: any) => {
    if (props.$isOpening) {
      return css`
        animation: ${slideInKeyFrames} ${OPEN_TIME}ms ease-out forwards;
      `
    }
    if (props.$isClosing) {
      return css`
        animation: ${slideInKeyFrames} ${CLOSE_TIME}ms ease-in reverse forwards;
      `
    }
  }};
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
`

const closeButtonKeyFrames = keyframes`
  0% {
    opacity: 0;
    transform: rotateZ(359deg) scale(0);
  }
  100% {
    opacity: 1;
    transform: rotateZ(0deg) scale(1);
}
`

const CloseArea = styled.div<{ $isOpening: boolean; $isClosing: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  left: -40px;
  top: 8px;
  ${(props: any) => {
    if (props.$isOpening) {
      return css`
        animation: ${closeButtonKeyFrames} ${OPEN_TIME}ms ease-out forwards;
      `
    }
    if (props.$isClosing) {
      return css`
        animation: ${closeButtonKeyFrames} ${CLOSE_TIME}ms ease-in reverse
          forwards;
      `
    }
  }}

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
    border-radius: 3px;
  }
`

const BottomItemsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const BottomItemButton = styled.button<{ $color: string }>`
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  width: max-content;
  margin: 12px 0 16px 16px;
  border-radius: 8px;
  color: ${props => props.$color};

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 8px;
  }
`

export const Menu = React.memo((props: IProps) => {
  const { isManagerRoute, isHrAdminRoute, primaryColor } =
    useContext(RouteContext)
  const { hrAdminContext, bannerContext } = useContext(SharedContext)
  const bannerHeight = bannerContext.bannerHeight
  const { className, user, notifications, logout, testNoRandom } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isClosing, setIsClosing] = useState<boolean>(false)
  const [isOpening, setIsOpening] = useState<boolean>(false)
  const [selectionRoleFrom, setSelectionRoleFrom] = useState<UserRole[]>(null)

  useEffect(
    () => () => {
      clearTimeout(timeoutId)
    },
    []
  )

  useEffect(() => {
    if (isOpening) {
      timeoutId = window.setTimeout(() => {
        setIsOpen(true)
        setIsOpening(false)
      }, OPEN_TIME)
    }
  }, [isOpening])

  useEffect(() => {
    if (isClosing) {
      timeoutId = window.setTimeout(() => {
        setIsOpen(false)
        setIsClosing(false)
      }, CLOSE_TIME)
    }
  }, [isClosing])

  const open = useCallback(() => {
    window.dispatchEvent(new Event(MENU_OPEN))
    setIsOpening(true)
  }, [setIsOpening])

  const close = useCallback(() => {
    setIsClosing(true)
  }, [setIsClosing])

  const keyHandler = useCallback((event: any, callback: () => void) => {
    switch (event.which) {
      case KEY_ENTER:
      case KEY_SPACE: {
        callback()
        break
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener(HR_ADMIN_SUB_ITEM_SELECTED, close)

    return () => {
      window.removeEventListener(HR_ADMIN_SUB_ITEM_SELECTED, close)
    }
  }, [close])

  const onNotificationsClick = useCallback(() => {
    if (isManagerRoute) {
      navigate(managerNotificationsRoute)
    } else {
      navigate(notificationsRoute)
    }
    close()
  }, [close, navigate, isManagerRoute])

  const switchModeView = useMemo(() => {
    if (user?.roles.length < 2) {
      return null
    }

    if (user?.roles.length > 2) {
      return (
        <BottomItemButton
          $color={colors.dark60}
          onClick={() => {
            let roles: UserRole[]
            if (isManagerRoute) {
              roles = ['employee', 'hradmin']
            } else if (isHrAdminRoute) {
              roles = ['employee', 'manager']
            } else {
              roles = ['manager', 'hradmin']
            }
            setSelectionRoleFrom(roles)
          }}
        >
          {t('header.switchRole')}
        </BottomItemButton>
      )
    }

    const role: UserRole = getCurrentUserRole(location)
    let roleToSwitch: UserRole
    ;(user?.roles as UserRole[]).forEach((r: UserRole) => {
      if (r !== role) {
        roleToSwitch = r
      }
    })

    const text: string = t(`header.${roleToSwitch}`)

    return (
      <BottomItemButton
        $color={colors.dark60}
        onClick={() => {
          switchToRole(roleToSwitch, navigate)
          close()
        }}
      >
        {text}
      </BottomItemButton>
    )
  }, [
    close,
    location,
    navigate,
    isHrAdminRoute,
    isManagerRoute,
    t,
    user?.roles
  ])

  const stateProps: any = useMemo(
    () => ({
      isOpen,
      isOpening,
      isClosing
    }),
    [isOpen, isOpening, isClosing]
  )

  const contentView = useMemo(() => {
    const navProps: any = {
      onNavigated: close,
      rnd: testNoRandom ? null : Date.now()
    }

    return (
      <ReactFocusLock>
        <Fragment>
          <Overlay
            onClick={close}
            $isOpening={stateProps.isOpening}
            $isClosing={stateProps.isClosing}
          />
          <Content
            $isOpening={stateProps.isOpening}
            $isClosing={stateProps.isClosing}
            $bannerHeight={bannerHeight}
          >
            <CloseArea
              onClick={close}
              tabIndex={0}
              role={'button'}
              onKeyDown={(e: any) => {
                keyHandler(e, close)
              }}
              $isOpening={stateProps.isOpening}
              $isClosing={stateProps.isClosing}
            >
              <Icon
                name={'menu_close'}
                ariaLabel={t('common.accessibilityText.closeButtonMenu')}
                settings={{ fill: colors.main90 }}
              />
            </CloseArea>
            <InnerContainer>
              {!isHrAdminRoute && (
                <Notifications
                  pathname={location.pathname}
                  notifications={notifications}
                  onClick={onNotificationsClick}
                />
              )}
              {!isHrAdminRoute && <Navigation {...navProps} />}
              <HrAdminMenuContent hrAdminContext={hrAdminContext} />
              <BottomItemsContainer>
                {isHrAdminRoute && (
                  <BottomItemButton
                    $color={colors.dark60}
                    onClick={() =>
                      window.open(
                        t('hrAdmin.feedbackLink'),
                        '_blank',
                        'noopener'
                      )
                    }
                  >
                    {t('hrAdmin.submitFeedback')}
                  </BottomItemButton>
                )}
                {switchModeView}
                <BottomItemButton
                  $color={colors.error80}
                  onClick={() => {
                    close()
                    logout()
                  }}
                >
                  {t('header.signOut')}
                </BottomItemButton>
              </BottomItemsContainer>
              <Disclaimer />
            </InnerContainer>
          </Content>
          {selectionRoleFrom && (
            <RoleSwitchModalView
              roles={selectionRoleFrom}
              onCancel={() => {
                setSelectionRoleFrom(null)
                close()
              }}
              onRoleSelected={(role: UserRole) => {
                setSelectionRoleFrom(null)
                close()
                switchToRole(role, navigate)
              }}
            />
          )}
        </Fragment>
      </ReactFocusLock>
    )
  }, [
    close,
    navigate,
    location.pathname,
    isHrAdminRoute,
    logout,
    notifications,
    onNotificationsClick,
    switchModeView,
    selectionRoleFrom,
    stateProps,
    t,
    testNoRandom,
    hrAdminContext,
    keyHandler,
    bannerHeight
  ])

  if (isPathnameLike(location.pathname, loggedOutPageRoute)) {
    return null
  }

  return (
    <Wrapper
      className={className}
      tabIndex={isOpen ? -1 : 0}
      onKeyDown={e => isOpen || keyHandler(e, open)}
      onClick={() => {
        if (!isOpen) {
          open()
        }
      }}
    >
      <Icon
        name={'menu'}
        settings={{
          fill: primaryColor
        }}
        ariaLabel={t('common.accessibilityText.menuButton')}
      />
      {(isOpen || isOpening || isClosing) && contentView}
    </Wrapper>
  )
})

Menu.displayName = 'Menu'

export default withRouter(Menu)

import {
  errorPageRoute,
  userConsentRoute,
  welcomeRoute
} from 'src/routes/constants'
import get from 'lodash.get'
import { IGraphqlExecutorOptions } from 'src/react-app-env'
import { NavigateFunction } from 'react-router'
import { captureException } from 'src/utils/sentry'

export const onQueryRejection = (
  reason: any,
  navigate: NavigateFunction,
  options: IGraphqlExecutorOptions = {}
): void => {
  const statusCode: number = get(reason, 'networkError.statusCode', 200)
  const {
    unhandled,
    unauthorized,
    notFound,
    badRequest,
    anyError,
    unavailableForLegalReasons
  } = options

  if (anyError) {
    anyError(reason)
    return
  }

  if (statusCode !== 200) {
    if (statusCode === 401) {
      navigate(welcomeRoute)
    } else if (statusCode === 451) {
      if (unavailableForLegalReasons) {
        unavailableForLegalReasons(reason)
      } else {
        navigate(userConsentRoute)
      }
    } else {
      navigate(errorPageRoute)
    }
    if (unhandled) {
      unhandled(reason)
    }
    return
  }

  const { graphQLErrors } = reason
  if (!graphQLErrors || graphQLErrors.length === 0) {
    if (unhandled) {
      unhandled(reason)
    } else {
      console.log('QueryExecutor unhandled error', reason)
      navigate(errorPageRoute)
    }
    return
  }

  for (const er of graphQLErrors) {
    const {
      extensions: { code }
    } = er
    switch (code) {
      case '400':
        if (badRequest) {
          badRequest(er)
        }
        break
      case '401':
        if (unauthorized) {
          unauthorized()
        } else {
          navigate(welcomeRoute)
        }
        break
      case '404':
        if (notFound) {
          notFound()
        }
        break
      default:
        captureException(er)

        if (unhandled) {
          unhandled(reason)
        } else {
          console.log('QueryExecutor unhandled error', er)
          navigate(errorPageRoute)
        }
    }
  }
}

export default async (
  queryBlock: any,
  navigate: NavigateFunction,
  options: IGraphqlExecutorOptions = {}
) => {
  try {
    await queryBlock()
  } catch (reason) {
    onQueryRejection(reason, navigate, options)
  }
}

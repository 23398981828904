import { MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'

export const Description = styled(MarkdownText)<any>`
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-left: 12px;
        `
      : css`
          margin: 0 16px;
        `}

  ul {
    list-style: inside;
    white-space: normal;
  }

  ul ul {
    margin-top: 5px;
    margin-bottom: 5px;
    list-style-type: circle;
    padding-left: 20px;
  }

  blockquote {
    margin-left: 24px;
  }

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
      outline-offset: 1px;
    }
  }

  h3 {
    font-weight: 500;
    margin-block-end: -12px;
  }

  em {
    font-style: italic;
  }
`

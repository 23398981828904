import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import { useEffect } from 'react'
import { notFoundPageRoute, welcomeRoute } from 'src/routes/constants'
import { UserRole } from 'src/react-app-env'
import { NavigateFunction } from 'react-router'

interface IInput {
  navigate: NavigateFunction
  allowedRole: UserRole
}

export default ({ navigate, allowedRole }: IInput) => {
  const userResult: IUseUser = useUser()

  useEffect(() => {
    if (userResult.loading) {
      return
    }

    if (!userResult.user && !userResult.loading) {
      navigate(welcomeRoute)
      return
    }

    if (userResult.user?.roles.indexOf(allowedRole) === -1) {
      navigate(notFoundPageRoute)
    }
  }, [navigate, userResult, allowedRole])
}

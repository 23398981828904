import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const leaveDescriptionKey = `${input.period.type}_${input.leave.type}`
  const isMiscarriage = input.leave.type === 'Miscarriage'

  return {
    ...baseConfig,
    datePickerComponent: LeaveDatePeriodPicker,
    detailsType: isMiscarriage ? leaveDescriptionKey : baseConfig.detailsType
  }
}
